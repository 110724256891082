import React, { useEffect } from "react";
import "./index.css";
import withDashboard from "../../HOC/withDashboard";
import BtcImg from "../../assets/bitcoin.png";
import EthImg from "../../assets/ethereum.png";
import UsdtImg from "../../assets/usdt.png";
import PriceCard from "../../components/PriceCard";
import CoinCard from "../../components/CoinCard";
import PriceCardList from "../../components/PriceCardList";
import { useDispatch, useSelector } from "react-redux";
import {
  getBTCAddress,
  getBTCBalance,
  getBTCPrices,
} from "../../redux/actions/btcAction";
import {
  getETHAddress,
  getETHBalance,
  getETHPrices,
} from "../../redux/actions/ethAction";
import {
  getUSDTAddress,
  getUSDTBalance,
  getUSDTPrices,
} from "../../redux/actions/usdtAction";

const Wallets = () => {
  const dispatch = useDispatch();
  const User = useSelector(({ User }) => User);

  const { currentUser } = User;
  const { currency } = currentUser || {};

  useEffect(() => {
    dispatch(getBTCPrices({ currency }));
    dispatch(getETHPrices({ currency }));
    dispatch(getUSDTPrices({ currency }));
    dispatch(getBTCBalance());
    dispatch(getETHBalance());
    dispatch(getUSDTBalance());
    dispatch(getBTCAddress());
    dispatch(getETHAddress());
    dispatch(getUSDTAddress());
  }, []);

  return (
    <div className="wallets-container">
      <PriceCardList />
      <div className="wallet-coincards-container">
        <CoinCard coin={"BTC"} />
        <CoinCard coin={"ETH"} />
        <CoinCard coin={"USDT"} />
      </div>
    </div>
  );
};

export default withDashboard(Wallets);
