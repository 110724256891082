import React, { useEffect } from "react";
import PriceCard from "../PriceCard";
import BalanceImg from "../../assets/balance.png";
import BtcImg from "../../assets/bitcoin.png";
import EthImg from "../../assets/ethereum.png";
import UsdtImg from "../../assets/usdt.png";
import { useDispatch, useSelector } from "react-redux";
import BalanceCard from "../BalanceCard";
import { getBTCUserBalance } from "../../redux/actions/btcAction";
import { getETHUserBalance } from "../../redux/actions/ethAction";
import { getUSDTUserBalance } from "../../redux/actions/usdtAction";

const BalanceCardList = ({ userId }) => {
  const dispatch = useDispatch();
  const Btc = useSelector(({ Btc }) => Btc);
  const Eth = useSelector(({ Eth }) => Eth);
  const Usdt = useSelector(({ Usdt }) => Usdt);

  useEffect(() => {
    const data = { data: { userId } };
    if (userId) {
      dispatch(getBTCUserBalance(data));
      dispatch(getETHUserBalance(data));
      dispatch(getUSDTUserBalance(data));
    }
  }, []);

  // Calculate Overall Currency
  const usdtBalance = Usdt.userBalance;
  const usdtPriceNow = Usdt.priceNow;
  const totalUsdtBalance = usdtBalance * usdtPriceNow;
  const btcBalance = Btc.userBalance;
  const btcPriceNow = Btc.priceNow;
  const totalBtcBalance = btcBalance * btcPriceNow;
  const ethBalance = Eth.userBalance;
  const ethPriceNow = Eth.priceNow;
  const totalEthBalance = ethBalance * ethPriceNow;
  const totalBalance = totalUsdtBalance + totalBtcBalance + totalEthBalance;

  return (
    <div className="wallet-price-containers">
      <BalanceCard
        image={BalanceImg}
        title={"Assets Worth"}
        symbol={"USD"}
        balance={totalBalance}
        currency={"$"}
        fixed={2}
      />
      <BalanceCard
        balance={Btc.userBalance}
        title={"Bitcoin Balance"}
        symbol={"BTC"}
        image={BtcImg}
        fixed={7}
      />
      <BalanceCard
        balance={Eth.userBalance}
        title={"Ethereum Balance"}
        symbol={"ETH"}
        image={EthImg}
        fixed={7}
      />
      <BalanceCard
        balance={Usdt.userBalance}
        title={"Tether Balance"}
        symbol={"USDT"}
        image={UsdtImg}
        fixed={7}
      />
    </div>
  );
};

export default BalanceCardList;
