import React, { useEffect, useState } from "react";
import "chart.js/auto";
import { Line } from "react-chartjs-2";

const Chart = ({
  borderColor,
  backgroundColor,
  prices,
  labels,
  isDual,
  borderColorTo,
  backgroundColorTo,
  pricesTo,
  labelsTo,
}) => {
  const options = {
    scales: {
      x: {
        display: true,
        grid: {
          display: false, // Hide Y axis grid lines
        }, // Hide X axis labels
      },
      y: {
        display: true,
        grid: {
          display: false,
        },
      },
    },
    plugins: {
      legend: {
        display: false, // Hide the legend
      },
    },
  };
  const datasets = isDual
    ? [
        {
          label: "Coin Price",
          data: prices,
          fill: false, // Set to true for area chart
          backgroundColor,
          borderColor,
          borderWidth: 2,
        },
        {
          label: "Coin Price 1",
          data: pricesTo,
          fill: false, // Set to true for area chart
          backgroundColor: backgroundColorTo,
          borderColor: borderColorTo,
          borderWidth: 2,
        },
      ]
    : [
        {
          label: "Coin Price",
          data: prices,
          fill: true, // Set to true for area chart
          backgroundColor,
          borderColor,
          borderWidth: 2,
        },
      ];
  console.log(datasets);
  const [userData, setUserData] = useState({
    labels,
    datasets,
  });

  useEffect(() => {
    setUserData({
      labels,
      datasets,
    });
  }, [prices]);

  return (
    <div style={{ width: "100%" }}>
      <Line data={userData} options={options} />
    </div>
  );
};

export default Chart;
