import { Fragment } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  ButtonBase,
  Button,
  InputAdornment,
  TextField,
  Card,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import Loader from "../Loader";
import Pagination from "../../components/Pagination";
import SearchIcon from "@mui/icons-material/Search";

import {
  getBtcUserTransactions,
  setBtc,
  setSearchUserBtcTransaction,
} from "../../redux/actions/btcAction";
import {
  getEthUserTransactions,
  setETH,
  setSearchUserEthTransaction,
} from "../../redux/actions/ethAction";
import {
  getUsdtUserTransactions,
  setSearchUserUsdtTransaction,
  setUSDT,
} from "../../redux/actions/usdtAction";
import CallMadeIcon from "@mui/icons-material/CallMade";
import CallReceivedIcon from "@mui/icons-material/CallReceived";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import PreviewIcon from "@mui/icons-material/Preview";
import { setUser } from "../../redux/actions/userAction";
import NoTransactionBtcImg from "../../assets/transaction_btc.png";
import NoTransactionEthImg from "../../assets/transaction_eth.png";
import NoTransactionUsdtImg from "../../assets/transaction_usdt.png";

const ViewUserTransactions = ({ selectedCoin, userId }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const Btc = useSelector(({ Btc }) => Btc);
  const Eth = useSelector(({ Eth }) => Eth);
  const Usdt = useSelector(({ Usdt }) => Usdt);
  const coinMappings = {
    BTC: {
      coinName: "Bitcoin",
      circleColor: "yellow-circle",
      baseColor: "yellow",
      borderColor: "rgba(244, 183, 63, 1)",
      backgroundColor: "rgba(244, 183, 63, 0.2)",
      selectedReducer: Btc,
      noTransactionImg: NoTransactionBtcImg,
    },
    ETH: {
      coinName: "Ethereum",
      circleColor: "blue-circle",
      baseColor: "blue",
      borderColor: "rgba(0, 107, 250, 1)",
      backgroundColor: "rgba(0, 107, 250, 0.2)",
      selectedReducer: Eth,
      noTransactionImg: NoTransactionEthImg,
    },
    USDT: {
      coinName: "Tether",
      circleColor: "green-circle",
      baseColor: "green",
      borderColor: "rgba(75, 192, 192, 1)",
      backgroundColor: "rgba(75, 192, 192, 0.2)",
      selectedReducer: Usdt,
      noTransactionImg: NoTransactionUsdtImg,
    },
  };
  const selectedCoinData = coinMappings[selectedCoin];

  const {
    coinName,
    baseColor,
    borderColor,
    selectedReducer,
    noTransactionImg,
  } = selectedCoinData;

  const {
    userfilteredTransactions,
    userpaginatedTransactions,
    usercurrentTransactionPage,
    usertotalTransactionPages,
    usertransactionPerPage,
    usertransactionSearch,
  } = selectedReducer;

  useEffect(() => {
    const data = { data: { userId } };
    if (selectedCoin === "BTC") {
      dispatch(getBtcUserTransactions(data));
    } else if (selectedCoin === "ETH") {
      dispatch(getEthUserTransactions(data));
    } else if (selectedCoin === "USDT") {
      dispatch(getUsdtUserTransactions(data));
    }
    // eslint-disable-next-line
  }, []);

  const setTransaction = (data) => {
    if (selectedCoin === "BTC") {
      dispatch(setBtc(data));
    } else if (selectedCoin === "ETH") {
      dispatch(setETH(data));
    } else if (selectedCoin === "USDT") {
      dispatch(setUSDT(data));
    }
  };

  const setTotalPages = (totalpages) => {
    setTransaction({
      name: "usertotalTransactionPages",
      value: totalpages,
    });
  };
  const setCurrentPage = (currentPage) => {
    setTransaction({
      name: "usercurrentTransactionPage",
      value: currentPage,
    });
  };
  const setPaginatedItems = (paginatedItems) => {
    setTransaction({
      name: "userpaginatedTransactions",
      value: paginatedItems,
    });
  };
  const handleChangeRowsPerPage = (event) => {
    const value = event.target.value;
    setTransaction({
      name: "usertransactionPerPage",
      value,
    });
  };

  const setSearch = (value) => {
    if (selectedCoin === "BTC") {
      dispatch(setSearchUserBtcTransaction(value));
    } else if (selectedCoin === "ETH") {
      dispatch(setSearchUserEthTransaction(value));
    } else if (selectedCoin === "USDT") {
      dispatch(setSearchUserUsdtTransaction(value));
    }
  };

  const onSearch = (e) => {
    const value = e.target.value;
    setSearch(value);
  };

  const formatAddress = (address) => {
    return `${address.slice(0, 4)}...${address.slice(-4)}`;
  };

  const onViewTransaction = (transaction) => {
    console.log(transaction);
    dispatch(
      setUser({
        name: "selectedCoin",
        value: selectedCoin,
      })
    );
    setTransaction({
      name: "selectedTransaction",
      value: transaction,
    });
    navigate("/viewTransactionDetail");
  };

  return (
    <Fragment>
      <div className="table-layout-container">
        <Card className="table-card">
          <div className="table-title" style={{ background: borderColor }}>
            <span>User {coinName} Transactions</span>
            <div>
              <TextField
                fullWidth
                variant="standard"
                className="search-input"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon style={{ color: "white" }} />
                    </InputAdornment>
                  ),
                }}
                onChange={onSearch}
                value={usertransactionSearch}
              />
            </div>
          </div>
          <div className="table-container">
            <table>
              <thead>
                <th>Type</th>
                <th>sender</th>
                <th>receiver</th>
                <th>amount</th>
                <th>transactionHash</th>
                <th>Date</th>
                <th>action</th>
              </thead>
              <tbody>
                {userpaginatedTransactions.length >= 1 &&
                  userpaginatedTransactions.map((transaction) => {
                    const formattedSender = formatAddress(transaction.sender);
                    const formattedReceiver = formatAddress(
                      transaction.receiver
                    );
                    const formattedTransactionHx = formatAddress(
                      transaction.transactionHx
                    );
                    const isSend = transaction.type === "send";

                    const date = new Date(transaction.date);
                    const day = date.getDate();
                    const month = date.getMonth() + 1;
                    const year = date.getFullYear();
                    const formattedDate = `${day}-${month}-${year}`;

                    return (
                      <Fragment>
                        <tr>
                          <td className="with-icon">
                            <span
                              className={`td-icon-container ${
                                isSend ? "send" : "receive"
                              }`}
                            >
                              {isSend ? (
                                <CallMadeIcon className="table-icon" />
                              ) : (
                                <CallReceivedIcon className="table-icon" />
                              )}
                            </span>
                            <span className="td-icon-text icon-left">
                              {" "}
                              {transaction.type}
                            </span>
                          </td>
                          <td>{formattedSender}</td>
                          <td>{formattedReceiver}</td>
                          <td>{transaction.amount}</td>
                          <td className="with-icon">
                            <span className="td-icon-text icon-right">
                              {" "}
                              {formattedTransactionHx}
                            </span>
                            <span className={`td-icon-container new-tab`}>
                              <ButtonBase>
                                <OpenInNewIcon className="table-icon" />
                              </ButtonBase>
                            </span>
                          </td>
                          <td>{formattedDate}</td>
                          <td>
                            <div className="action-row">
                              <Button
                                className={`action-btn edit ${baseColor}`}
                                variant="contained"
                                onClick={() => onViewTransaction(transaction)}
                              >
                                {" "}
                                <PreviewIcon />
                                <span> View</span>
                              </Button>
                            </div>
                          </td>
                        </tr>
                      </Fragment>
                    );
                  })}
              </tbody>
            </table>
            {userpaginatedTransactions.length < 1 && (
              <Fragment>
                <div className="no-transaction-container">
                  <img src={noTransactionImg} className="no-transaction-img" />
                  <div className="no-transaction-text">
                    No Transactions Found
                  </div>
                </div>
              </Fragment>
            )}
          </div>
          <Pagination
            currentPage={usercurrentTransactionPage}
            setCurrentPage={setCurrentPage}
            items={userfilteredTransactions}
            totalPages={usertotalTransactionPages}
            setTotalPages={setTotalPages}
            itemsPerPage={usertransactionPerPage}
            setPaginatedItems={setPaginatedItems}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
          />
          <Loader />
        </Card>
      </div>
    </Fragment>
  );
};

export default ViewUserTransactions;
