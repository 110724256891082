export const BASE_URL = "https://api.coinatik.com";
// export const BASE_URL = "http://localhost:3000";
// USER
export const SET_USER = "SET_USER";
export const SET_USER_PROFILE = "SET_USER_PROFILE";
export const SET_PROFILE_FORM = "SET_PROFILE_FORM";
export const SET_USER_PROFILE_FORM = "SET_USER_PROFILE_FORM";
export const SET_USER_DETAIL_FORM = "SET_USER_DETAIL_FORM";
export const CLEAR_STATE = "CLEAR_STATE";
export const SET_STATUS = "SET_STATUS";
export const SET_SEARCH_SWAP = "SET_SEARCH_SWAP";
export const SET_SEARCH_USER = "SET_SEARCH_USER";
export const SET_SEARCH_ORDER = "SET_SEARCH_ORDER";
export const SET_TEST_SETTINGS = "SET_TEST_SETTINGS";
export const SET_PROD_SETTINGS = "SET_PROD_SETTINGS";
export const SET_UPDATE_PASSWORD_FORM = "SET_UPDATE_PASSWORD_FORM";
export const SET_UPDATE_PIN = "SET_UPDATE_PIN";
export const SET_SEARCH_REFERRAL = "SET_SEARCH_REFERRAL";
export const SET_USER_SEARCH_SWAP = "SET_USER_SEARCH_SWAP";
// BTC
export const SET_BTC = "SET_BTC";
export const SET_BTC_CHART = "SET_BTC_CHART";
export const SET_SEARCH_BTC_TRANSACTION = "SET_SEARCH_BTC_TRANSACTION";
export const SET_SEARCH_BTC_USER_TRANSACTION =
  "SET_SEARCH_BTC_USER_TRANSACTION";
// ETH
export const SET_ETH = "SET_ETH";
export const SET_ETH_CHART = "SET_ETH_CHART";
export const SET_SEARCH_ETH_TRANSACTION = "SET_SEARCH_ETH_TRANSACTION";
export const SET_SEARCH_ETH_USER_TRANSACTION =
  "SET_SEARCH_ETH_USER_TRANSACTION";
// USDT
export const SET_USDT = "SET_USDT";
export const SET_USDT_CHART = "SET_USDT_CHART";
export const SET_SEARCH_USDT_TRANSACTION = "SET_SEARCH_USDT_TRANSACTION";
export const SET_SEARCH_USDT_USER_TRANSACTION =
  "SET_SEARCH_USDT_USER_TRANSACTION";
