import "./index.css";
import HomeIcon from "@mui/icons-material/Home";
import PersonIcon from "@mui/icons-material/Person";
import SettingsIcon from "@mui/icons-material/Settings";
import Button from "@mui/material/Button";
import { useDispatch, useSelector } from "react-redux";
import { logout, setUser } from "../../redux/actions/userAction";
import { useLocation, useNavigate } from "react-router-dom";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import HandymanIcon from "@mui/icons-material/Handyman";
import { capitalize } from "../../utils";
const DashboardNav = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const User = useSelector(({ User }) => User);
  const { pathname } = useLocation();
  const { sidebarOpen } = User;
  const onLogout = () => {
    dispatch(logout({ navigate }));
  };

  const toggleSidebar = () => {
    dispatch(
      setUser({
        name: "sidebarOpen",
        value: !sidebarOpen,
      })
    );
  };

  return (
    <div
      className={`dashboard-navbar-container ${sidebarOpen ? "open" : "close"}`}
    >
      <div className="dashboard-navbar-link-container">
        <div className="dashboard-navbar-menu" onClick={toggleSidebar}>
          <MenuOpenIcon />
        </div>
        <div className="dashboard-navbar-link">
          <div className="dashboard-navbar-link-icon">
            <HomeIcon />
          </div>
          /<div className="dashboard-navbar-link-text">{pathname.substring(1)}</div>
        </div>
        <div className="dashboard-navbar-link-alt">
          {capitalize(pathname.substring(1))}
        </div>
      </div>
      <div className="dashboard-actions-container">
        <div
          className="dashboard-navbar-action-item"
          onClick={() => navigate("/users")}
        >
          <PersonIcon />
        </div>
        <div
          className="dashboard-navbar-action-item"
          onClick={() => navigate("/settings")}
        >
          <SettingsIcon />
        </div>
        <div
          className="dashboard-navbar-action-item"
          onClick={() => navigate("/config")}
        >
          <HandymanIcon />
        </div>

        <div className="dashboard-navbar-action-item">
          <Button
            variant="contained"
            className="dashboard-btn"
            onClick={onLogout}
          >
            Logout
          </Button>
        </div>
      </div>
    </div>
  );
};

export default DashboardNav;
