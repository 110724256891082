const formatNumberWithCommas = (value) => {
  const number = parseInt(value).toLocaleString("en-US");
  return number;
};

const isNumber = (value) => {
  const regex = /^\d*\.?\d*$/; // Regex to allow digits and optional decimal point
  return regex.test(value);
};

const isValidEmail = (email) => {
  // Regular expression for validating an Email
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
};

function isValidDecimal(value) {
  // Regular expression to match a positive decimal number
  const regex = /^\d+(\.\d+)?$/;
  return regex.test(value);
}

const capitalize = (value) => {
  return value.charAt(0).toUpperCase() + value.slice(1);
};

export {
  formatNumberWithCommas,
  isValidEmail,
  isNumber,
  isValidDecimal,
  capitalize,
};
