import React, { useEffect, useState, useRef } from "react";
import "./index.css";
import withDashboard from "../../HOC/withDashboard";
import { Button, ButtonBase } from "@mui/material";
import CardLayout from "../../components/CardLayout";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import {
  getSettings,
  updateEnv,
  updateSettings,
} from "../../redux/actions/userAction";
import { useNavigate } from 'react-router-dom'
import TestConfig from "../../components/TestConfig";
import ProdConfig from "../../components/ProdConfig";

const Configurations = () => {
  const [isTest, setIsTest] = useState(true);
  const User = useSelector(({ User }) => User);
  const { testConfig, prodConfig, enviroment } = User;
  const dispatch = useDispatch();
  const wasCalled = useRef(false);
  const navigate = useNavigate();

  useEffect(() => {
    if(wasCalled.current) return;
    wasCalled.current = true;
    
    dispatch(getSettings());
    // eslint-disable-next-line
  }, []);

  const onUpdate = () => {
    let data = isTest ? testConfig : prodConfig;
    data.env = isTest ? "test" : "prod";
    console.log(data);
    dispatch(
      updateSettings({
        data,
      })
    );
  };
  const onChangeEnv = async (env) => {
    const _env = env === "test" ? "Test" : "Production";
    const msg = `You are about to Change the Enviroment to ${_env}`;
    const result = await Swal.fire({
      title: "Are you sure?",
      text: msg,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Change Enviroment",
      cancelButtonText: "No",
    });
    if (result.isConfirmed) {
      dispatch(
        updateEnv({
          data: {
            env,
          },
        })
      );

      navigate();
    }
  };

  return (
    <div className="wallets-container">
      <CardLayout>
        <div className="config-env-container">
          <div className="config-env-label">Current Enviroment:</div>
          <div className="config-env-accordian-container">
            <ButtonBase
              className={`config-env-item ${
                enviroment === "test" && "selected"
              } left`}
              onClick={() => onChangeEnv("test")}
            >
              Test
            </ButtonBase>
            <ButtonBase
              className={`config-env-item ${
                enviroment === "prod" && "selected"
              } right`}
              onClick={() => onChangeEnv("prod")}
            >
              Production
            </ButtonBase>
          </div>
        </div>
        <div className="env-inputs-config-accordian">
          <ButtonBase
            className={`env-inputs-config-item left ${isTest && "selected"} `}
            onClick={() => setIsTest(true)}
          >
            Test
          </ButtonBase>
          <ButtonBase
            className={`env-inputs-config-item right ${!isTest && "selected"} `}
            onClick={() => setIsTest(false)}
          >
            Production
          </ButtonBase>
        </div>
        <div className="config-inputs-container">
          {isTest ? <TestConfig /> : <ProdConfig />}
        </div>
        <div className="config-btn-container">
          <Button variant="contained" className="config-btn" onClick={onUpdate}>
            Update Config
          </Button>
        </div>
      </CardLayout>
    </div>
  );
};

export default withDashboard(Configurations);
