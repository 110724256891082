import "../CoinWallet/index.css";
import "./index.css";
import withDashboard from "../../HOC/withDashboard";
import BtcImg from "../../assets/bitcoin.png";
import EthImg from "../../assets/ethereum.png";
import UsdtImg from "../../assets/usdt.png";
import { Button } from "@mui/material";
import Chart from "../../components/Chart";
import CardLayout from "../../components/CardLayout";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import PriceCardList from "../../components/PriceCardList";
import {  useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { formatNumberWithCommas } from "../../utils";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import SwapHorizIcon from "@mui/icons-material/SwapHoriz";
import SendIcon from "@mui/icons-material/Send";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";

const ReviewTransaction = () => {
  const navigate = useNavigate();
  const Btc = useSelector(({ Btc }) => Btc);
  const Eth = useSelector(({ Eth }) => Eth);
  const Usdt = useSelector(({ Usdt }) => Usdt);
  const User = useSelector(({ User }) => User);
  const {
    currentUser,
    selectedSwap,
  } = User;
  const { currencySymbol, currency } = currentUser || {};
  const {
    swapId,
    amountSent,
    coinSent,
    amountToRecieve,
    coinRecieve,
    status,
    date,
  } = selectedSwap || {};

  const coinMappings = {
    BTC: {
      coinName: "Bitcoin",
      circleColor: "yellow-circle",
      baseColor: "yellow",
      borderColor: "rgba(244, 183, 63, 1)",
      backgroundColor: "rgba(244, 183, 63, 0.2)",
      selectedReducer: Btc,
      image: BtcImg,
    },
    ETH: {
      coinName: "Ethereum",
      circleColor: "blue-circle",
      baseColor: "blue",
      borderColor: "rgba(0, 107, 250, 1)",
      backgroundColor: "rgba(0, 107, 250, 0.2)",
      selectedReducer: Eth,
      image: EthImg,
    },
    USDT: {
      coinName: "Tether",
      circleColor: "green-circle",
      baseColor: "green",
      borderColor: "rgba(75, 192, 192, 1)",
      backgroundColor: "rgba(75, 192, 192, 0.2)",
      selectedReducer: Usdt,
      image: UsdtImg,
    },
  };

  const coinFrom = coinSent;
  const selectedCoinFromData = coinMappings[coinFrom];
  const borderColorFrom = selectedCoinFromData?.borderColor;
  const backgroundColorFrom = selectedCoinFromData?.backgroundColor;
  const selectedReducerFrom = selectedCoinFromData?.selectedReducer;
  const imageFrom = selectedCoinFromData?.image;
  //  Taken from CoinFrom Reducer
  const pricesFrom = selectedReducerFrom?.prices;
  const labelsFrom = selectedReducerFrom?.labels;
  const balanceFrom = selectedReducerFrom?.balance;
  const priceNowFrom = formatNumberWithCommas(selectedReducerFrom?.priceNow);
  // Coin To Recieve
  const coinTo = coinRecieve;
  const selectedCoinToData = coinMappings[coinTo];
  const borderColorTo = selectedCoinToData?.borderColor;
  const backgroundColorTo = selectedCoinToData?.backgroundColor;
  const selectedReducerTo = selectedCoinToData?.selectedReducer;
  const imageTo = selectedCoinToData?.image;
  //  Taken from Coin To Reducer
  const pricesTo = selectedReducerTo?.prices;
  const labelsTo = selectedReducerTo?.labels;
  const balanceTo = selectedReducerTo?.balance;
  const priceNowTo = formatNumberWithCommas(selectedReducerTo?.priceNow);

  // Format Date
  const swapDate = new Date(date);
  const formattedDate = `${swapDate.toLocaleTimeString("en-US", {
    hour: "numeric",
    minute: "2-digit",
  })} on ${swapDate.toLocaleDateString("en-US", {
    year: "numeric",
    month: "short",
    day: "numeric",
  })}`;

  // Format Balance CoinFrom
  let formattedBalanceFrom = parseFloat(balanceFrom).toFixed(7);
  if (Number.isInteger(parseFloat(formattedBalanceFrom))) {
    formattedBalanceFrom = parseFloat(formattedBalanceFrom).toString();
  }
  // Format Balance CoinTo
  let formattedBalanceTo = parseFloat(balanceTo).toFixed(7);
  if (Number.isInteger(parseFloat(formattedBalanceTo))) {
    formattedBalanceTo = parseFloat(formattedBalanceTo).toString();
  }


  return (
    <div className="wallets-container">
      <PriceCardList />
      <CardLayout>
        <div className="coinwallet-back-btn">
          <Button variant="outlined" onClick={() => navigate(-1)}>
            <ArrowBackIcon /> Back
          </Button>
        </div>
        <div className="coinwallet-container">
          <div className="coinwallet-info-container">
            <div className="coinwallet-info review-info">
              <div className="swap-image-container">
                <span className="swap-icon-container">
                  <ArrowUpwardIcon className="swap-arrow-icon out" />
                </span>
                <img
                  src={imageFrom}
                  className="coinwallet-img"
                  alt="card.png"
                />
              </div>

              <div className="coinwallet-info-text-container">
                <div className="coinwallet-info-price">
                  <span> Price: </span>
                  {currencySymbol}
                  {priceNowFrom} {currency}
                </div>
                <div className="coinwallet-info-balance">
                  <span> Balance: </span>
                  {formattedBalanceFrom} {coinFrom}
                </div>
              </div>
            </div>
            <div className="coinwallet-info review-info">
              <div className="swap-image-container">
                <span className="swap-icon-container">
                  <ArrowDownwardIcon className="swap-arrow-icon in" />
                </span>
                <img src={imageTo} className="coinwallet-img" alt="card.png" />
              </div>
              <div className="coinwallet-info-text-container">
                <div className="coinwallet-info-price">
                  <span> Price: </span>
                  {currencySymbol}
                  {priceNowTo} {currency}
                </div>
                <div className="coinwallet-info-balance">
                  <span> Balance: </span>
                  {formattedBalanceTo} {coinTo}
                </div>
              </div>
            </div>
            <div className="coinwallet-chart-title">
              Price of {coinFrom} and {coinTo} in previous 7 days
            </div>
            <div className="coinwallet-chart">
              <Chart
                borderColor={borderColorFrom}
                backgroundColor={backgroundColorFrom}
                prices={pricesFrom}
                labels={labelsFrom}
                isDual={true}
                borderColorTo={borderColorTo}
                backgroundColorTo={backgroundColorTo}
                pricesTo={pricesTo}
                labelsTo={labelsTo}
              />
            </div>
          </div>
          <div className="review-transaction-details">
            <div className="swap-items-container">
              <div
                className={`swap-item ${
                  (status === "waiting" ||
                    status === "confirming" ||
                    status === "exchanging" ||
                    status === "sending") &&
                  "completed"
                }`}
              >
                <div className="swap-item-icon-container">
                  <MoreHorizIcon />
                </div>
                <div className="swap-item-value">Pending</div>
              </div>
              <div
                className={`swap-item ${
                  (status === "confirming" ||
                    status === "exchanging" ||
                    status === "sending") &&
                  "completed"
                } `}
              >
                <div className="swap-item-icon-container">
                  <AutorenewIcon />
                </div>
                <div className="swap-item-value">Confirming</div>
              </div>
              <div
                className={`swap-item ${
                  (status === "sending" || status === "exchanging") &&
                  "completed"
                }`}
              >
                <div className="swap-item-icon-container">
                  <SwapHorizIcon />
                </div>
                <div className="swap-item-value">Exchanging</div>
              </div>
              <div
                className={`swap-item  ${status === "sending" && "completed"}`}
              >
                <div className="swap-item-icon-container">
                  <SendIcon />
                </div>
                <div className="swap-item-value">Sending</div>
              </div>
            </div>
            <div className="review-transaction-item">
              <div className="review-transaction-title">Date:</div>
              <div className="review-transaction-value">{formattedDate}</div>
            </div>
            <div className="review-transaction-item">
              <div className="review-transaction-title">Swap Id:</div>
              <div className="review-transaction-value">{swapId}</div>
            </div>
            <div className="review-transaction-item">
              <div className="review-transaction-title">Amount Out:</div>
              <div className="review-transaction-value">{`${amountSent} ${coinSent}`}</div>
            </div>
            <div className="review-transaction-item">
              <div className="review-transaction-title">Amount In:</div>
              <div className="review-transaction-value">{`${amountToRecieve} ${coinRecieve}`}</div>
            </div>
            <div className="review-transaction-item border-bottom-none">
              <div className="review-transaction-title">Current Status:</div>
              <div className="review-transaction-value">{status}</div>
            </div>
          </div>
        </div>
      </CardLayout>
    </div>
  );
};

export default withDashboard(ReviewTransaction);
