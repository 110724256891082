import { Fragment } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  ButtonBase,
  Button,
  InputAdornment,
  TextField,
  Card,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import Loader from "../Loader";
import Pagination from "../../components/Pagination";
import SearchIcon from "@mui/icons-material/Search";

import {
  getBtcTransactions,
  setBtc,
  setSearchBtcTransaction,
} from "../../redux/actions/btcAction";
import {
  getEthTransactions,
  setETH,
  setSearchEthTransaction,
} from "../../redux/actions/ethAction";
import {
  getUsdtTransactions,
  setSearchUsdtTransaction,
  setUSDT,
} from "../../redux/actions/usdtAction";
import CallMadeIcon from "@mui/icons-material/CallMade";
import CallReceivedIcon from "@mui/icons-material/CallReceived";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import PreviewIcon from "@mui/icons-material/Preview";
import NoTransactionBtcImg from "../../assets/transaction_btc.png";
import NoTransactionEthImg from "../../assets/transaction_eth.png";
import NoTransactionUsdtImg from "../../assets/transaction_usdt.png";

const ViewTransactions = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const User = useSelector(({ User }) => User);
  const { selectedCoin, testConfig, prodConfig, enviroment } = User;
  const config = enviroment === "test" ? testConfig : prodConfig;
  const { BTC_EXPLORER, ETH_EXPLORER, USDT_EXPLORER } = config;
  const Btc = useSelector(({ Btc }) => Btc);
  const Eth = useSelector(({ Eth }) => Eth);
  const Usdt = useSelector(({ Usdt }) => Usdt);

  const coinMappings = {
    BTC: {
      coinName: "Bitcoin",
      circleColor: "yellow-circle",
      baseColor: "yellow",
      borderColor: "rgba(244, 183, 63, 1)",
      backgroundColor: "rgba(244, 183, 63, 0.2)",
      selectedReducer: Btc,
      explorer: BTC_EXPLORER,
      noTransactionImg: NoTransactionBtcImg,
    },
    ETH: {
      coinName: "Ethereum",
      circleColor: "blue-circle",
      baseColor: "blue",
      borderColor: "rgba(0, 107, 250, 1)",
      backgroundColor: "rgba(0, 107, 250, 0.2)",
      selectedReducer: Eth,
      explorer: ETH_EXPLORER,
      noTransactionImg: NoTransactionEthImg,
    },
    USDT: {
      coinName: "Tether",
      circleColor: "green-circle",
      baseColor: "green",
      borderColor: "rgba(75, 192, 192, 1)",
      backgroundColor: "rgba(75, 192, 192, 0.2)",
      selectedReducer: Usdt,
      explorer: USDT_EXPLORER,
      noTransactionImg: NoTransactionUsdtImg,
    },
  };
  const selectedCoinData = coinMappings[selectedCoin];

  const {
    coinName,
    borderColor,
    baseColor,
    selectedReducer,
    explorer,
    noTransactionImg,
  } = selectedCoinData;

  const {
    filteredTransactions,
    paginatedTransactions,
    currentTransactionPage,
    totalTransactionPages,
    transactionPerPage,
    transactionSearch,
  } = selectedReducer;

  useEffect(() => {
    if (selectedCoin === "BTC") {
      dispatch(getBtcTransactions());
    } else if (selectedCoin === "ETH") {
      dispatch(getEthTransactions());
    } else if (selectedCoin === "USDT") {
      dispatch(getUsdtTransactions());
    }
    // eslint-disable-next-line
  }, []);

  const setTransaction = (data) => {
    if (selectedCoin === "BTC") {
      dispatch(setBtc(data));
    } else if (selectedCoin === "ETH") {
      dispatch(setETH(data));
    } else if (selectedCoin === "USDT") {
      dispatch(setUSDT(data));
    }
  };

  const setTotalPages = (totalpages) => {
    setTransaction({
      name: "totalTransactionPages",
      value: totalpages,
    });
  };
  const setCurrentPage = (currentPage) => {
    setTransaction({
      name: "currentTransactionPage",
      value: currentPage,
    });
  };
  const setPaginatedItems = (paginatedItems) => {
    setTransaction({
      name: "paginatedTransactions",
      value: paginatedItems,
    });
  };
  const handleChangeRowsPerPage = (event) => {
    const value = event.target.value;
    setTransaction({
      name: "transactionPerPage",
      value,
    });
  };

  const setSearch = (value) => {
    if (selectedCoin === "BTC") {
      dispatch(setSearchBtcTransaction(value));
    } else if (selectedCoin === "ETH") {
      dispatch(setSearchEthTransaction(value));
    } else if (selectedCoin === "USDT") {
      dispatch(setSearchUsdtTransaction(value));
    }
  };

  const onSearch = (e) => {
    const value = e.target.value;
    setSearch(value);
    setTransaction({
      name: "transactionSearch",
      value: value,
    });
  };

  const formatAddress = (address) => {
    return `${address.slice(0, 4)}...${address.slice(-4)}`;
  };

  const onViewTransaction = (transaction) => {
    console.log(transaction);
    setTransaction({
      name: "selectedTransaction",
      value: transaction,
    });
    navigate("/viewTransactionDetail");
  };

  const ViewOnBlockchain = (transactionHx) => {
    const url = `${explorer}${transactionHx}`;
    window.open(url, "_blank");
  };

  return (
    <Fragment>
      <div className="table-layout-container">
        <Card className="table-card">
          <div className="table-title" style={{ background: borderColor }}>
            <span>{coinName} Transactions</span>
            <div>
              <TextField
                fullWidth
                variant="standard"
                className="search-input"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon style={{ color: "white" }} />
                    </InputAdornment>
                  ),
                }}
                onChange={onSearch}
                value={transactionSearch}
              />
            </div>
          </div>
          <div className="table-container">
            <table>
              <thead>
                <th>Type</th>
                <th>sender</th>
                <th>receiver</th>
                <th>amount</th>
                <th>transactionHash</th>
                <th>date</th>
                <th>action</th>
              </thead>
              <tbody>
                {paginatedTransactions.length >= 1 &&
                  paginatedTransactions.map((transaction) => {
                    const formattedSender = formatAddress(transaction.sender);
                    const formattedReceiver = formatAddress(
                      transaction.receiver
                    );
                    const formattedTransactionHx = formatAddress(
                      transaction.transactionHx
                    );
                    const isSend = transaction.type === "send";
                    
                    const date = new Date(transaction.date); 
                    const day = date.getDate();
                    const month = date.getMonth() + 1; 
                    const year = date.getFullYear();
                    const formattedDate = `${day}-${month}-${year}`;

                    return (
                      <Fragment>
                        <tr>
                          <td className="with-icon">
                            <span
                              className={`td-icon-container ${
                                isSend ? "send" : "receive"
                              }`}
                            >
                              {isSend ? (
                                <CallMadeIcon className="table-icon" />
                              ) : (
                                <CallReceivedIcon className="table-icon" />
                              )}
                            </span>
                            <span className="td-icon-text icon-left">
                              {" "}
                              {transaction.type}
                            </span>
                          </td>
                          <td>{formattedSender}</td>
                          <td>{formattedReceiver}</td>
                          <td>{transaction.amount}</td>
                          <td className="with-icon">
                            <span className="td-icon-text icon-right">
                              {" "}
                              {formattedTransactionHx}
                            </span>
                            <span className={`td-icon-container new-tab`}>
                              <ButtonBase
                                onClick={() =>
                                  ViewOnBlockchain(transaction.transactionHx)
                                }
                              >
                                <OpenInNewIcon className="table-icon" />
                              </ButtonBase>
                            </span>
                          </td>
                          <td>{formattedDate}</td>
                          <td>
                            <div className="action-row">
                              <Button
                                className={`action-btn edit ${baseColor}`}
                                variant="contained"
                                onClick={() => onViewTransaction(transaction)}
                              >
                                {" "}
                                <PreviewIcon />
                                <span> View</span>
                              </Button>
                            </div>
                          </td>
                        </tr>
                      </Fragment>
                    );
                  })}
              </tbody>
            </table>
            {paginatedTransactions.length < 1 && (
              <Fragment>
                <div className="no-transaction-container">
                  <img
                    src={noTransactionImg}
                    className="no-transaction-img"
                    alt="no-transaction-img.png"
                  />
                  <div className="no-transaction-text">
                    No Transactions Found
                  </div>
                </div>
              </Fragment>
            )}
          </div>
          <Pagination
            currentPage={currentTransactionPage}
            setCurrentPage={setCurrentPage}
            items={filteredTransactions}
            totalPages={totalTransactionPages}
            setTotalPages={setTotalPages}
            itemsPerPage={transactionPerPage}
            setPaginatedItems={setPaginatedItems}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
          />
          <Loader />
        </Card>
      </div>
    </Fragment>
  );
};

export default ViewTransactions;
