import {
  BASE_URL,
  SET_BTC,
  SET_BTC_CHART,
  SET_SEARCH_BTC_TRANSACTION,
  SET_SEARCH_BTC_USER_TRANSACTION,
  SET_STATUS,
} from "./types";
import Axios from "axios";
import Swal from "sweetalert2";
import { setLoader } from "./userAction";

// Set BTC reducer
export const setBtc = (data) => async (dispatch) => {
  try {
    dispatch({ type: SET_BTC, payload: data });
  } catch (error) {
    console.log(error);
  }
};

// get previous 7 days prices of btc
export const getBTCPrices = (data) => async (dispatch) => {
  try {
    const resp = await Axios.post(`${BASE_URL}/api/btc/getPrices`, data, {
      withCredentials: true,
    });
    const dataset = resp.data.dataset;
    dispatch({ type: SET_BTC_CHART, payload: dataset });
  } catch (error) {
    const data = error?.response.data;
    const errorMsg = data?.errorMsg;
    if (errorMsg) {
      Swal.fire({
        icon: "error",
        title: "Error Occured",
        text: errorMsg,
      });
    }
    console.log(error);
  }
};

// get user address
export const getBTCAddress = () => async (dispatch) => {
  try {
    const resp = await Axios.get(`${BASE_URL}/api/btc/address`, {
      withCredentials: true,
    });
    const address = resp.data.address;
    dispatch(
      setBtc({
        name: "address",
        value: address,
      })
    );
  } catch (error) {
    const data = error?.response.data;
    const errorMsg = data?.errorMsg;
    if (errorMsg) {
      Swal.fire({
        icon: "error",
        title: "Error Occured",
        text: errorMsg,
      });
    }
    console.log(error);
  }
};

// get user balance
export const getBTCBalance = () => async (dispatch) => {
  try {
    const resp = await Axios.get(`${BASE_URL}/api/btc/balance`, {
      withCredentials: true,
    });
    const balance = resp.data.balance;

    dispatch(
      setBtc({
        name: "balance",
        value: balance,
      })
    );
  } catch (error) {
    const data = error?.response.data;
    const errorMsg = data?.errorMsg;
    if (errorMsg) {
      Swal.fire({
        icon: "error",
        title: "Error Occured",
        text: errorMsg,
      });
    }
    console.log(error);
  }
};

// get user balance
export const getBTCUserBalance =
  ({ data }) =>
  async (dispatch) => {
    try {
      const resp = await Axios.post(`${BASE_URL}/api/btc/userBalance`, data, {
        withCredentials: true,
      });
      const balance = resp.data.balance;

      dispatch(
        setBtc({
          name: "userBalance",
          value: balance,
        })
      );
    } catch (error) {
      console.log(error);
    }
  };

export const getBtcCurrentPrice = (data) => async (dispatch) => {
  try {
    const resp = await Axios.post(`${BASE_URL}/api/btc/currentPrice`, data, {
      withCredentials: true,
    });
    const price = resp.data.price;
    dispatch({
      type: SET_BTC,
      payload: {
        name: "priceNow",
        value: price,
      },
    });
  } catch (error) {
    const data = error?.response.data;
    const errorMsg = data?.errorMsg;
    if (errorMsg) {
      Swal.fire({
        icon: "error",
        title: "Error Occured",
        text: errorMsg,
      });
    }
    console.log(error);
  }
};

export const estimateBTCGas =
  ({ data }) =>
  async (dispatch) => {
    dispatch(setLoader(true));
    try {
      const isFee = data.isFee;
      const resp = await Axios.post(`${BASE_URL}/api/btc/estimateGas`, data, {
        withCredentials: true,
      });
      const estimatedGas = resp.data.estimatedGas;
      if (isFee) {
        dispatch({
          type: SET_BTC,
          payload: {
            name: "gasPriceFee",
            value: estimatedGas,
          },
        });
      } else {
        dispatch({
          type: SET_BTC,
          payload: {
            name: "gasPrice",
            value: estimatedGas,
          },
        });
      }
    } catch (error) {
      const data = error?.response.data;
      const errorMsg = data?.errorMsg;
      if (errorMsg) {
        Swal.fire({
          icon: "error",
          title: "Error Occured",
          text: errorMsg,
        });
      }
      console.log(error);
    }
    dispatch(setLoader(false));
  };

export const sendBTC =
  ({ data, navigate }) =>
  async (dispatch) => {
    dispatch(setLoader(true));
    try {
      const resp = await Axios.post(`${BASE_URL}/api/btc/send`, data, {
        withCredentials: true,
      });
      const transactionHash = resp.data.transactionHash;
      dispatch({
        type: SET_STATUS,
        payload: {
          statusTitle: "Success",
          statusDesc: "Amount was successfully Sent",
          statusContinueRoute: "/coinWallet",
        },
      });
      console.log(transactionHash);
      navigate("/coinWallet");
      Swal.fire({
        position: "top-end",
        icon: "success",
        title: "Transaction Sent Successfully",
        showConfirmButton: false,
        timer: 1500,
      });
      dispatch(setBtc({ name: "sendAmount", value: "" }));
      dispatch(setBtc({ name: "recipientAddress", value: "" }));
    } catch (error) {
      const data = error?.response.data;
      const errorMsg = data?.errorMsg;
      if (errorMsg) {
        Swal.fire({
          icon: "error",
          title: "Error Occured",
          text: errorMsg,
        });
      }
      navigate("/coinWallet");
      console.log(error);
    }
    dispatch(setLoader(false));
  };

export const getBtcTransactions = () => async (dispatch) => {
  dispatch(setLoader(true));
  try {
    const resp = await Axios.get(`${BASE_URL}/api/btc/transactions`, {
      withCredentials: true,
    });
    const transactions = resp.data.transactions;
    dispatch(
      setBtc({
        name: "transactions",
        value: transactions,
      })
    );
    dispatch(
      setBtc({
        name: "filteredTransactions",
        value: transactions,
      })
    );
  } catch (error) {
    const data = error?.response.data;
    const errorMsg = data?.errorMsg;
    if (errorMsg) {
      Swal.fire({
        icon: "error",
        title: "Error Occured",
        text: errorMsg,
      });
    }
    console.log(error);
  }
  dispatch(setLoader(false));
};

export const getBtcUserTransactions =
  ({ data }) =>
  async (dispatch) => {
    dispatch(setLoader(true));
    try {
      const resp = await Axios.post(
        `${BASE_URL}/api/btc/userTransactions`,
        data,
        {
          withCredentials: true,
        }
      );
      const transactions = resp.data.transactions;
      dispatch(
        setBtc({
          name: "usertransactions",
          value: transactions,
        })
      );
      dispatch(
        setBtc({
          name: "userfilteredTransactions",
          value: transactions,
        })
      );
    } catch (error) {
      console.log(error);
    }
    dispatch(setLoader(false));
  };

export const setSearchBtcTransaction = (data) => async (dispatch) => {
  try {
    dispatch({ type: SET_SEARCH_BTC_TRANSACTION, payload: data });
  } catch (error) {
    console.log(error);
  }
};

export const setSearchUserBtcTransaction = (data) => async (dispatch) => {
  try {
    dispatch({ type: SET_SEARCH_BTC_USER_TRANSACTION, payload: data });
  } catch (error) {
    console.log(error);
  }
};
