import {
  SET_USER,
  BASE_URL,
  SET_USER_PROFILE,
  SET_PROFILE_FORM,
  SET_SEARCH_BTC_TRANSACTION,
  SET_SEARCH_SWAP,
  SET_SEARCH_USER,
  SET_USER_PROFILE_FORM,
  SET_USER_DETAIL_FORM,
  SET_SEARCH_ORDER,
  SET_TEST_SETTINGS,
  SET_PROD_SETTINGS,
  SET_UPDATE_PASSWORD_FORM,
  SET_UPDATE_PIN,
  SET_SEARCH_REFERRAL,
  SET_USER_SEARCH_SWAP,
} from "./types";
import Axios from "axios";
import Swal from "sweetalert2";
import { setBtc } from "./btcAction";
import { setETH } from "./ethAction";
import { setUSDT } from "./usdtAction";

// Set overall Reducer
export const setUser = (data) => async (dispatch) => {
  try {
    dispatch({ type: SET_USER, payload: data });
  } catch (error) {
    console.log(error);
  }
};

export const setProfileForm = (data) => async (dispatch) => {
  try {
    dispatch({ type: SET_USER_PROFILE, payload: data });
  } catch (error) {
    console.log(error);
  }
};

export const setUserProfile = (user) => async (dispatch) => {
  try {
    dispatch({ type: SET_PROFILE_FORM, payload: user });
  } catch (error) {
    console.log(error);
  }
};

export const setUserDetailProfile = (user) => async (dispatch) => {
  try {
    dispatch({ type: SET_USER_PROFILE_FORM, payload: user });
  } catch (error) {
    console.log(error);
  }
};

export const setUserDetailForm = (data) => async (dispatch) => {
  try {
    dispatch({ type: SET_USER_DETAIL_FORM, payload: data });
  } catch (error) {
    console.log(error);
  }
};

// Set Loader
export const setLoader = (isLoading) => async (dispatch) => {
  try {
    dispatch({
      type: SET_USER,
      payload: {
        name: "isLoading",
        value: isLoading,
      },
    });
  } catch (error) {
    console.log(error);
  }
};

// Login a User
// export const login =
//   ({ data, navigate }) =>
//   async (dispatch) => {
//     dispatch(setLoader(true));
//     try {
//       const resp = await Axios.post(`${BASE_URL}/api/admin/login`, data, {
//         withCredentials: true,
//       });
//       const currentUser = resp.data.user;
//       dispatch(setUser({ name: "currentUser", value: currentUser }));
//       dispatch(setUserProfile(currentUser));
//       dispatch(getSettings());
//       localStorage.setItem('token', resp.data.token);
//       navigate("/profile");
//     } catch (error) {
//       const response = error?.response;
//       const data = response?.data;
//       const errorMsg = data?.errorMsg;
//       if (errorMsg) {
//         Swal.fire({
//           icon: "error",
//           title: "Error Occured",
//           text: errorMsg,
//         });
//       }
//       console.log(error);
//     }
//     dispatch(setLoader(false));
//   };

export const login =
  ({ data, navigate }) =>
  async (dispatch) => {
    dispatch(setLoader(true));
    try {
      await Axios.post(`${BASE_URL}/api/admin/login`, data, {
        withCredentials: true,
      });
    } catch (error) {
      const response = error?.response;
      const data = response?.data;
      const errorMsg = data?.errorMsg;
      if (errorMsg) {
        Swal.fire({
          icon: "error",
          title: "Error Occured",
          text: errorMsg,
        });
      }
      console.log(error);
    }
    dispatch(setLoader(false));
  };

export const checkOTP =
  ({ data, navigate }) =>
  async (dispatch) => {
    dispatch(setLoader(true));
    try {
      const resp = await Axios.post(`${BASE_URL}/api/admin/checkOtp`, data, {
        withCredentials: true,
      });

      const currentUser = resp.data.user;
      dispatch(setUser({ name: "currentUser", value: currentUser }));
      dispatch(setUserProfile(currentUser));
      dispatch(getSettings());
      navigate("/profile");
    } catch (error) {
      const response = error?.response;
      const data = response?.data;
      const errorMsg = data?.errorMsg;
      if (errorMsg) {
        Swal.fire({
          icon: "error",
          title: "Error Occured",
          text: errorMsg,
        });
      }
      console.log(error);
    }
    dispatch(setLoader(false));
  };

// Logout a User
export const logout =
  ({ navigate }) =>
  async (dispatch) => {
    try {
      await Axios.get(`${BASE_URL}/api/admin/logout`, {
        withCredentials: true,
      });

      localStorage.clear();
      sessionStorage.clear();
      navigate("/");
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error Occured",
        text: "Could not Logout",
      });
      console.log(error);
    }
  };

// Update a Profile
export const updateProfile =
  ({ data }) =>
  async (dispatch) => {
    dispatch(setLoader(true));
    try {
      const resp = await Axios.post(
        `${BASE_URL}/api/user/updateProfile`,
        data,
        {
          withCredentials: true,
        }
      );
      const user = resp.data.user;
      dispatch(
        setUser({
          name: "currentUser",
          value: user,
        })
      );
      Swal.fire({
        position: "top-end",
        icon: "success",
        title: "Profile Updated",
        showConfirmButton: false,
        timer: 1500,
      });
    } catch (error) {
      const data = error?.response.data;
      const errorMsg = data?.errorMsg;
      if (errorMsg) {
        Swal.fire({
          icon: "error",
          title: "Error Occured",
          text: errorMsg,
        });
      }
      console.log(error);
    }
    dispatch(setLoader(false));
  };

export const updateUserProfile =
  ({ data }) =>
  async (dispatch) => {
    dispatch(setLoader(true));
    try {
      await Axios.post(`${BASE_URL}/api/admin/updateUserProfile`, data, {
        withCredentials: true,
      });

      Swal.fire({
        position: "top-end",
        icon: "success",
        title: "Updated User Profile",
        showConfirmButton: false,
        timer: 1500,
      });
    } catch (error) {
      const data = error?.response.data;
      const errorMsg = data?.errorMsg;
      if (errorMsg) {
        Swal.fire({
          icon: "error",
          title: "Error Occured",
          text: errorMsg,
        });
      }
      console.log(error);
    }
    dispatch(setLoader(false));
  };

export const getSwapRanges =
  ({ data }) =>
  async (dispatch) => {
    dispatch(setLoader(true));
    try {
      const response = await Axios.post(
        `${BASE_URL}/api/user/swapRange`,
        data,
        {
          withCredentials: true,
        }
      );
      const range = response.data.range;
      dispatch(setUser({ name: "range", value: range }));
    } catch (error) {
      console.log(error);
    }
    dispatch(setLoader(false));
  };

export const getSwapEstimate =
  ({ data }) =>
  async (dispatch) => {
    dispatch(setLoader(true));
    try {
      const response = await Axios.post(
        `${BASE_URL}/api/user/swapEstimate`,
        data,
        {
          withCredentials: true,
        }
      );
      const estimate = response.data.estimate;
      dispatch(setUser({ name: "swapEstimate", value: estimate }));
    } catch (error) {
      console.log(error);
    }
    dispatch(setLoader(false));
  };

export const getSettings = () => async (dispatch) => {
  dispatch(setLoader(true));
  try {
    const response = await Axios.get(`${BASE_URL}/api/admin/settings`, {
      withCredentials: true,
    });
    const settings = response.data.settings;
    const enviroment = settings.env;
    const testConfig = settings["test"];
    const prodConfig = settings["prod"];
    dispatch(setUser({ name: "enviroment", value: enviroment }));
    dispatch(setUser({ name: "testConfig", value: testConfig }));
    dispatch(setUser({ name: "prodConfig", value: prodConfig }));
  } catch (error) {
    console.log(error);
  }
  dispatch(setLoader(false));
};

export const swapCrypto =
  ({ data, navigate }) =>
  async (dispatch) => {
    dispatch(setLoader(true));
    try {
      await Axios.post(`${BASE_URL}/api/user/createSwap`, data, {
        withCredentials: true,
      });
      Swal.fire({
        position: "top-end",
        icon: "success",
        title: "Swap was created Successfully",
        showConfirmButton: false,
        timer: 1500,
      });
      navigate("/swaps");
    } catch (error) {
      console.log(error);
    }
    dispatch(setLoader(false));
  };

export const getSwaps = () => async (dispatch) => {
  dispatch(setLoader(true));
  try {
    const resp = await Axios.get(`${BASE_URL}/api/user/swaps`, {
      withCredentials: true,
    });
    const swaps = resp.data.swaps;
    dispatch(
      setUser({
        name: "swaps",
        value: swaps,
      })
    );
    dispatch(
      setUser({
        name: "filteredSwaps",
        value: swaps,
      })
    );
  } catch (error) {
    console.log(error);
  }
  dispatch(setLoader(false));
};

export const getUserSwaps =
  ({ data }) =>
  async (dispatch) => {
    dispatch(setLoader(true));
    try {
      const resp = await Axios.post(`${BASE_URL}/api/admin/userSwaps`, data, {
        withCredentials: true,
      });
      const swaps = resp.data.swaps;
      dispatch(
        setUser({
          name: "userSwaps",
          value: swaps,
        })
      );
      dispatch(
        setUser({
          name: "userFilteredSwaps",
          value: swaps,
        })
      );
    } catch (error) {
      console.log(error);
    }
    dispatch(setLoader(false));
  };

export const getOrders = () => async (dispatch) => {
  dispatch(setLoader(true));
  try {
    const resp = await Axios.get(`${BASE_URL}/api/admin/orders`, {
      withCredentials: true,
    });
    const orders = resp.data.orders;
    dispatch(
      setUser({
        name: "orders",
        value: orders,
      })
    );
    dispatch(
      setUser({
        name: "filteredOrders",
        value: orders,
      })
    );
  } catch (error) {
    console.log(error);
  }
  dispatch(setLoader(false));
};

export const getReferrals = () => async (dispatch) => {
  dispatch(setLoader(true));
  try {
    const resp = await Axios.get(`${BASE_URL}/api/admin/referrals`, {
      withCredentials: true,
    });
    const referrals = resp.data.referrals;
    dispatch(
      setUser({
        name: "referrals",
        value: referrals,
      })
    );
    dispatch(
      setUser({
        name: "filteredReferrals",
        value: referrals,
      })
    );
  } catch (error) {
    console.log(error);
  }
  dispatch(setLoader(false));
};

export const setSearchReferral = (data) => async (dispatch) => {
  try {
    dispatch({ type: SET_SEARCH_REFERRAL, payload: data });
  } catch (error) {
    console.log(error);
  }
};

export const setSearchSwap = (data) => async (dispatch) => {
  try {
    dispatch({ type: SET_SEARCH_SWAP, payload: data });
  } catch (error) {
    console.log(error);
  }
};

export const setUserSearchSwap = (data) => async (dispatch) => {
  try {
    dispatch({ type: SET_USER_SEARCH_SWAP, payload: data });
  } catch (error) {
    console.log(error);
  }
};

export const getUsers = () => async (dispatch) => {
  dispatch(setLoader(true));
  try {
    const resp = await Axios.get(`${BASE_URL}/api/admin/getUsers`, {
      withCredentials: true,
    });
    const users = resp.data.users;
    dispatch(
      setUser({
        name: "users",
        value: users,
      })
    );
    dispatch(
      setUser({
        name: "filteredUsers",
        value: users,
      })
    );
  } catch (error) {
    console.log(error);
  }
  dispatch(setLoader(false));
};

export const getOrderDetail =
  ({ data, navigate }) =>
  async (dispatch) => {
    dispatch(setLoader(true));
    try {
      const resp = await Axios.post(`${BASE_URL}/api/admin/orderDetail`, data, {
        withCredentials: true,
      });
      const order = resp.data.order;
      if (order) {
        dispatch(
          setUser({
            name: "selectedOrder",
            value: order,
          })
        );
        const isBuy = order.type === "buy";
        if (isBuy) {
          const coin = order.coin;
          if (coin === "BTC") {
            const address = order.bitcoin.walletAddress;
            dispatch(
              setBtc({
                name: "recipientAddress",
                value: address,
              })
            );
          } else if (coin === "ETH") {
            const address = order.ethereum.address;
            dispatch(
              setETH({
                name: "recipientAddress",
                value: address,
              })
            );
          } else if (coin === "USDT") {
            const address = order.ethereum.address;
            dispatch(
              setUSDT({
                name: "recipientAddress",
                value: address,
              })
            );
          }
        }
        navigate("/reviewOrder");
      } else {
        Swal.fire({
          icon: "error",
          title: "Error Occured",
          text: "Order not found",
        });
      }
    } catch (error) {
      console.log(error);
    }
    dispatch(setLoader(false));
  };

export const updateOrder =
  ({ data, navigate }) =>
  async (dispatch) => {
    dispatch(setLoader(true));
    try {
      const status = data?.status;
      await Axios.post(`${BASE_URL}/api/admin/updateOrder`, data, {
        withCredentials: true,
      });
      Swal.fire({
        position: "top-end",
        icon: "success",
        title: `Order was ${status} Successfully`,
        showConfirmButton: false,
        timer: 1500,
      });
      navigate("/orders");
    } catch (error) {
      const data = error?.response.data;
      const errorMsg = data?.errorMsg;
      if (errorMsg) {
        Swal.fire({
          icon: "error",
          title: "Error Occured",
          text: errorMsg,
        });
      }
      navigate("/orders");
      console.log(error);
    }
    dispatch(setLoader(false));
  };

export const updateSettings =
  ({ data }) =>
  async (dispatch) => {
    dispatch(setLoader(true));
    try {
      await Axios.post(`${BASE_URL}/api/admin/updateSettings`, data, {
        withCredentials: true,
      });
      Swal.fire({
        position: "top-end",
        icon: "success",
        title: `Configurations are updated`,
        showConfirmButton: false,
        timer: 1500,
      });
    } catch (error) {
      console.log(error);
    }
    dispatch(setLoader(false));
  };

export const setTestSettings = (data) => async (dispatch) => {
  try {
    dispatch({ type: SET_TEST_SETTINGS, payload: data });
  } catch (error) {
    console.log(error);
  }
};

export const setProdSettings = (data) => async (dispatch) => {
  try {
    dispatch({ type: SET_PROD_SETTINGS, payload: data });
  } catch (error) {
    console.log(error);
  }
};

export const setSearchUser = (data) => async (dispatch) => {
  try {
    dispatch({ type: SET_SEARCH_USER, payload: data });
  } catch (error) {
    console.log(error);
  }
};

export const setSearchOrder = (data) => async (dispatch) => {
  try {
    dispatch({ type: SET_SEARCH_ORDER, payload: data });
  } catch (error) {
    console.log(error);
  }
};

export const setUpdatePassword = (data) => async (dispatch) => {
  try {
    dispatch({ type: SET_UPDATE_PASSWORD_FORM, payload: data });
  } catch (error) {
    console.log(error);
  }
};

export const setPinForm = (data) => async (dispatch) => {
  try {
    dispatch({ type: SET_UPDATE_PIN, payload: data });
  } catch (error) {
    console.log(error);
  }
};

export const getUser =
  ({ data, navigate }) =>
  async (dispatch) => {
    dispatch(setLoader(true));
    try {
      const resp = await Axios.post(`${BASE_URL}/api/admin/getUser`, data, {
        withCredentials: true,
      });
      const user = resp.data.user;
      dispatch(
        setUser({
          name: "selectedUser",
          value: user,
        })
      );
      dispatch(setUserDetailProfile(user));
      navigate("/viewUserDetail");
    } catch (error) {
      console.log(error);
    }
    dispatch(setLoader(false));
  };

export const updateEnv =
  ({ data }) =>
  async (dispatch) => {
    dispatch(setLoader(true));
    try {
      await Axios.post(`${BASE_URL}/api/admin/updateEnv`, data, {
        withCredentials: true,
      });
      Swal.fire({
        icon: "success",
        title: `Enviroment Changed`,
      });
      // dispatch(getSettings());
    } catch (error) {
      console.log(error);
    }
    dispatch(setUser({ name: "enviroment", value: data.env }));
    dispatch(setLoader(false));
  };

export const updatePassword =
  ({ data, navigate }) =>
  async (dispatch) => {
    dispatch(setLoader(true));
    try {
      await Axios.post(`${BASE_URL}/api/user/updatePassword`, data, {
        withCredentials: true,
      });
      navigate("/settings");
      Swal.fire({
        position: "top-end",
        icon: "success",
        title: `Password was updated`,
        showConfirmButton: false,
        timer: 1500,
      });
    } catch (error) {
      const response = error?.response;
      const data = response?.data;
      const errorMsg = data?.errorMsg;
      if (errorMsg) {
        Swal.fire({
          icon: "error",
          title: "Error Occured",
          text: errorMsg,
        });
      }
      console.log(error);
    }
    dispatch(setLoader(false));
  };

export const updatePin =
  ({ data, navigate }) =>
  async (dispatch) => {
    dispatch(setLoader(true));
    try {
      await Axios.post(`${BASE_URL}/api/user/updatePin`, data, {
        withCredentials: true,
      });
      Swal.fire({
        position: "top-end",
        icon: "success",
        title: `Pin was updated`,
        showConfirmButton: false,
        timer: 1500,
      });
      navigate("/settings");
    } catch (error) {
      const response = error?.response;
      const data = response?.data;
      const errorMsg = data?.errorMsg;
      if (errorMsg) {
        Swal.fire({
          icon: "error",
          title: "Error Occured",
          text: errorMsg,
        });
      }
      console.log(error);
    }
    dispatch(setLoader(false));
  };

export const checkPIN =
  ({ data }) =>
  async (dispatch) => {
    dispatch(setLoader(true));
    try {
      const response = await Axios.post(`${BASE_URL}/api/user/checkPin`, data, {
        withCredentials: true,
      });
      const isCorrectPin = response.data.isCorrectPin;
      dispatch(setLoader(false));
      return isCorrectPin;
    } catch (error) {
      const response = error?.response;
      const data = response?.data;
      const errorMsg = data?.errorMsg;
      if (errorMsg) {
        Swal.fire({
          icon: "error",
          title: "Error Occured",
          text: errorMsg,
        });
      }
      console.log(error);
    }
    dispatch(setLoader(false));
  };

export const isValidAddress =
  ({ data }) =>
  async (dispatch) => {
    dispatch(setLoader(true));
    try {
      let coin = data.coin;
      coin = coin.toLowerCase();
      const response = await Axios.post(
        `${BASE_URL}/api/${coin}/isValidAddress`,
        data,
        {
          withCredentials: true,
        }
      );
      const isValidAddress = response.data.isValidAddress;
      dispatch(setLoader(false));
      return isValidAddress;
    } catch (error) {
      console.log(error);
      dispatch(setLoader(false));
      return false;
    }
  };
