import {
  BASE_URL,
  SET_SEARCH_USDT_TRANSACTION,
  SET_SEARCH_USDT_USER_TRANSACTION,
  SET_STATUS,
  SET_USDT,
  SET_USDT_CHART,
} from "./types";
import Axios from "axios";
import Swal from "sweetalert2";
import { setLoader } from "./userAction";

// Set ETH reducer
export const setUSDT = (data) => async (dispatch) => {
  try {
    dispatch({ type: SET_USDT, payload: data });
  } catch (error) {
    console.log(error);
  }
};

// Login a User
export const getUSDTPrices = (data) => async (dispatch) => {
  try {
    const resp = await Axios.post(`${BASE_URL}/api/usdt/getPrices`, data, {
      withCredentials: true,
    });
    const dataset = resp.data.dataset;
    dispatch({ type: SET_USDT_CHART, payload: dataset });
  } catch (error) {
    const data = error?.response.data;
    const errorMsg = data?.errorMsg;
    if (errorMsg) {
      Swal.fire({
        icon: "error",
        title: "Error Occured",
        text: errorMsg,
      });
    }
    console.log(error);
  }
};

// get user address
export const getUSDTAddress = () => async (dispatch) => {
  try {
    const resp = await Axios.get(`${BASE_URL}/api/usdt/address`, {
      withCredentials: true,
      
    });
    const address = resp.data.address;
    dispatch({
      type: SET_USDT,
      payload: {
        name: "address",
        value: address,
      },
    });
  } catch (error) {
    const data = error?.response.data;
    const errorMsg = data?.errorMsg;
    if (errorMsg) {
      Swal.fire({
        icon: "error",
        title: "Error Occured",
        text: errorMsg,
      });
    }
    console.log(error);
  }
};

// get user balance
export const getUSDTBalance = () => async (dispatch) => {
  try {
    const resp = await Axios.get(`${BASE_URL}/api/usdt/balance`, {
      withCredentials: true,     
    });
    const balance = resp.data.balance;
    dispatch({
      type: SET_USDT,
      payload: {
        name: "balance",
        value: balance,
      },
    });
  } catch (error) {
    const data = error?.response.data;
    const errorMsg = data?.errorMsg;
    if (errorMsg) {
      Swal.fire({
        icon: "error",
        title: "Error Occured",
        text: errorMsg,
      });
    }
    console.log(error);
  }
};

// get user balance
export const getUSDTUserBalance =
  ({ data }) =>
  async (dispatch) => {
    try {
      const resp = await Axios.post(`${BASE_URL}/api/usdt/userBalance`, data, {
        withCredentials: true,
      });
      const balance = resp.data.balance;
      dispatch({
        type: SET_USDT,
        payload: {
          name: "userBalance",
          value: balance,
        },
      });
    } catch (error) {
      console.log(error);
    }
  };

export const getUsdtCurrentPrice = (data) => async (dispatch) => {
  try {
    const resp = await Axios.post(`${BASE_URL}/api/usdt/currentPrice`, data, {
      withCredentials: true,
    });
    const price = resp.data.price;
    dispatch({
      type: SET_USDT,
      payload: {
        name: "priceNow",
        value: price,
      },
    });
  } catch (error) {
    const data = error?.response.data;
    const errorMsg = data?.errorMsg;
    if (errorMsg) {
      Swal.fire({
        icon: "error",
        title: "Error Occured",
        text: errorMsg,
      });
    }
    console.log(error);
  }
};

// Get Gas Price of a Transaction
export const estimateUSDTGas =
  ({ data }) =>
  async (dispatch) => {
    dispatch(setLoader(true));
    try {
      const isFee = data.isFee;
      const resp = await Axios.post(`${BASE_URL}/api/usdt/estimateGas`, data, {
        withCredentials: true,
      });
      const estimatedGas = resp.data.estimatedGas;
      if (isFee) {
        dispatch({
          type: SET_USDT,
          payload: {
            name: "gasPriceFee",
            value: estimatedGas,
          },
        });
      } else {
        dispatch({
          type: SET_USDT,
          payload: {
            name: "gasPrice",
            value: estimatedGas,
          },
        });
      }
    } catch (error) {
      const data = error?.response.data;
      const errorMsg = data?.errorMsg;
      if (errorMsg) {
        Swal.fire({
          icon: "error",
          title: "Error Occured",
          text: errorMsg,
        });
      }
      console.log(error);
    }
    dispatch(setLoader(false));
  };

// Send USDT
export const sendUSDT =
  ({ data, navigate }) =>
  async (dispatch) => {
    dispatch(setLoader(true));
    try {
      const resp = await Axios.post(`${BASE_URL}/api/usdt/send`, data, {
        withCredentials: true,
      });
      const transactionHash = resp.data.transactionHash;
      console.log(transactionHash);
      navigate("/coinWallet");
      Swal.fire({
        position: "top-end",
        icon: "success",
        title: "Transaction Sent Successfully",
        showConfirmButton: false,
        timer: 1500,
      });
      dispatch(setUSDT({ name: "sendAmount", value: "" }));
      dispatch(setUSDT({ name: "recipientAddress", value: "" }));
    } catch (error) {
      const data = error?.response.data;
      const errorMsg = data?.errorMsg;
      if (errorMsg) {
        Swal.fire({
          icon: "error",
          title: "Error Occured",
          text: errorMsg,
        });
      }
      navigate("/coinWallet");
      console.log(error);
    }
    dispatch(setLoader(false));
  };

export const getUsdtTransactions = () => async (dispatch) => {
  dispatch(setLoader(true));
  try {
    const resp = await Axios.get(`${BASE_URL}/api/usdt/transactions`, {
      withCredentials: true,
    });
    const transactions = resp.data.transactions;
    dispatch(
      setUSDT({
        name: "transactions",
        value: transactions,
      })
    );
    dispatch(
      setUSDT({
        name: "filteredTransactions",
        value: transactions,
      })
    );
  } catch (error) {
    console.log(error);
  }
  dispatch(setLoader(false));
};

export const getUsdtUserTransactions =
  ({ data }) =>
  async (dispatch) => {
    dispatch(setLoader(true));
    try {
      const resp = await Axios.post(
        `${BASE_URL}/api/usdt/userTransactions`,
        data,
        {
          withCredentials: true,
        }
      );
      const transactions = resp.data.transactions;
      dispatch(
        setUSDT({
          name: "usertransactions",
          value: transactions,
        })
      );
      dispatch(
        setUSDT({
          name: "userfilteredTransactions",
          value: transactions,
        })
      );
    } catch (error) {
      console.log(error);
    }
    dispatch(setLoader(false));
  };

export const setSearchUsdtTransaction = (data) => async (dispatch) => {
  try {
    dispatch({ type: SET_SEARCH_USDT_TRANSACTION, payload: data });
  } catch (error) {
    console.log(error);
  }
};

export const setSearchUserUsdtTransaction = (data) => async (dispatch) => {
  try {
    dispatch({ type: SET_SEARCH_USDT_USER_TRANSACTION, payload: data });
  } catch (error) {
    console.log(error);
  }
};
