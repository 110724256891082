const currencies = [
  { value: "AFN", label: "🇦🇫 AFN", symbol: "؋" },
  { value: "ALL", label: "🇦🇱 ALL", symbol: "L" },
  { value: "DZD", label: "🇩🇿 DZD", symbol: "د.ج" },
  { value: "AOA", label: "🇦🇴 AOA", symbol: "Kz" },
  { value: "XCD", label: "🇦🇬 XCD", symbol: "$" },
  { value: "ARS", label: "🇦🇷 ARS", symbol: "$" },
  { value: "AMD", label: "🇦🇲 AMD", symbol: "֏" },
  { value: "AUD", label: "🇦🇺 AUD", symbol: "$" },
  { value: "AZN", label: "🇦🇿 AZN", symbol: "₼" },
  { value: "BSD", label: "🇧🇸 BSD", symbol: "$" },
  { value: "BHD", label: "🇧🇭 BHD", symbol: ".د.ب" },
  { value: "BDT", label: "🇧🇩 BDT", symbol: "৳" },
  { value: "BBD", label: "🇧🇧 BBD", symbol: "$" },
  { value: "BYN", label: "🇧🇾 BYN", symbol: "Br" },
  { value: "BZD", label: "🇧🇿 BZD", symbol: "BZ$" },
  { value: "XOF", label: "🇧🇯 XOF", symbol: "CFA" },
  { value: "BTN", label: "🇧🇹 BTN", symbol: "Nu." },
  { value: "BOB", label: "🇧🇴 BOB", symbol: "Bs." },
  { value: "BAM", label: "🇧🇦 BAM", symbol: "KM" },
  { value: "BWP", label: "🇧🇼 BWP", symbol: "P" },
  { value: "BRL", label: "🇧🇷 BRL", symbol: "R$" },
  { value: "BND", label: "🇧🇳 BND", symbol: "$" },
  { value: "BGN", label: "🇧🇬 BGN", symbol: "лв" },
  { value: "XOF", label: "🇧🇫 XOF", symbol: "CFA" },
  { value: "BIF", label: "🇧🇮 BIF", symbol: "FBu" },
  { value: "KHR", label: "🇰🇭 KHR", symbol: "៛" },
  { value: "XAF", label: "🇨🇲 XAF", symbol: "FCFA" },
  { value: "CAD", label: "🇨🇦 CAD", symbol: "$" },
  { value: "CVE", label: "🇨🇻 CVE", symbol: "$" },
  { value: "XAF", label: "🇨🇫 XAF", symbol: "CFA" },
  { value: "XAF", label: "🇹🇩 XAF", symbol: "CFA" },
  { value: "CLP", label: "🇨🇱 CLP", symbol: "$" },
  { value: "CNY", label: "🇨🇳 CNY", symbol: "¥" },
  { value: "COP", label: "🇨🇴 COP", symbol: "$" },
  { value: "KMF", label: "🇰🇲 KMF", symbol: "CF" },
  { value: "CRC", label: "🇨🇷 CRC", symbol: "₡" },
  { value: "HRK", label: "🇭🇷 HRK", symbol: "kn" },
  { value: "CUP", label: "🇨🇺 CUP", symbol: "$" },
  { value: "CZK", label: "🇨🇿 CZK", symbol: "Kč" },
  { value: "CDF", label: "🇨🇩 CDF", symbol: "FC" },
  { value: "DKK", label: "🇩🇰 DKK", symbol: "kr" },
  { value: "DJF", label: "🇩🇯 DJF", symbol: "Fdj" },
  { value: "XCD", label: "🇩🇲 XCD", symbol: "$" },
  { value: "DOP", label: "🇩🇴 DOP", symbol: "$" },
  { value: "EGP", label: "🇪🇬 EGP", symbol: "ج.م" },
  { value: "XAF", label: "🇬🇶 XAF", symbol: "CFA" },
  { value: "ERN", label: "🇪🇷 ERN", symbol: "Nfk" },
  { value: "ETB", label: "🇪🇹 ETB", symbol: "Br" },
  { value: "FJD", label: "🇫🇯 FJD", symbol: "$" },
  { value: "XAF", label: "🇬🇦 XAF", symbol: "CFA" },
  { value: "GMD", label: "🇬🇲 GMD", symbol: "D" },
  { value: "GEL", label: "🇬🇪 GEL", symbol: "₾" },
  { value: "GHS", label: "🇬🇭 GHS", symbol: "₵" },
  { value: "XCD", label: "🇬🇩 XCD", symbol: "$" },
  { value: "GTQ", label: "🇬🇹 GTQ", symbol: "Q" },
  { value: "GNF", label: "🇬🇳 GNF", symbol: "FG" },
  { value: "XOF", label: "🇬🇼 XOF", symbol: "CFA" },
  { value: "GYD", label: "🇬🇾 GYD", symbol: "$" },
  { value: "HTG", label: "🇭🇹 HTG", symbol: "G" },
  { value: "HNL", label: "🇭🇳 HNL", symbol: "L" },
  { value: "HKD", label: "🇭🇰 HKD", symbol: "$" },
  { value: "HUF", label: "🇭🇺 HUF", symbol: "Ft" },
  { value: "ISK", label: "🇮🇸 ISK", symbol: "kr" },
  { value: "INR", label: "🇮🇳 INR", symbol: "₹" },
  { value: "IDR", label: "🇮🇩 IDR", symbol: "Rp" },
  { value: "IRR", label: "🇮🇷 IRR", symbol: "﷼" },
  { value: "IQD", label: "🇮🇶 IQD", symbol: "ع.د" },
  { value: "ILS", label: "🇮🇱 ILS", symbol: "₪" },
  { value: "JMD", label: "🇯🇲 JMD", symbol: "J$" },
  { value: "JPY", label: "🇯🇵 JPY", symbol: "¥" },
  { value: "JOD", label: "🇯🇴 JOD", symbol: "د.ا" },
  { value: "KZT", label: "🇰🇿 KZT", symbol: "₸" },
  { value: "KES", label: "🇰🇪 KES", symbol: "KSh" },
  { value: "AUD", label: "🇰🇮 AUD", symbol: "$" },
  { value: "KWD", label: "🇰🇼 KWD", symbol: "د.ك" },
  { value: "KGS", label: "🇰🇬 KGS", symbol: "с" },
  { value: "LAK", label: "🇱🇦 LAK", symbol: "₭" },
  { value: "LBP", label: "🇱🇧 LBP", symbol: "ل.ل" },
  { value: "LSL", label: "🇱🇸 LSL", symbol: "L" },
  { value: "LRD", label: "🇱🇷 LRD", symbol: "$" },
  { value: "LYD", label: "🇱🇾 LYD", symbol: "ل.د" },
  { value: "CHF", label: "🇱🇮 CHF", symbol: "CHF" },
  { value: "MKD", label: "🇲🇰 MKD", symbol: "ден" },
  { value: "MGA", label: "🇲🇬 MGA", symbol: "Ar" },
  { value: "MWK", label: "🇲🇼 MWK", symbol: "MK" },
  { value: "MYR", label: "🇲🇾 MYR", symbol: "RM" },
  { value: "MVR", label: "🇲🇻 MVR", symbol: "Rf" },
  { value: "XOF", label: "🇲🇱 XOF", symbol: "CFA" },
  { value: "XOF", label: "🇲🇷 XOF", symbol: "CFA" },
  { value: "XCD", label: "🇲🇸 XCD", symbol: "$" },
  { value: "MAD", label: "🇲🇦 MAD", symbol: "د.م." },
  { value: "MZN", label: "🇲🇿 MZN", symbol: "MT" },
  { value: "MMK", label: "🇲🇲 MMK", symbol: "K" },
  { value: "NAD", label: "🇳🇦 NAD", symbol: "$" },
  { value: "AUD", label: "🇳🇷 AUD", symbol: "$" },
  { value: "NPR", label: "🇳🇵 NPR", symbol: "रू" },
  { value: "NZD", label: "🇳🇿 NZD", symbol: "$" },
  { value: "NIO", label: "🇳🇮 NIO", symbol: "C$" },
  { value: "XOF", label: "🇳🇪 XOF", symbol: "CFA" },
  { value: "NGN", label: "🇳🇬 NGN", symbol: "₦" },
  { value: "KPW", label: "🇰🇵 KPW", symbol: "₩" },
  { value: "NOK", label: "🇳🇴 NOK", symbol: "kr" },
  { value: "OMR", label: "🇴🇲 OMR", symbol: "ر.ع." },
  { value: "PKR", label: "🇵🇰 PKR", symbol: "₨" },
  { value: "ILS", label: "🇵🇸 ILS", symbol: "₪" },
  { value: "PAB", label: "🇵🇦 PAB", symbol: "B/." },
  { value: "PGK", label: "🇵🇬 PGK", symbol: "K" },
  { value: "PYG", label: "🇵🇾 PYG", symbol: "₲" },
  { value: "PEN", label: "🇵🇪 PEN", symbol: "S/." },
  { value: "PHP", label: "🇵🇭 PHP", symbol: "₱" },
  { value: "PLN", label: "🇵🇱 PLN", symbol: "zł" },
  { value: "QAR", label: "🇶🇦 QAR", symbol: "ر.ق" },
  { value: "XAF", label: "🇨🇬 XAF", symbol: "CFA" },
  { value: "RON", label: "🇷🇴 RON", symbol: "lei" },
  { value: "RUB", label: "🇷🇺 RUB", symbol: "₽" },
  { value: "RWF", label: "🇷🇼 RWF", symbol: "RF" },
  { value: "WST", label: "🇼🇸 WST", symbol: "T" },
  { value: "STN", label: "🇸🇹 STN", symbol: "Db" },
  { value: "SAR", label: "🇸🇦 SAR", symbol: "ر.س" },
  { value: "XOF", label: "🇸🇳 XOF", symbol: "CFA" },
  { value: "RSD", label: "🇷🇸 RSD", symbol: "дин." },
  { value: "SCR", label: "🇸🇨 SCR", symbol: "₨" },
  { value: "SLL", label: "🇸🇱 SLL", symbol: "Le" },
  { value: "SGD", label: "🇸🇬 SGD", symbol: "$" },
  { value: "SBD", label: "🇸🇧 SBD", symbol: "SI$" },
  { value: "SOS", label: "🇸🇴 SOS", symbol: "S" },
  { value: "ZAR", label: "🇿🇦 ZAR", symbol: "R" },
  { value: "KRW", label: "🇰🇷 KRW", symbol: "₩" },
  { value: "SSP", label: "🇸🇸 SSP", symbol: "£" },
  { value: "EUR", label: "🇪🇺 EUR", symbol: "€" },
  { value: "LKR", label: "🇱🇰 LKR", symbol: "₨" },
  { value: "XCD", label: "🇰🇳 XCD", symbol: "$" },
  { value: "XCD", label: "🇱🇨 XCD", symbol: "$" },
  { value: "XCD", label: "🇻🇨 XCD", symbol: "$" },
  { value: "SDG", label: "🇸🇩 SDG", symbol: "ج.س." },
  { value: "SRD", label: "🇸🇷 SRD", symbol: "$" },
  { value: "NOK", label: "🇸🇯 NOK", symbol: "kr" },
  { value: "SZL", label: "🇸🇿 SZL", symbol: "L" },
  { value: "SEK", label: "🇸🇪 SEK", symbol: "kr" },
  { value: "CHE", label: "🇨🇭 CHE", symbol: "CHF" },
  { value: "CHW", label: "🇨🇭 CHW", symbol: "CHF" },
  { value: "SYP", label: "🇸🇾 SYP", symbol: "£" },
  { value: "TWD", label: "🇹🇼 TWD", symbol: "NT$" },
  { value: "TJS", label: "🇹🇯 TJS", symbol: "ЅМ" },
  { value: "TZS", label: "🇹🇿 TZS", symbol: "TSh" },
  { value: "THB", label: "🇹🇭 THB", symbol: "฿" },
  { value: "XOF", label: "🇹🇬 XOF", symbol: "CFA" },
  { value: "NZD", label: "🇹🇰 NZD", symbol: "$" },
  { value: "TOP", label: "🇹🇴 TOP", symbol: "T$" },
  { value: "TTD", label: "🇹🇹 TTD", symbol: "TT$" },
  { value: "TND", label: "🇹🇳 TND", symbol: "د.ت" },
  { value: "TRY", label: "🇹🇷 TRY", symbol: "₺" },
  { value: "TMT", label: "🇹🇲 TMT", symbol: "T" },
  { value: "AUD", label: "🇹🇻 AUD", symbol: "$" },
  { value: "UGX", label: "🇺🇬 UGX", symbol: "USh" },
  { value: "UAH", label: "🇺🇦 UAH", symbol: "₴" },
  { value: "AED", label: "🇦🇪 AED", symbol: "د.إ" },
  { value: "GBP", label: "🇬🇧 GBP", symbol: "£" },
  { value: "USD", label: "🇺🇸 USD", symbol: "$" },
  { value: "UYI", label: "🇺🇾 UYI", symbol: "$U" },
  { value: "UYU", label: "🇺🇾 UYU", symbol: "$U" },
  { value: "UZS", label: "🇺🇿 UZS", symbol: "so'm" },
  { value: "VUV", label: "🇻🇺 VUV", symbol: "VT" },
  { value: "VES", label: "🇻🇪 VES", symbol: "Bs" },
  { value: "VND", label: "🇻🇳 VND", symbol: "₫" },
  { value: "XPF", label: "🇵🇫 XPF", symbol: "₣" },
  { value: "MAD", label: "🇪🇭 MAD", symbol: "د.م." },
  { value: "YER", label: "🇾🇪 YER", symbol: "﷼" },
  { value: "ZMW", label: "🇿🇲 ZMW", symbol: "ZK" },
  { value: "ZWL", label: "🇿🇼 ZWL", symbol: "Z$" },
];

export default currencies;
