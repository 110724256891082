import {
  CLEAR_STATE,
  SET_ETH,
  SET_ETH_CHART,
  SET_SEARCH_ETH_TRANSACTION,
  SET_SEARCH_ETH_USER_TRANSACTION,
} from "../actions/types";
const initialState = {
  prices: [],
  currencyDiff: "",
  labels: [],
  percentageDiff: "",
  priceNow: 0,
  address: "",
  balance: 0,
  userBalance: 0,
  balanceCurrency: 0,
  sendAmount: "",
  recipientAddress: "",
  gasPrice: "",
  gasPriceFee: "",
  currentTransactionPage: 1,
  transactions: [],
  paginatedTransactions: [],
  totalTransactionPages: 1,
  transactionsPerPage: 5,
  selectedTransaction: null,
  // Transactions
  transactions: [],
  filteredTransactions: [],
  paginatedTransactions: [],
  currentTransactionPage: 1,
  totalTransactionPages: 1,
  transactionPerPage: 5,
  transactionSearch: "",
  // User Transactions
  usertransactions: [],
  userfilteredTransactions: [],
  userpaginatedTransactions: [],
  usercurrentTransactionPage: 1,
  usertotalTransactionPages: 1,
  usertransactionPerPage: 5,
  usertransactionSearch: "",
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_ETH: {
      return {
        ...state,
        [action.payload.name]: action.payload.value,
      };
    }
    case SET_ETH_CHART: {
      const { labels, prices, currencyDiff, percentageDiff, priceNow } =
        action.payload;
      return {
        ...state,
        labels,
        prices,
        currencyDiff,
        percentageDiff,
        priceNow,
      };
    }
    case SET_SEARCH_ETH_TRANSACTION: {
      const value = action.payload;
      const filteredTransactions = state.transactions.filter((transaction) => {
        const regex = new RegExp(value, "i");
        const { type, sender, receiver, transactionHx } = transaction;

        return (
          value === "" ||
          regex.test(type) ||
          regex.test(sender) ||
          regex.test(receiver) ||
          regex.test(transactionHx)
        );
      });
      return { ...state, filteredTransactions, transactionSearch: value };
    }
    case SET_SEARCH_ETH_USER_TRANSACTION: {
      const value = action.payload;
      const filteredTransactions = state.usertransactions.filter(
        (transaction) => {
          const regex = new RegExp(value, "i");
          const { type, sender, receiver, transactionHx, date } = transaction;
          
          const transactionDate = new Date(date);
          const day = transactionDate.getDate();
          const month = transactionDate.getMonth() + 1;
          const year = transactionDate.getFullYear();
          const formattedDate = `${day}-${month}-${year}`;

          return (
            value === "" ||
            regex.test(type) ||
            regex.test(sender) ||
            regex.test(receiver) ||
            regex.test(transactionHx) ||
            regex.test(formattedDate)
          );
        }
      );
      return {
        ...state,
        userfilteredTransactions: filteredTransactions,
        usertransactionSearch: value,
      };
    }
    case CLEAR_STATE: {
      return initialState;
    }
    default:
      return state;
  }
};
