import React, { useEffect } from "react";
import "../CoinWallet/index.css";
import "./index.css";
import withDashboard from "../../HOC/withDashboard";
import BtcImg from "../../assets/bitcoin.png";
import EthImg from "../../assets/ethereum.png";
import UsdtImg from "../../assets/usdt.png";
import { Button } from "@mui/material";
import Chart from "../../components/Chart";
import CardLayout from "../../components/CardLayout";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import PriceCardList from "../../components/PriceCardList";
import { estimateBTCGas } from "../../redux/actions/btcAction";
import { estimateETHGas } from "../../redux/actions/ethAction";
import { estimateUSDTGas } from "../../redux/actions/usdtAction";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { formatNumberWithCommas } from "../../utils";
import { getSwapEstimate } from "../../redux/actions/userAction";
import Swal from "sweetalert2";

const ReviewTransaction = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const Btc = useSelector(({ Btc }) => Btc);
  const Eth = useSelector(({ Eth }) => Eth);
  const Usdt = useSelector(({ Usdt }) => Usdt);
  const User = useSelector(({ User }) => User);
  const {
    selectedCoin,
    currentUser,
    swapCoinFromSelect,
    swapCoinFrom,
    swapCoinToSelect,
    swapEstimate,
  } = User;
  const { currencySymbol, currency } = currentUser || {};

  const coinMappings = {
    BTC: {
      coinName: "Bitcoin",
      circleColor: "yellow-circle",
      baseColor: "yellow",
      borderColor: "rgba(244, 183, 63, 1)",
      backgroundColor: "rgba(244, 183, 63, 0.2)",
      selectedReducer: Btc,
      image: BtcImg,
    },
    ETH: {
      coinName: "Ethereum",
      circleColor: "blue-circle",
      baseColor: "blue",
      borderColor: "rgba(0, 107, 250, 1)",
      backgroundColor: "rgba(0, 107, 250, 0.2)",
      selectedReducer: Eth,
      image: EthImg,
    },
    USDT: {
      coinName: "Tether",
      circleColor: "green-circle",
      baseColor: "green",
      borderColor: "rgba(75, 192, 192, 1)",
      backgroundColor: "rgba(75, 192, 192, 0.2)",
      selectedReducer: Usdt,
      image: UsdtImg,
    },
  };

  const coinFrom = swapCoinFromSelect;
  const selectedCoinFromData = coinMappings[coinFrom];
  const coinFromName = selectedCoinFromData?.coinName;
  const baseColorFrom = selectedCoinFromData?.baseColor;
  const borderColorFrom = selectedCoinFromData?.borderColor;
  const backgroundColorFrom = selectedCoinFromData?.backgroundColor;
  const selectedReducerFrom = selectedCoinFromData?.selectedReducer;
  const imageFrom = selectedCoinFromData?.image;
  //  Taken from CoinFrom Reducer
  const pricesFrom = selectedReducerFrom?.prices;
  const labelsFrom = selectedReducerFrom?.labels;
  const balanceFrom = selectedReducerFrom?.balance;
  const gasPrice = selectedReducerFrom?.gasPrice;
  const sendAmount = selectedReducerFrom?.sendAmount;
  const recipientAddress = selectedReducerFrom?.recipientAddress;
  const priceNowFrom = formatNumberWithCommas(selectedReducerFrom?.priceNow);
  // Coin To Recieve
  const coinTo = swapCoinToSelect;
  const selectedCoinToData = coinMappings[swapCoinToSelect];
  const borderColorTo = selectedCoinToData?.borderColor;
  const backgroundColorTo = selectedCoinToData?.backgroundColor;
  const selectedReducerTo = selectedCoinToData?.selectedReducer;
  const imageTo = selectedCoinToData?.image;
  //  Taken from Coin To Reducer
  const pricesTo = selectedReducerTo?.prices;
  const labelsTo = selectedReducerTo?.labels;
  const balanceTo = selectedReducerTo?.balance;
  const priceNowTo = formatNumberWithCommas(selectedReducerTo?.priceNow);

  // Format Balance CoinFrom
  let formattedBalanceFrom = parseFloat(balanceFrom).toFixed(7);
  if (Number.isInteger(parseFloat(formattedBalanceFrom))) {
    formattedBalanceFrom = parseFloat(formattedBalanceFrom).toString();
  }
  // Format Balance CoinTo
  let formattedBalanceTo = parseFloat(balanceTo).toFixed(7);
  if (Number.isInteger(parseFloat(formattedBalanceTo))) {
    formattedBalanceTo = parseFloat(formattedBalanceTo).toString();
  }

  // Format Date
  const date = new Date();
  const formattedDate = `${date.toLocaleTimeString("en-US", {
    hour: "numeric",
    minute: "2-digit",
  })} on ${date.toLocaleDateString("en-US", {
    year: "numeric",
    month: "short",
    day: "numeric",
  })}`;
  //   Total Amount
  const totalAmount =
    swapCoinFromSelect === "USDT"
      ? sendAmount
      : (parseFloat(gasPrice) + parseFloat(sendAmount)).toFixed(7);
  const transactionFeeCoin = coinFrom === "USDT" ? "ETH" : coinFrom;
  useEffect(() => {
    if (coinFrom === "BTC") {
      dispatch(
        estimateBTCGas({
          data: {
            amount: sendAmount,
            recipientAddress,
          },
        })
      );
    } else if (coinFrom === "ETH") {
      dispatch(
        estimateETHGas({
          data: {
            amount: sendAmount,
            recipientAddress,
          },
        })
      );
    } else if (coinFrom === "USDT") {
      dispatch(
        estimateUSDTGas({
          data: {
            amount: sendAmount,
            recipientAddress,
          },
        })
      );
    }
    dispatch(
      getSwapEstimate({
        data: {
          coinToSend: coinFrom,
          coinToRecieve: coinTo,
          amount: swapCoinFrom,
        },
      })
    );
    // eslint-disable-next-line
  }, []);

  const onContinue = () => {
    if (selectedCoin === "USDT") {
      const EthBalance = Eth.balance;
      if (EthBalance < gasPrice) {
        Swal.fire({
          icon: "error",
          title: "Error Occured",
          text: "Insufficent Ethereum Balance for Gas Fee",
        });
        return;
      }
    }

    if (balanceFrom < totalAmount) {
      Swal.fire({
        icon: "error",
        title: "Error Occured",
        text: `Insufficent ${coinFromName} Balance`,
      });
      return;
    }

    navigate("/confirmSwap");
  };
  return (
    <div className="wallets-container">
      <PriceCardList />
      <CardLayout>
        <div className="coinwallet-back-btn">
          <Button variant="outlined" onClick={() => navigate(-1)}>
            <ArrowBackIcon /> Back
          </Button>
        </div>
        <div className="coinwallet-container">
          <div className="coinwallet-info-container">
            <div className="coinwallet-info review-info">
              <img src={imageFrom} className="coinwallet-img" alt="coin.png" />
              <div className="coinwallet-info-text-container">
                <div className="coinwallet-info-price">
                  <span> Price: </span>
                  {currencySymbol}
                  {priceNowFrom} {currency}
                </div>
                <div className="coinwallet-info-balance">
                  <span> Balance: </span>
                  {formattedBalanceFrom} {coinFrom}
                </div>
              </div>
            </div>
            <div className="coinwallet-info review-info">
              <img src={imageTo} className="coinwallet-img" alt="coin.png" />
              <div className="coinwallet-info-text-container">
                <div className="coinwallet-info-price">
                  <span> Price: </span>
                  {currencySymbol}
                  {priceNowTo} {currency}
                </div>
                <div className="coinwallet-info-balance">
                  <span> Balance: </span>
                  {formattedBalanceTo} {coinTo}
                </div>
              </div>
            </div>
            <div className="coinwallet-chart-title">
              Price of {coinFrom} and {coinTo} in previous 7 days
            </div>
            <div className="coinwallet-chart">
              <Chart
                borderColor={borderColorFrom}
                backgroundColor={backgroundColorFrom}
                prices={pricesFrom}
                labels={labelsFrom}
                isDual={true}
                borderColorTo={borderColorTo}
                backgroundColorTo={backgroundColorTo}
                pricesTo={pricesTo}
                labelsTo={labelsTo}
              />
            </div>
          </div>
          <div className="review-transaction-details">
            <div className="review-transaction-item">
              <div className="review-transaction-title">Date:</div>
              <div className="review-transaction-value">{formattedDate}</div>
            </div>
            <div className="review-transaction-item">
              <div className="review-transaction-title">Amount Out:</div>
              <div className="review-transaction-value">
                {swapCoinFrom} {swapCoinFromSelect}
              </div>
            </div>
            <div className="review-transaction-item">
              <div className="review-transaction-title">Transaction Fee:</div>
              <div className="review-transaction-value">
                {gasPrice} {transactionFeeCoin}
              </div>
            </div>
            <div className="review-transaction-item">
              <div className="review-transaction-title">Total Amount Out:</div>
              <div className="review-transaction-value">
                {totalAmount} {coinFrom}
              </div>
            </div>
            <div className="review-transaction-item">
              <div className="review-transaction-title">Amount In:</div>
              <div className="review-transaction-value">
                {swapEstimate} {swapCoinToSelect}
              </div>
            </div>
            <div className="review-transaction-item">
              <div className="review-transaction-title">Sending From:</div>
              <div className="review-transaction-value">{coinFrom} Wallet</div>
            </div>
            <div className="review-btn-container">
              <Button
                variant="contained"
                className={`continue ${baseColorFrom}`}
                onClick={onContinue}
              >
                Continue
              </Button>
            </div>
          </div>
        </div>
      </CardLayout>
    </div>
  );
};

export default withDashboard(ReviewTransaction);
