import React, { useState } from "react";
import "./index.css";
import withDashboard from "../../HOC/withDashboard";
import BtcImg from "../../assets/bitcoin.png";
import EthImg from "../../assets/ethereum.png";
import UsdtImg from "../../assets/usdt.png";
import PriceCard from "../../components/PriceCard";
import CoinCard from "../../components/CoinCard";
import {
  Button,
  Card,
  IconButton,
  InputAdornment,
  TextField,
} from "@mui/material";
import Chart from "../../components/Chart";
import QRCode from "react-qr-code";
import CardLayout from "../../components/CardLayout";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import PriceCardList from "../../components/PriceCardList";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setBtc } from "../../redux/actions/btcAction";
import { setETH } from "../../redux/actions/ethAction";
import { setUSDT } from "../../redux/actions/usdtAction";
import Swal from "sweetalert2";
import ViewTransactions from "../../components/ViewTransactions";
import {
  setUpdatePassword,
  setUser,
  updatePassword,
} from "../../redux/actions/userAction";
import SettingsIcon from "@mui/icons-material/Settings";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import PasswordIcon from "@mui/icons-material/Password";
import Loader from "../../components/Loader";

const UpdatePassword = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showPassword, setshowPassword] = useState(false);
  const [shownewPassword, setshowNewPassword] = useState(false);
  const [showNewConfirmPassword, setshowNewConfirmPassword] = useState(false);
  const User = useSelector(({ User }) => User);
  const { updatePasswordForm } = User;
  const { currentPassword, newPassword, confirmPassword } =
    updatePasswordForm || {};
  const [errors, setErrors] = useState({
    currentPassword: false,
    newPassword: false,
    confirmPassword: false,
  });
  const onChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    dispatch(
      setUpdatePassword({
        name,
        value,
      })
    );
    setErrors({ ...errors, [name]: false });
  };
  const onClick = () => {
    let newErrors = {
      currentPassword: !currentPassword,
      newPassword: !newPassword,
      confirmPassword: !confirmPassword,
    };
    const everyFieldFilled = Object.values(newErrors).every((value) => !value);

    if (newPassword !== confirmPassword) {
      Swal.fire({
        icon: "error",
        title: "Error Occured",
        text: `New Password and Confirm is not the same`,
      });
    }
    if (everyFieldFilled) {
      dispatch(
        updatePassword({
          data: {
            currentPassword,
            newPassword,
          },
          navigate,
        })
      );
    } else {
      setErrors(newErrors);
    }
  };
  return (
    <div className="settings-container">
      <Card className="settings-card">
        <div className="coinwallet-back-btn">
          <Button variant="outlined" onClick={() => navigate(-1)}>
            <ArrowBackIcon /> Back
          </Button>
        </div>
        <div className="settings-title">
          <span>
            <PasswordIcon />
          </span>
          Update Password
        </div>
        <div className="settings-input-container">
          <div className="setting-input">
            <TextField
              label="Current Password"
              variant="outlined"
              className="w-100"
              name="currentPassword"
              type={showPassword ? "text" : "password"}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => setshowPassword(!showPassword)}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              onChange={onChange}
              value={currentPassword}
              error={errors.currentPassword}
              helperText={
                errors.currentPassword ? "Current Password is required" : ""
              }
            />
          </div>
          <div className="setting-input">
            <TextField
              label="New Password"
              variant="outlined"
              className="w-100"
              name="newPassword"
              type={shownewPassword ? "text" : "password"}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => setshowNewPassword(!shownewPassword)}
                      edge="end"
                    >
                      {shownewPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              onChange={onChange}
              value={newPassword}
              error={errors.newPassword}
              helperText={errors.newPassword ? "New Password is required" : ""}
            />
          </div>
          <div className="setting-input">
            <TextField
              label="Confirm New Password"
              variant="outlined"
              className="w-100"
              name="confirmPassword"
              type={showNewConfirmPassword ? "text" : "password"}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() =>
                        setshowNewConfirmPassword(!showNewConfirmPassword)
                      }
                      edge="end"
                    >
                      {showNewConfirmPassword ? (
                        <VisibilityOff />
                      ) : (
                        <Visibility />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              onChange={onChange}
              value={confirmPassword}
              error={errors.confirmPassword}
              helperText={
                errors.confirmPassword ? "Confirm Password is required" : ""
              }
            />
          </div>
          <div className="setting-btn-container">
            <Button
              variant="contained"
              className={`setting-btn`}
              onClick={onClick}
            >
              Update
            </Button>
          </div>
        </div>
        <Loader />
      </Card>
    </div>
  );
};

export default withDashboard(UpdatePassword);
