import React, { useState, useRef } from "react";
import "./index.css";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

const FourDigits = ({ digits, setDigits }) => {
  // const [digits, setDigits] = useState(["", "", "", ""]);
  const inputRefs = [useRef(null), useRef(null), useRef(null), useRef(null)];
  const [hideDigits, setHideDigits] = useState(true);
  const handleInputChange = (e, index) => {
    try {
      const value = e.target.value;
      if (/^\d*$/.test(value) && value.length <= 1) {
        const newDigits = [...digits];
        newDigits[index] = value;
        setDigits(newDigits);

        // Move to the next input field
        if (index < 3 && value !== "") {
          inputRefs[index + 1].current.focus();
        }
      }
    } catch (error) {}
  };

  const checkBackspace = (e, index) => {
    try {
      const key = e.key;
      const value = e.target.value;
      const isBackspace = e.key === "Backspace";
      if (isBackspace && value === "") {
        inputRefs[index - 1].current.focus();
      }
    } catch (error) {}
  };
  return (
    <div className="digits-container">
      <div className="digits-inputs-container">
        {digits.map((digit, index) => (
          <input
            key={index}
            className="digit-input"
            type={hideDigits ? "password" : "text"}
            value={digit}
            onChange={(e) => handleInputChange(e, index)}
            onKeyUp={(e) => checkBackspace(e, index)}
            ref={inputRefs[index]}
            autoComplete={false}
          />
        ))}
      </div>
      <div
        className="digit-icon-container"
        onClick={() => setHideDigits(!hideDigits)}
      >
        {hideDigits ? <VisibilityIcon /> : <VisibilityOffIcon />}
      </div>
    </div>
  );
};

export default FourDigits;
