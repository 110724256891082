import React, { Fragment, useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import "./index.css";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { checkOTP, login, setUser, setUserProfile } from "../../redux/actions/userAction";
import { IconButton, InputAdornment } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { isValidEmail } from "../../utils";
import { BASE_URL } from "../../redux/actions/types.js";
import Loader from "../../components/Loader";
import axios from 'axios';

const Login = () => {
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showPassword, setshowPassword] = useState(false);
  const User = useSelector(({ User }) => User);
  const { email, password } = User;
  const [errors, setErrors] = useState({
    email: false,
    password: false,
    code: false,
  });
  const [errorEmail, setErrorEmail] = useState("");
  const [errorCode, setErrorCode] = useState("");
  const [isInSecondStep, setIsInSecondStep] = useState(false);
  const [code, setCode] = useState('');

  const onChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    dispatch(
      setUser({
        name,
        value,
      })
    );
    setErrors({ ...errors, [name]: false });
  };
  const onClick = () => {
    let newErrors = {
      email: !email,
      password: !password,
    };
    if (email.toString().trim() === "") {
      setErrorEmail("Email is requried");
      newErrors.email = true;
    } else if (!isValidEmail(email)) {
      setErrorEmail("Email is not valid");
      newErrors.email = true;
    }
    const everyFieldFilled = Object.values(newErrors).every((value) => !value);
    if (everyFieldFilled) {
      dispatch(login({ data: { email, password }, navigate }));
      setIsInSecondStep(true);
    } else {
      setErrors(newErrors);
    }
  };
  const onClickOTP = () => {
    let newErrors = {
      email: !email,
      code: !code,
    };
    if (code.toString().trim() === "") {
      setErrorEmail("Code is requried");
      newErrors.code = true;
    } else if (email.toString().trim() === "") {
      setErrorEmail("Email is not valid");
      newErrors.email = true;
    }
    const everyFieldFilled = Object.values(newErrors).every((value) => !value);
    if (everyFieldFilled) {
      dispatch(checkOTP({ data: { email, otp: code }, navigate }));
      setIsInSecondStep(true);
    } else {
      setErrors(newErrors);
    }
  }

  const getUserInfo = async () => {
    try {
      const { data } = await axios.get(`${BASE_URL}/api/user/user`, {
        withCredentials: true,
      });
      const currentUser = data.user;
  
      dispatch(setUser({ name: "currentUser", value: currentUser }));
      dispatch(setUserProfile(currentUser));
      setIsLoading(false);
      navigate('/profile');
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    if (localStorage.getItem('user')) {
      getUserInfo();
    }
  }, [])

  return (
    <Fragment>
      <div className="login-container">
        <div className="login-content">
          <div className="login-card">
            <div className="login-header">
              <div className="login-header-title">Login</div>
              <div className="login-header-desc">
                Enter Email and Password to Login
              </div>
            </div>
            <div className="login-card-body">
              {!isInSecondStep ? (
                <>
                  <div className="login-form-item">
                    <TextField
                      label="Email"
                      variant="standard"
                      className="input"
                      name={"email"}
                      onChange={onChange}
                      value={email}
                      type="email"
                      error={errors.email}
                      helperText={errors.email ? errorEmail : ""}
                    />
                  </div>
                  <div className="login-form-item">
                    <TextField
                      label="Password"
                      variant="standard"
                      className="input"
                      name={"password"}
                      onChange={onChange}
                      value={password}
                      type={showPassword ? "text" : "password"}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={() => setshowPassword(!showPassword)}
                              edge="end"
                            >
                              {showPassword ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      error={errors.password}
                      helperText={errors.password ? "Password is required" : ""}
                    />
                  </div>
                  <div className="login-btn-container">
                    <Button
                      variant="contained"
                      className="w-100 login-btn"
                      onClick={onClick}
                    >
                      Sign In
                    </Button>
                  </div>
                </>
              ) : (
                <>
                  <div className="login-form-item">
                    <TextField
                      label="2FA Code"
                      variant="standard"
                      className="input"
                      name={"code"}
                      onChange={(e) => setCode(e.target.value)}
                      value={code}
                      type="text"
                      error={errors.code}
                      helperText={errors.code ? errorCode : ""}
                    />
                  </div>
                  <div className="login-btn-container">
                    <Button
                      variant="contained"
                      className="w-100 login-btn"
                      onClick={onClickOTP}
                    >
                      Verify
                    </Button>
                  </div>
                </>
              )}
            </div>
            <Loader />
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Login;
