import { combineReducers } from "redux";
import User from "./User";
import Btc from "./Btc";
import Eth from "./Eth";
import Usdt from "./Usdt";

export default combineReducers({
  User: User,
  Btc: Btc,
  Eth: Eth,
  Usdt: Usdt,
});
