import React from "react";
import "./index.css";
import withDashboard from "../../HOC/withDashboard";
import BtcImg from "../../assets/bitcoin.png";
import EthImg from "../../assets/ethereum.png";
import UsdtImg from "../../assets/usdt.png";
import PriceCard from "../../components/PriceCard";
import CoinCard from "../../components/CoinCard";
import { Button, Card, TextField } from "@mui/material";
import Chart from "../../components/Chart";
import QRCode from "react-qr-code";
import CardLayout from "../../components/CardLayout";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import PriceCardList from "../../components/PriceCardList";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setBtc } from "../../redux/actions/btcAction";
import { setETH } from "../../redux/actions/ethAction";
import { setUSDT } from "../../redux/actions/usdtAction";
import Swal from "sweetalert2";
import ViewTransactions from "../../components/ViewTransactions";
import { setUser } from "../../redux/actions/userAction";
import SettingsIcon from "@mui/icons-material/Settings";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
const Settings = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  return (
    <div className="settings-container">
      <Card className="settings-card">
        <div className="settings-title">
          <span>
            <SettingsIcon />
          </span>
          Settings
        </div>
        <div className="setting-item">
          <div className="settings-label">Change Password</div>
          <div
            className="settings-value"
            onClick={() => navigate("/updatePassword")}
          >
            <span>
              <ModeEditIcon />
            </span>{" "}
            Edit{" "}
          </div>
        </div>
        <div className="setting-item">
          <div className="settings-label">Change Pin</div>
          <div
            className="settings-value"
            onClick={() => navigate("/updatePin")}
          >
            <span>
              <ModeEditIcon />
            </span>{" "}
            Edit{" "}
          </div>
        </div>
      </Card>
    </div>
  );
};

export default withDashboard(Settings);
