import { Fragment } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, InputAdornment, TextField, Card } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Loader from "../Loader";
import Pagination from "../../components/Pagination";
import SearchIcon from "@mui/icons-material/Search";

import PreviewIcon from "@mui/icons-material/Preview";
import {
  getSwaps,
  setSearchSwap,
  setUser,
} from "../../redux/actions/userAction";
import noTransactionImg from "../../assets/transaction_eth.png";

const ViewSwaps = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const User = useSelector(({ User }) => User);
  const {
    filteredSwaps,
    paginatedSwaps,
    currentSwapPage,
    totalSwapPages,
    swapPerPage,
    swapSearch,
  } = User;

  useEffect(() => {
    dispatch(getSwaps());
    // eslint-disable-next-line
  }, []);

  const setTotalPages = (totalpages) => {
    dispatch(
      setUser({
        name: "totalSwapPages",
        value: totalpages,
      })
    );
  };
  const setCurrentPage = (currentPage) => {
    dispatch(
      setUser({
        name: "currentSwapPage",
        value: currentPage,
      })
    );
  };
  const setPaginatedItems = (paginatedItems) => {
    console.log(paginatedItems);
    dispatch(
      setUser({
        name: "paginatedSwaps",
        value: paginatedItems,
      })
    );
  };
  const handleChangeRowsPerPage = (event) => {
    const value = event.target.value;
    dispatch(
      setUser({
        name: "swapPerPage",
        value,
      })
    );
  };

  const onSearch = (e) => {
    const value = e.target.value;
    dispatch(setSearchSwap(value));
  };

  const onViewSwap = (swap) => {
    dispatch(
      setUser({
        name: "selectedSwap",
        value: swap,
      })
    );
    navigate("/viewSwapDetail");
  };

  return (
    <Fragment>
      <div className="table-layout-container">
        <Card className="table-card">
          <div className="table-title">
            <span>List of Swaps</span>
            <div>
              <TextField
                fullWidth
                variant="standard"
                className="search-input"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon style={{ color: "white" }} />
                    </InputAdornment>
                  ),
                }}
                onChange={onSearch}
                value={swapSearch}
              />
            </div>
          </div>
          <div className="table-container">
            <table>
              <thead>
                <th>SwapId</th>
                <th>Amount Sent</th>
                <th>Amount Receive</th>
                <th>Status</th>
                <th>Date</th>
                <th>Action</th>
              </thead>
              <tbody>
                {paginatedSwaps.length >= 1 &&
                  paginatedSwaps.map((swap) => {
                    const date = new Date(swap.date);
                    const day = date.getDate();
                    const month = date.getMonth() + 1;
                    const year = date.getFullYear();
                    const formattedDate = `${day}-${month}-${year}`;

                    return (
                      <Fragment>
                        <tr>
                          <td>{swap.swapId}</td>
                          <td>{`${swap.amountSent} ${swap.coinSent}`}</td>
                          <td>{`${swap.amountToRecieve} ${swap.coinRecieve}`}</td>
                          <td>{swap.status}</td>
                          <td>{formattedDate}</td>
                          <td>
                            <div className="action-row">
                              <Button
                                className="action-btn edit"
                                variant="contained"
                                onClick={() => onViewSwap(swap)}
                              >
                                {" "}
                                <PreviewIcon />
                                <span> View</span>
                              </Button>
                            </div>
                          </td>
                        </tr>
                      </Fragment>
                    );
                  })}
              </tbody>
            </table>
            {paginatedSwaps.length < 1 && (
              <Fragment>
                <div className="no-transaction-container">
                  <img
                    src={noTransactionImg}
                    className="no-transaction-img"
                    alt="no-transaction-img.png"
                  />
                  <div className="no-transaction-text">No Swaps Found</div>
                </div>
              </Fragment>
            )}
          </div>
          <Pagination
            currentPage={currentSwapPage}
            setCurrentPage={setCurrentPage}
            items={filteredSwaps}
            totalPages={totalSwapPages}
            setTotalPages={setTotalPages}
            itemsPerPage={swapPerPage}
            setPaginatedItems={setPaginatedItems}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
          />
          <Loader />
        </Card>
      </div>
    </Fragment>
  );
};

export default ViewSwaps;
