import React from "react";
import PriceCard from "../PriceCard";
import BalanceImg from "../../assets/balance.png";
import BtcImg from "../../assets/bitcoin.png";
import EthImg from "../../assets/ethereum.png";
import UsdtImg from "../../assets/usdt.png";
import { useSelector } from "react-redux";

const PriceCardList = () => {
  // Crypto Reducers
  const Btc = useSelector(({ Btc }) => Btc);
  const Eth = useSelector(({ Eth }) => Eth);
  const Usdt = useSelector(({ Usdt }) => Usdt);
  // Calculate Overall Currency
  const usdtBalance = Usdt.balance;
  const usdtPriceNow = Usdt.priceNow;
  const totalUsdtBalance = usdtBalance * usdtPriceNow;
  const btcBalance = Btc.balance;
  const btcPriceNow = Btc.priceNow;
  const totalBtcBalance = btcBalance * btcPriceNow;
  const ethBalance = Eth.balance;
  const ethPriceNow = Eth.priceNow;
  const totalEthBalance = ethBalance * ethPriceNow;
  const totalBalance = totalUsdtBalance + totalBtcBalance + totalEthBalance;
  let formattedBalanceCurrency = parseFloat(totalBalance).toFixed(2);
  if (Number.isInteger(parseFloat(formattedBalanceCurrency))) {
    formattedBalanceCurrency = parseFloat(formattedBalanceCurrency).toString();
  }

  return (
    <div className="wallet-price-containers">
      <PriceCard
        cardImg={BalanceImg}
        price={formattedBalanceCurrency}
        title={"Assets Worth"}
        isCurrency={true}
      />
      <PriceCard
        cardImg={BtcImg}
        price={Btc.priceNow}
        title={"Bitcoin"}
        currencyDiff={Btc.currencyDiff}
      />
      <PriceCard
        cardImg={EthImg}
        price={Eth.priceNow}
        title={"Ethereum"}
        currencyDiff={Eth.currencyDiff}
      />
      <PriceCard
        cardImg={UsdtImg}
        price={Usdt.priceNow}
        title={"Tether"}
        currencyDiff={Usdt.currencyDiff}
      />
    </div>
  );
};

export default PriceCardList;
