import React from "react";
import "./index.css";
import Chart from "../Chart";
import { ButtonBase, Card } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { setUser } from "../../redux/actions/userAction";
import { useNavigate } from "react-router-dom";

const CoinCard = ({ coin }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const Btc = useSelector(({ Btc }) => Btc);
  const Eth = useSelector(({ Eth }) => Eth);
  const Usdt = useSelector(({ Usdt }) => Usdt);
  const coinMappings = {
    BTC: {
      coinName: "Bitcoin",
      circleColor: "yellow-circle",
      baseColor: "yellow",
      borderColor: "rgba(244, 183, 63, 1)",
      backgroundColor: "rgba(244, 183, 63, 0.2)",
      selectedReducer: Btc,
    },
    ETH: {
      coinName: "Ethereum",
      circleColor: "blue-circle",
      baseColor: "blue",
      borderColor: "rgba(0, 107, 250, 1)",
      backgroundColor: "rgba(0, 107, 250, 0.2)",
      selectedReducer: Eth,
    },
    USDT: {
      coinName: "Tether",
      circleColor: "green-circle",
      baseColor: "green",
      borderColor: "rgba(75, 192, 192, 1)",
      backgroundColor: "rgba(75, 192, 192, 0.2)",
      selectedReducer: Usdt,
    },
  };

  const selectedCoinData = coinMappings[coin];

  const {
    coinName,
    circleColor,
    baseColor,
    borderColor,
    backgroundColor,
    selectedReducer,
  } = selectedCoinData;

  const { prices, labels, balance, percentageDiff, currencyDiff } =
    selectedReducer;
  const isPoistive = parseFloat(currencyDiff) >= 0;

  let formattedBalance = parseFloat(balance).toFixed(7);
  if (Number.isInteger(parseFloat(formattedBalance))) {
    formattedBalance = parseFloat(formattedBalance).toString();
  }

  const onViewClick = () => {
    dispatch(
      setUser({
        name: "selectedCoin",
        value: coin,
      })
    );
    navigate("/coinWallet");
  };
  return (
    <div className="coincard-container">
      <Card className="coin-card">
        <div className="coincard-details">
          <div className="coincard-balance">Balance:</div>
          <div className="coincard-title-container">
            <div className="coincard-balance">
              {formattedBalance} {coin}
            </div>
            <div className="coincard-difference-container">
              <div
                className={`coincard-diff-percet ${
                  isPoistive ? "positive" : "negative"
                }`}
              >
                {percentageDiff}
              </div>
            </div>
          </div>
          <Chart
            borderColor={borderColor}
            backgroundColor={backgroundColor}
            prices={prices}
            labels={labels}
          />
        </div>
        <div className="coincard-actions">
          <ButtonBase className="coincard-btn" onClick={onViewClick}>
            View {coinName} Wallet
          </ButtonBase>
        </div>
      </Card>
    </div>
  );
};

export default CoinCard;
