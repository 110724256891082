import React, { Fragment } from "react";
import "../CoinWallet/index.css";
import "../ReviewOrder/index.css";
import "./index.css";
import withDashboard from "../../HOC/withDashboard";
import BtcImg from "../../assets/bitcoin.png";
import EthImg from "../../assets/ethereum.png";
import UsdtImg from "../../assets/usdt.png";
import { Button, Switch, TextField } from "@mui/material";
import CardLayout from "../../components/CardLayout";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { setBtc } from "../../redux/actions/btcAction";
import { setETH } from "../../redux/actions/ethAction";
import { setUSDT } from "../../redux/actions/usdtAction";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Avatar from "../../assets/avatars/avatar4.png";
import AccountDetails from "../../components/AccountDetails";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { capitalize } from "../../utils";
import { getUser, setUser, updateOrder } from "../../redux/actions/userAction";
import Swal from "sweetalert2";

const ViewReferral = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const User = useSelector(({ User }) => User);
  const { selectedReferral, testConfig, prodConfig, enviroment } = User;
  const {
    refferal,
    referre,
    transactionHx,
    sentTo,
    amountDeposited,
    amountPaid,
    coin,
    date,
  } = selectedReferral;
  const config = enviroment === "test" ? testConfig : prodConfig;
  const { BTC_EXPLORER, ETH_EXPLORER, USDT_EXPLORER } = config;
  const coinMappings = {
    BTC: {
      explorer: BTC_EXPLORER,
      Image: BtcImg,
    },
    ETH: {
      explorer: ETH_EXPLORER,
      Image: EthImg,
    },
    USDT: {
      explorer: USDT_EXPLORER,
      Image: UsdtImg,
    },
  };
  const selectedCoinData = coinMappings[coin];
  const { explorer, Image } = selectedCoinData;
  const referralDate = new Date(date);
  const formattedDate = `${referralDate.toLocaleDateString("en-US", {
    year: "numeric",
    month: "short",
    day: "numeric",
  })} at ${referralDate.toLocaleTimeString("en-US", {
    hour: "numeric",
    minute: "2-digit",
  })}`;

  const getUserDetail = (_id) => {
    dispatch(
      getUser({
        data: {
          userId: _id,
        },
        navigate,
      })
    );
  };

  const CopyToClipboard = (data) => {
    navigator.clipboard.writeText(data);
    Swal.fire({
      position: "top-end",
      icon: "success",
      title: "Copied to Clipboard",
      showConfirmButton: false,
      timer: 1500,
    });
  };

  const ViewOnBlockchain = (transactionHx) => {
    const url = `${explorer}${transactionHx}`;
    window.open(url, "_blank");
  };

  return (
    <div className="wallets-container">
      <CardLayout>
        <div className="coinwallet-back-btn">
          <Button variant="outlined" onClick={() => navigate(-1)}>
            <ArrowBackIcon /> Back
          </Button>
        </div>
        <div className="review-order-container">
          <div className="review-order-user-container">
            <div className="referral-user-item">
              <div className="buy-order-review-title">Referral Details</div>
              <div className="review-order-user-img-container">
                <img
                  src={
                    refferal?.image
                      ? require(`../../assets/avatars/${refferal?.image}`)
                      : Avatar
                  }
                  className="review-order-user-img"
                  alt="card.png"
                />
              </div>
              <div className="review-transaction-item">
                <div className="review-transaction-title">Name:</div>
                <div className="review-transaction-value">{refferal?.name}</div>
              </div>
              <div className="review-transaction-item">
                <div className="review-transaction-title">UserName:</div>
                <div className="review-transaction-value">
                  {refferal?.userName}
                </div>
              </div>
              <div className="review-transaction-item">
                <div className="review-transaction-title">Email:</div>
                <div className="review-transaction-value">
                  {refferal?.email}
                </div>
              </div>
              <div className="review-transaction-item">
                <div className="review-transaction-title">Phone Number:</div>
                <div className="review-transaction-value">
                  {refferal?.phoneNumber}
                </div>
              </div>
              <div className="review-transaction-item border-bottom-none">
                <div className="review-transaction-title">Country:</div>
                <div className="review-transaction-value">
                  {refferal?.country}
                </div>
              </div>
              <div className="review-order-btn-container">
                {" "}
                <Button
                  variant="contained"
                  className="review-order-btn"
                  onClick={() => getUserDetail(refferal._id)}
                >
                  View Referral Details
                </Button>
              </div>
            </div>
            <div className="referral-user-item">
              <div className="buy-order-review-title">Referre Details</div>
              <div className="review-order-user-img-container">
                <img
                  src={
                    referre?.image
                      ? require(`../../assets/avatars/${referre?.image}`)
                      : Avatar
                  }
                  className="review-order-user-img"
                  alt="card.png"
                />
              </div>
              <div className="review-transaction-item">
                <div className="review-transaction-title">Name:</div>
                <div className="review-transaction-value">{referre?.name}</div>
              </div>
              <div className="review-transaction-item">
                <div className="review-transaction-title">UserName:</div>
                <div className="review-transaction-value">
                  {referre?.userName}
                </div>
              </div>
              <div className="review-transaction-item">
                <div className="review-transaction-title">Email:</div>
                <div className="review-transaction-value">{referre?.email}</div>
              </div>
              <div className="review-transaction-item">
                <div className="review-transaction-title">Phone Number:</div>
                <div className="review-transaction-value">
                  {referre?.phoneNumber}
                </div>
              </div>
              <div className="review-transaction-item border-bottom-none">
                <div className="review-transaction-title">Country:</div>
                <div className="review-transaction-value">
                  {referre?.country}
                </div>
              </div>
              <div className="review-order-btn-container">
                {" "}
                <Button
                  variant="contained"
                  className="review-order-btn"
                  onClick={() => getUserDetail(referre._id)}
                >
                  View Referre Details
                </Button>
              </div>
            </div>
          </div>
          <div className="review-order-info-container">
            <div className="buy-order-review-container">
              <div className="buy-order-review-title">Transaction Details</div>
              <div className="buy-order-review-item">
                <div className="buy-order-review-item-title">
                  Referre Amount Deposited
                </div>
                <div className="buy-order-review-value">
                  <div className="buy-order-review-value-text">
                    <Fragment>
                      <img
                        src={Image}
                        className="crypto-sent-img"
                        alt="card.png"
                      />
                      {amountDeposited} {coin}
                    </Fragment>
                  </div>
                  <span
                    className="copy-icon-container"
                    onClick={() => CopyToClipboard("")}
                  >
                    <ContentCopyIcon />
                  </span>
                </div>
              </div>
              <div className="buy-order-review-item">
                <div className="buy-order-review-item-title">Amount Paid</div>
                <div className="buy-order-review-value">
                  <div className="buy-order-review-value-text">
                    <Fragment>
                      <img
                        src={Image}
                        className="crypto-sent-img"
                        alt="card.png"
                      />
                      {amountPaid} {coin}
                    </Fragment>
                  </div>
                  <span
                    className="copy-icon-container"
                    onClick={() => CopyToClipboard("")}
                  >
                    <ContentCopyIcon />
                  </span>
                </div>
              </div>
              <div className="buy-order-review-item ">
                <div className="buy-order-review-item-title">Sent To</div>
                <div className="buy-order-review-value">
                  <div className="buy-order-review-value-text">{sentTo}</div>
                  <span
                    className="copy-icon-container"
                    onClick={() => CopyToClipboard("")}
                  >
                    <ContentCopyIcon />
                  </span>
                </div>
              </div>
              <div className="buy-order-review-item ">
                <div className="buy-order-review-item-title">
                  Transaction Hash
                </div>
                <div className="buy-order-review-value">
                  <div className="buy-order-review-value-text">
                    {transactionHx}
                  </div>
                  <span
                    className="copy-icon-container"
                    onClick={() => CopyToClipboard("")}
                  >
                    <ContentCopyIcon />
                  </span>
                </div>
              </div>
              <div className="buy-order-review-item border-bottom-none">
                <div className="buy-order-review-item-title">Date</div>
                <div className="buy-order-review-value">
                  <div className="buy-order-review-value-text">
                    {formattedDate}
                  </div>
                  <span
                    className="copy-icon-container"
                    onClick={() => CopyToClipboard("")}
                  >
                    <ContentCopyIcon />
                  </span>
                </div>
              </div>
              <div className="review-order-btn-container">
                {" "}
                <Button
                  variant="contained"
                  className="review-order-btn"
                  onClick={() => ViewOnBlockchain(transactionHx)}
                >
                  View on Blockchain
                </Button>
              </div>
            </div>
          </div>
        </div>
      </CardLayout>
    </div>
  );
};

export default withDashboard(ViewReferral);
