import React, { useState } from "react";
import withDashboard from "../../HOC/withDashboard";
import BackgroundImage from "../../assets/profilebackground.jpeg";
import "./index.css";
import {
  Autocomplete,
  Button,
  IconButton,
  Switch,
  TextField,
} from "@mui/material";
import countries from "../../constants/countries";
import currencies from "../../constants/currencies";
import languages from "../../constants/languages";
import { useDispatch, useSelector } from "react-redux";
import { setProfileForm, updateProfile } from "../../redux/actions/userAction";
import Avatar from "../../assets/avatars/avatar4.png";
import Avatar1 from "../../assets/avatars/avatar1.png";
import Avatar2 from "../../assets/avatars/avatar2.png";
import Avatar3 from "../../assets/avatars/avatar3.png";
import Avatar4 from "../../assets/avatars/avatar4.png";
import Avatar5 from "../../assets/avatars/avatar5.png";
import Avatar6 from "../../assets/avatars/avatar6.png";
import Avatar7 from "../../assets/avatars/avatar7.png";
import Avatar8 from "../../assets/avatars/avatar8.png";
import CreateIcon from "@mui/icons-material/Create";
import Loader from "../../components/Loader";
import { isValidEmail } from "../../utils";
const Profile = () => {
  const dispatch = useDispatch();
  const User = useSelector(({ User }) => User);
  const [toggleImages, setToggleImages] = useState(false);
  const { profile } = User;
  const [errors, setErrors] = useState({
    name: false,
    email: false,
    phoneNumber: false,
    currency: false,
    country: false,
    language: false,
    image: false,
    userName: false,
  });
  const [errorEmail, setErrorEmail] = useState("");

  const {
    name,
    email,
    phoneNumber,
    currency,
    country,
    language,
    image,
    currencySymbol,
    userName,
    editEmail,
  } = profile;
  const onChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    dispatch(
      setProfileForm({
        name,
        value,
      })
    );
    setErrors({ ...errors, [name]: false });
  };
  const onClick = () => {
    const selectedCurrencySymbol = currency?.symbol;
    const selectedCurrency = currency?.value;
    const selectedCountry = country?.value;
    const selectedLang = language?.value;
    let newErrors = {
      name: !name,
      phoneNumber: !phoneNumber,
      currency: !selectedCurrency,
      country: !selectedCountry,
      language: !selectedLang,
      userName: !userName,
    };
    let data = {
      name,
      phoneNumber,
      currency: selectedCurrency,
      country: selectedCountry,
      language: selectedLang,
      image,
      currencySymbol: selectedCurrencySymbol,
      userName,
    };
    if (editEmail) {
      // Email
      if (email.toString().trim() === "") {
        setErrorEmail("Email is requried");
        newErrors.email = true;
      } else if (!isValidEmail(email)) {
        setErrorEmail("Email is not valid");
        newErrors.email = true;
      }
      data.email = email;
    }
    const everyFieldFilled = Object.values(newErrors).every((value) => !value);
    console.log(newErrors);
    if (everyFieldFilled) {
      dispatch(
        updateProfile({
          data,
        })
      );
    } else {
      setErrors(newErrors);
    }
  };
  return (
    <div className="profile-container">
      <div className="profile-backgroundImage-container">
        <img
          src={BackgroundImage}
          className="profile-backgroundImage"
          alt="background.png"
        />
      </div>
      <div className="profile-card-container">
        <div className="profile-card">
          <div className="profile-info-container">
            <div className="profile-user-container">
              <img
                src={image ? require(`../../assets/avatars/${image}`) : Avatar}
                className="profile-user-img"
                alt="profile.png"
              />
              <div
                className="profile-user-edit"
                onClick={() => setToggleImages(!toggleImages)}
              >
                <IconButton>
                  <CreateIcon color="white" />
                </IconButton>
              </div>
            </div>
            <div className="profile-info-text-container">
              <div className="profile-info-role">Admin</div>
              <div className="profile-info-desc">CEO / Co-Founder</div>
            </div>
          </div>
          {toggleImages && (
            <div className="profile-images-container">
              <div
                className={`profile-image-item ${
                  image === "avatar1.png" && "selected"
                }`}
                onClick={() => {
                  onChange({
                    target: {
                      name: "image",
                      value: "avatar1.png",
                    },
                  });
                }}
              >
                <img
                  src={Avatar1}
                  className="profile-image"
                  alt="avatar1.png"
                />
              </div>
              <div
                className={`profile-image-item ${
                  image === "avatar2.png" && "selected"
                }`}
                onClick={() => {
                  onChange({
                    target: {
                      name: "image",
                      value: "avatar2.png",
                    },
                  });
                }}
              >
                <img
                  src={Avatar2}
                  className="profile-image"
                  alt="avatar2.png"
                />
              </div>
              <div
                className={`profile-image-item ${
                  image === "avatar3.png" && "selected"
                }`}
                onClick={() => {
                  onChange({
                    target: {
                      name: "image",
                      value: "avatar3.png",
                    },
                  });
                }}
              >
                <img
                  src={Avatar3}
                  className="profile-image"
                  alt="avatar3.png"
                />
              </div>
              <div
                className={`profile-image-item ${
                  image === "avatar4.png" && "selected"
                }`}
                onClick={() => {
                  onChange({
                    target: {
                      name: "image",
                      value: "avatar4.png",
                    },
                  });
                }}
              >
                <img
                  src={Avatar4}
                  className="profile-image"
                  alt="avatar4.png"
                />
              </div>
              <div
                className={`profile-image-item ${
                  image === "avatar5.png" && "selected"
                }`}
                onClick={() => {
                  onChange({
                    target: {
                      name: "image",
                      value: "avatar5.png",
                    },
                  });
                }}
              >
                <img
                  src={Avatar5}
                  className="profile-image"
                  alt="avatar5.png"
                />
              </div>
              <div
                className={`profile-image-item ${
                  image === "avatar6.png" && "selected"
                }`}
                onClick={() => {
                  onChange({
                    target: {
                      name: "image",
                      value: "avatar6.png",
                    },
                  });
                }}
              >
                <img
                  src={Avatar6}
                  className="profile-image"
                  alt="avatar6.png"
                />
              </div>
              <div
                className={`profile-image-item ${
                  image === "avatar7.png" && "selected"
                }`}
                onClick={() => {
                  onChange({
                    target: {
                      name: "image",
                      value: "avatar7.png",
                    },
                  });
                }}
              >
                <img
                  src={Avatar7}
                  className="profile-image"
                  alt="avatar7.png"
                />
              </div>
              <div
                className={`profile-image-item ${
                  image === "avatar8.png" && "selected"
                }`}
                onClick={() => {
                  onChange({
                    target: {
                      name: "image",
                      value: "avatar8.png",
                    },
                  });
                }}
              >
                <img
                  src={Avatar8}
                  className="profile-image"
                  alt="avatar8.png"
                />
              </div>
            </div>
          )}

          <div className="profile-inputs-container">
            <div className="profile-input-row">
              <div className="profile-input-item">
                <div className="profile-input-container">
                  <TextField
                    label="Name"
                    variant="outlined"
                    className="w-100"
                    name="name"
                    onChange={onChange}
                    value={name}
                    error={errors.name}
                    helperText={errors.name ? "Full Name is required" : ""}
                  />
                </div>
              </div>
              <div className="profile-input-item">
                <div className="profile-input-container">
                  <TextField
                    label="Email"
                    variant="outlined"
                    className="w-100"
                    name="email"
                    onChange={onChange}
                    value={email}
                    disabled={!editEmail}
                    error={errors.email}
                    helperText={errors.email ? errorEmail : ""}
                  />
                </div>
              </div>
            </div>
            <div className="reason-switch-container-row">
              <div className="reason-switch-container">
                <span className="reason-switch-container-label">
                  Edit Email
                </span>
                <span className="reason-switch-value">No</span>
                <Switch
                  inputProps={{ "aria-label": "controlled" }}
                  checked={editEmail}
                  onChange={() =>
                    onChange({
                      target: {
                        name: "editEmail",
                        value: !editEmail,
                      },
                    })
                  }
                />
                <span className="reason-switch-value">Yes</span>
              </div>
            </div>
            <div className="profile-input-row">
              <div className="profile-input-item">
                <div className="profile-input-container">
                  <TextField
                    label="UserName"
                    variant="outlined"
                    className="w-100"
                    name="userName"
                    onChange={onChange}
                    value={userName}
                    error={errors.userName}
                    helperText={errors.userName ? "User Name is required" : ""}
                  />
                </div>
              </div>
              <div className="profile-input-item">
                <div className="profile-input-container">
                  <TextField
                    label="Currency Symbol"
                    variant="outlined"
                    className="w-100"
                    name="currencySymbol"
                    onChange={onChange}
                    value={currencySymbol}
                    disabled={true}
                  />
                </div>
              </div>
            </div>
            <div className="profile-input-row">
              <div className="profile-input-item">
                <div className="profile-input-container">
                  <TextField
                    label="Phone Number"
                    variant="outlined"
                    className="w-100"
                    name="phoneNumber"
                    onChange={onChange}
                    value={phoneNumber}
                    error={errors.phoneNumber}
                    helperText={
                      errors.phoneNumber ? "Phone Number is required" : ""
                    }
                  />
                </div>
              </div>
              <div className="profile-input-item">
                <div className="profile-input-container">
                  <Autocomplete
                    options={languages}
                    getOptionLabel={(option) => option?.label}
                    name={"language"}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Language"
                        error={errors.language}
                        helperText={
                          errors.language ? "Language is required" : ""
                        }
                      />
                    )}
                    onChange={(event, newValue) => {
                      onChange({
                        target: {
                          name: "language",
                          value: newValue,
                        },
                      });
                    }}
                    value={language}
                  />
                </div>
              </div>
            </div>
            <div className="profile-input-row">
              <div className="profile-input-item">
                <div className="profile-input-container">
                  <Autocomplete
                    options={countries}
                    getOptionLabel={(option) => option?.label}
                    name={"country"}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Country"
                        error={errors.country}
                        helperText={errors.country ? "Country is required" : ""}
                      />
                    )}
                    onChange={(event, newValue) => {
                      onChange({
                        target: {
                          name: "country",
                          value: newValue,
                        },
                      });
                    }}
                    value={country}
                  />
                </div>
              </div>
              <div className="profile-input-item">
                <div className="profile-input-container">
                  <Autocomplete
                    options={currencies}
                    getOptionLabel={(option) => option?.label}
                    name={"currency"}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Currency"
                        error={errors.currency}
                        helperText={
                          errors.currency ? "Currency is required" : ""
                        }
                      />
                    )}
                    onChange={(event, newValue) => {
                      onChange({
                        target: {
                          name: "currency",
                          value: newValue,
                        },
                      });
                    }}
                    value={currency}
                  />
                </div>
              </div>
            </div>
            <div className="profile-input-btn-container">
              <Button
                variant="contained"
                className="profile-btn"
                onClick={onClick}
              >
                Update Profile
              </Button>
            </div>
          </div>
          <Loader />
        </div>
      </div>
    </div>
  );
};

export default withDashboard(Profile);
