import React, { Fragment } from "react";
import "../CoinWallet/index.css";
import "./index.css";
import withDashboard from "../../HOC/withDashboard";
import BtcImg from "../../assets/bitcoin.png";
import EthImg from "../../assets/ethereum.png";
import UsdtImg from "../../assets/usdt.png";
import { Button, Switch, TextField } from "@mui/material";
import CardLayout from "../../components/CardLayout";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { setBtc } from "../../redux/actions/btcAction";
import { setETH } from "../../redux/actions/ethAction";
import { setUSDT } from "../../redux/actions/usdtAction";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Avatar from "../../assets/avatars/avatar4.png";
import AccountDetails from "../../components/AccountDetails";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { capitalize } from "../../utils";
import { getUser, setUser, updateOrder } from "../../redux/actions/userAction";
import Swal from "sweetalert2";

const ReviewOrder = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const Btc = useSelector(({ Btc }) => Btc);
  const Eth = useSelector(({ Eth }) => Eth);
  const Usdt = useSelector(({ Usdt }) => Usdt);
  const User = useSelector(({ User }) => User);
  const { selectedOrder, messageText, shouldAddMessage } = User;
  const { type, amountSent, amountToRecieve, coin, currency, status } =
    selectedOrder || {};
  const { user } = selectedOrder || {};
  const { name, email, phoneNumber, userName, country, image } = user;
  const isBuy = type === "buy";
  const isUSD = currency === "USD";
  const Image = coin === "BTC" ? BtcImg : coin === "ETH" ? EthImg : UsdtImg;
  const coinMappings = {
    BTC: {
      selectedReducer: Btc,
    },
    ETH: {
      selectedReducer: Eth,
    },
    USDT: {
      selectedReducer: Usdt,
    },
  };
  const selectedCoinData = coinMappings[coin];
  const { selectedReducer } = selectedCoinData;
  const recipientAddress = selectedReducer.recipientAddress;

  const setTransaction = (data) => {
    if (coin === "BTC") {
      dispatch(setBtc(data));
    } else if (coin === "ETH") {
      dispatch(setETH(data));
    } else if (coin === "USDT") {
      dispatch(setUSDT(data));
    }
  };
  const onComplete = () => {
    if (isBuy) {
      dispatch(
        setUser({
          name: "selectedCoin",
          value: coin,
        })
      );

      dispatch(
        setUser({
          name: "isBuyTransaction",
          value: true,
        })
      );

      setTransaction({
        name: "sendAmount",
        value: amountToRecieve,
      });

      navigate("/reviewTransaction");
    } else {
      const orderId = selectedOrder._id;
      let data = {
        status: "completed",
        orderId,
      };
      if (shouldAddMessage) {
        data.message = messageText;
      }
      dispatch(
        updateOrder({
          data,
          navigate,
        })
      );
      dispatch(
        updateOrder({
          data,
          navigate,
        })
      );
    }
  };

  const onMessageChange = (e) => {
    const value = e.target.value;
    dispatch(
      setUser({
        name: "messageText",
        value,
      })
    );
  };

  const onReject = () => {
    const orderId = selectedOrder._id;
    let data = {
      status: "rejected",
      orderId,
    };
    if (shouldAddMessage) {
      data.message = messageText;
    }
    dispatch(
      updateOrder({
        data,
        navigate,
      })
    );
  };

  const getUserDetail = () => {
    const userId = user._id;
    dispatch(
      getUser({
        data: {
          userId,
        },
        navigate,
      })
    );
  };

  const CopyToClipboard = (data) => {
    navigator.clipboard.writeText(data);
    Swal.fire({
      position: "top-end",
      icon: "success",
      title: "Copied to Clipboard",
      showConfirmButton: false,
      timer: 1500,
    });
  };

  return (
    <div className="wallets-container">
      <CardLayout>
        <div className="coinwallet-back-btn">
          <Button variant="outlined" onClick={() => navigate(-1)}>
            <ArrowBackIcon /> Back
          </Button>
        </div>
        <div className="review-order-container">
          <div className="review-order-user-container">
            <div className="review-order-user-img-container">
              <img
                src={image ? require(`../../assets/avatars/${image}`) : Avatar}
                className="review-order-user-img"
                alt="card.png"
              />
            </div>
            <div className="review-transaction-item">
              <div className="review-transaction-title">Name:</div>
              <div className="review-transaction-value">{name}</div>
            </div>
            <div className="review-transaction-item">
              <div className="review-transaction-title">UserName:</div>
              <div className="review-transaction-value">{userName}</div>
            </div>
            <div className="review-transaction-item">
              <div className="review-transaction-title">Email:</div>
              <div className="review-transaction-value">{email}</div>
            </div>
            <div className="review-transaction-item">
              <div className="review-transaction-title">Phone Number:</div>
              <div className="review-transaction-value">{phoneNumber}</div>
            </div>
            <div className="review-transaction-item border-bottom-none">
              <div className="review-transaction-title">Country:</div>
              <div className="review-transaction-value">{country}</div>
            </div>
            {!isBuy && <AccountDetails />}
            <div className="review-order-btn-container">
              {" "}
              <Button
                variant="contained"
                className="review-order-btn"
                onClick={getUserDetail}
              >
                View User Detail
              </Button>
            </div>
          </div>
          <div className="review-order-info-container">
            <div className="buy-order-review-container">
              <div className="buy-order-review-title">Order Details</div>
              <div className="buy-order-review-item">
                <div className="buy-order-review-item-title">Order Status</div>
                <div className="buy-order-review-value">
                  {capitalize(status)}
                </div>
              </div>
              <div className="buy-order-review-item">
                <div className="buy-order-review-item-title">Order Type</div>
                <div className="buy-order-review-value">{capitalize(type)}</div>
              </div>
              <div className="buy-order-review-item">
                <div className="buy-order-review-item-title">
                  {isBuy ? "Currency Received" : "Crypto Received"}
                </div>
                <div className="buy-order-review-value">
                  <div className="buy-order-review-value-text">
                    {isBuy ? (
                      <Fragment>
                        <span className="crypto-sent-img">
                          {isUSD ? "🇺🇸" : "🇳🇬"}
                        </span>
                        {amountSent} {currency}
                      </Fragment>
                    ) : (
                      <Fragment>
                        <img
                          src={Image}
                          className="crypto-sent-img"
                          alt="card.png"
                        />
                        {amountSent} {coin}
                      </Fragment>
                    )}
                  </div>
                  <span
                    className="copy-icon-container"
                    onClick={() => CopyToClipboard(amountSent)}
                  >
                    <ContentCopyIcon />
                  </span>
                </div>
              </div>
              <div className="buy-order-review-item ">
                <div className="buy-order-review-item-title">
                  {isBuy ? "Recipient Address" : "Crypto Sent from"}
                </div>
                <div className="buy-order-review-value">
                  <div className="buy-order-review-value-text">
                    {recipientAddress}
                  </div>
                  <span
                    className="copy-icon-container"
                    onClick={() => CopyToClipboard(recipientAddress)}
                  >
                    <ContentCopyIcon />
                  </span>
                </div>
              </div>
              <div className="buy-order-review-item border-bottom-none">
                <div className="buy-order-review-item-title">
                  Amount To Send
                </div>
                <div className="buy-order-review-value">
                  <div className="buy-order-review-value-text">
                    {isBuy ? (
                      <Fragment>
                        <img
                          src={Image}
                          className="crypto-sent-img"
                          alt="card.png"
                        />
                        {amountToRecieve} {coin}
                      </Fragment>
                    ) : (
                      <Fragment>
                        <span className="crypto-sent-img">
                          {isUSD ? "🇺🇸" : "🇳🇬"}
                        </span>
                        {amountToRecieve} {currency}
                      </Fragment>
                    )}
                  </div>
                  <span
                    className="copy-icon-container"
                    onClick={() => CopyToClipboard(amountToRecieve)}
                  >
                    <ContentCopyIcon />
                  </span>
                </div>
              </div>
            </div>
            {status === "pending" && (
              <Fragment>
                <div className="reason-switch-container-row">
                  <div className="reason-switch-container">
                    <span className="reason-switch-container-label">
                      Add Message
                    </span>
                    <span className="reason-switch-value">No</span>
                    <Switch
                      inputProps={{ "aria-label": "controlled" }}
                      checked={shouldAddMessage}
                      onChange={() => {
                        dispatch(
                          setUser({
                            name: "shouldAddMessage",
                            value: !shouldAddMessage,
                          })
                        );
                      }}
                    />
                    <span className="reason-switch-value">Yes</span>
                  </div>
                </div>
                {shouldAddMessage && (
                  <div className="reason-textarea-container">
                    <TextField
                      label="Message(optional)"
                      multiline
                      rows={4}
                      className="reason-textarea"
                      value={messageText}
                      onChange={onMessageChange}
                    />
                  </div>
                )}

                <div className="review-order-btn-container">
                  <Button
                    variant="contained"
                    className="review-order-btn reject"
                    onClick={onReject}
                  >
                    Reject
                  </Button>
                  <Button
                    variant="contained"
                    className="review-order-btn completed"
                    onClick={onComplete}
                  >
                    Complete
                  </Button>
                </div>
              </Fragment>
            )}
          </div>
        </div>
      </CardLayout>
    </div>
  );
};

export default withDashboard(ReviewOrder);
