import { useState } from "react";
import "../CoinWallet/index.css";
import "./index.css";
import withDashboard from "../../HOC/withDashboard";
import BtcImg from "../../assets/bitcoin.png";
import EthImg from "../../assets/ethereum.png";
import UsdtImg from "../../assets/usdt.png";
import {
  Autocomplete,
  Button,
  IconButton,
  Switch,
  TextField,
} from "@mui/material";
import CardLayout from "../../components/CardLayout";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import BalanceCardList from "../../components/BalanceCardList";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import CreateIcon from "@mui/icons-material/Create";
import currencies from "../../constants/currencies";
import countries from "../../constants/countries";
import languages from "../../constants/languages";
import Avatar from "../../assets/avatars/avatar4.png";
import Avatar1 from "../../assets/avatars/avatar1.png";
import Avatar2 from "../../assets/avatars/avatar2.png";
import Avatar3 from "../../assets/avatars/avatar3.png";
import Avatar4 from "../../assets/avatars/avatar4.png";
import Avatar5 from "../../assets/avatars/avatar5.png";
import Avatar6 from "../../assets/avatars/avatar6.png";
import Avatar7 from "../../assets/avatars/avatar7.png";
import Avatar8 from "../../assets/avatars/avatar8.png";
import {
  setUserDetailForm,
  updateUserProfile,
} from "../../redux/actions/userAction";
import Swal from "sweetalert2";
import ViewUserTransactions from "../../components/ViewUserTransactions";
import ViewUserSwaps from "../../components/ViewUserSwaps";

const ViewUserDetail = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [toggleImages, setToggleImages] = useState(false);
  const User = useSelector(({ User }) => User);
  const { selectedUser, userprofile } = User;
  const { _id, bitcoin, ethereum } = selectedUser || {};
  const {
    userKeychainPrivateKey,
    userKeychainPublicKey,
    backupKeychainPublicKey,
    bitgoKeychainPublicKey,
    walletAddress,
  } = bitcoin || {};
  const { privateKey, address } = ethereum || {};
  const {
    name,
    email,
    phoneNumber,
    currency,
    country,
    language,
    image,
    currencySymbol,
    userName,
    referralCode,
    referrerId,
    editEmail,
  } = userprofile || {};

  const onChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    dispatch(
      setUserDetailForm({
        name,
        value,
      })
    );
    if (name === "currency") {
      const symbol = value.symbol;
      dispatch(
        setUserDetailForm({
          name: "currencySymbol",
          value: symbol,
        })
      );
    }
  };

  const onClick = () => {
    const selectedCurrencySymbol = currency.symbol;
    const selectedCurrency = currency.value;
    const selectedCountry = country.value;
    const selectedLang = language?.value;
    const userId = selectedUser._id;
    let data = {
      userId,
      name,
      phoneNumber,
      currency: selectedCurrency,
      country: selectedCountry,
      language: selectedLang,
      image,
      currencySymbol: selectedCurrencySymbol,
      userName,
      referralCode,
      referrerId,
    };
    if (editEmail) data.email = email;
    console.clear();
    console.log(data);
    dispatch(
      updateUserProfile({
        data,
      })
    );
  };

  const CopyToClipboard = (data) => {
    navigator.clipboard.writeText(data);
    Swal.fire({
      position: "top-end",
      icon: "success",
      title: "Copied to Clipboard",
      showConfirmButton: false,
      timer: 1500,
    });
  };

  const formatAddress = (address) => {
    return `${address?.slice(0, 4)}...${address?.slice(-4)}`;
  };

  return (
    <div className="wallets-container">
      <CardLayout>
        <div className="user-detail-layout-container">
          <div className="coinwallet-back-btn">
            <Button variant="outlined" onClick={() => navigate(-1)}>
              <ArrowBackIcon /> Back
            </Button>
          </div>
          <BalanceCardList userId={_id} />
          <div className="user-detail-inputs-container">
            <div className="user-detail-profile-img-container">
              <div className="user-detail-profile-container">
                <img
                  src={
                    image ? require(`../../assets/avatars/${image}`) : Avatar
                  }
                  className="user-detail-img"
                  alt="card.png"
                />
                <div className="user-detail-profile-edit">
                  <IconButton onClick={() => setToggleImages(!toggleImages)}>
                    <CreateIcon className="color-white" />
                  </IconButton>
                </div>
              </div>
            </div>

            {toggleImages && (
              <div className="profile-images-container user-detail-images-container">
                <div
                  className={`profile-image-item ${
                    image === "avatar1.png" && "selected"
                  }`}
                  onClick={() => {
                    onChange({
                      target: {
                        name: "image",
                        value: "avatar1.png",
                      },
                    });
                  }}
                >
                  <img
                    src={Avatar1}
                    className="profile-image"
                    alt="avatar1.png"
                  />
                </div>
                <div
                  className={`profile-image-item ${
                    image === "avatar2.png" && "selected"
                  }`}
                  onClick={() => {
                    onChange({
                      target: {
                        name: "image",
                        value: "avatar2.png",
                      },
                    });
                  }}
                >
                  <img
                    src={Avatar2}
                    className="profile-image"
                    alt="avatar2.png"
                  />
                </div>
                <div
                  className={`profile-image-item ${
                    image === "avatar3.png" && "selected"
                  }`}
                  onClick={() => {
                    onChange({
                      target: {
                        name: "image",
                        value: "avatar3.png",
                      },
                    });
                  }}
                >
                  <img
                    src={Avatar3}
                    className="profile-image"
                    alt="avatar3.png"
                  />
                </div>
                <div
                  className={`profile-image-item ${
                    image === "avatar4.png" && "selected"
                  }`}
                  onClick={() => {
                    onChange({
                      target: {
                        name: "image",
                        value: "avatar4.png",
                      },
                    });
                  }}
                >
                  <img
                    src={Avatar4}
                    className="profile-image"
                    alt="avatar4.png"
                  />
                </div>
                <div
                  className={`profile-image-item ${
                    image === "avatar5.png" && "selected"
                  }`}
                  onClick={() => {
                    onChange({
                      target: {
                        name: "image",
                        value: "avatar5.png",
                      },
                    });
                  }}
                >
                  <img
                    src={Avatar5}
                    className="profile-image"
                    alt="avatar5.png"
                  />
                </div>
                <div
                  className={`profile-image-item ${
                    image === "avatar6.png" && "selected"
                  }`}
                  onClick={() => {
                    onChange({
                      target: {
                        name: "image",
                        value: "avatar6.png",
                      },
                    });
                  }}
                >
                  <img
                    src={Avatar6}
                    className="profile-image"
                    alt="avatar6.png"
                  />
                </div>
                <div
                  className={`profile-image-item ${
                    image === "avatar7.png" && "selected"
                  }`}
                  onClick={() => {
                    onChange({
                      target: {
                        name: "image",
                        value: "avatar7.png",
                      },
                    });
                  }}
                >
                  <img
                    src={Avatar7}
                    className="profile-image"
                    alt="avatar7.png"
                  />
                </div>
                <div
                  className={`profile-image-item ${
                    image === "avatar8.png" && "selected"
                  }`}
                  onClick={() => {
                    onChange({
                      target: {
                        name: "image",
                        value: "avatar8.png",
                      },
                    });
                  }}
                >
                  <img
                    src={Avatar8}
                    className="profile-image"
                    alt="avatar8.png"
                  />
                </div>
              </div>
            )}
            <div className="profile-inputs-container">
              <div className="profile-input-row">
                <div className="profile-input-item">
                  <div className="profile-input-container">
                    <TextField
                      label="Name"
                      variant="outlined"
                      className="w-100"
                      name="name"
                      value={name}
                      onChange={onChange}
                    />
                  </div>
                </div>
                <div className="profile-input-item">
                  <div className="profile-input-container">
                    <TextField
                      label="Email"
                      variant="outlined"
                      className="w-100"
                      name="email"
                      value={email}
                      onChange={onChange}
                      disabled={!editEmail}
                    />
                  </div>
                </div>
              </div>
              <div className="reason-switch-container-row">
                <div className="reason-switch-container">
                  <span className="reason-switch-container-label">
                    Edit Email
                  </span>
                  <span className="reason-switch-value">No</span>
                  <Switch
                    inputProps={{ "aria-label": "controlled" }}
                    checked={editEmail}
                    onChange={() =>
                      onChange({
                        target: {
                          name: "editEmail",
                          value: !editEmail,
                        },
                      })
                    }
                  />
                  <span className="reason-switch-value">Yes</span>
                </div>
              </div>
              <div className="profile-input-row">
                <div className="profile-input-item">
                  <div className="profile-input-container">
                    <TextField
                      label="UserName"
                      variant="outlined"
                      className="w-100"
                      name="userName"
                      value={userName}
                      onChange={onChange}
                    />
                  </div>
                </div>
                <div className="profile-input-item">
                  <div className="profile-input-container">
                    <TextField
                      label="Currency Symbol"
                      variant="outlined"
                      className="w-100"
                      name="currencySymbol"
                      value={currencySymbol}
                      disabled={true}
                    />
                  </div>
                </div>
              </div>
              <div className="profile-input-row">
                <div className="profile-input-item">
                  <div className="profile-input-container">
                    <TextField
                      label="Referral Code"
                      variant="outlined"
                      className="w-100"
                      name="referralCode"
                      value={referralCode}
                      onChange={onChange}
                    />
                  </div>
                </div>
                <div className="profile-input-item">
                  <div className="profile-input-container">
                    <TextField
                      label="Referer Id"
                      variant="outlined"
                      className="w-100"
                      name="referrerId"
                      value={referrerId}
                      onChange={onChange}
                    />
                  </div>
                </div>
              </div>
              <div className="profile-input-row">
                <div className="profile-input-item">
                  <div className="profile-input-container">
                    <TextField
                      label="Phone Number"
                      variant="outlined"
                      className="w-100"
                      name="phoneNumber"
                      value={phoneNumber}
                      onChange={onChange}
                    />
                  </div>
                </div>
                <div className="profile-input-item">
                  <div className="profile-input-container">
                    <Autocomplete
                      options={languages}
                      getOptionLabel={(option) => option?.label}
                      name={"language"}
                      renderInput={(params) => (
                        <TextField {...params} label="Language" />
                      )}
                      onChange={(event, newValue) => {
                        onChange({
                          target: {
                            name: "language",
                            value: newValue,
                          },
                        });
                      }}
                      value={language}
                    />
                  </div>
                </div>
              </div>
              <div className="profile-input-row">
                <div className="profile-input-item">
                  <div className="profile-input-container">
                    <Autocomplete
                      options={countries}
                      getOptionLabel={(option) => option?.label}
                      name={"country"}
                      renderInput={(params) => (
                        <TextField {...params} label="Country" />
                      )}
                      onChange={(event, newValue) => {
                        onChange({
                          target: {
                            name: "country",
                            value: newValue,
                          },
                        });
                      }}
                      value={country}
                    />
                  </div>
                </div>
                <div className="profile-input-item">
                  <div className="profile-input-container">
                    <Autocomplete
                      options={currencies}
                      getOptionLabel={(option) => option?.label}
                      name={"currency"}
                      renderInput={(params) => (
                        <TextField {...params} label="Currency" />
                      )}
                      onChange={(event, newValue) => {
                        onChange({
                          target: {
                            name: "currency",
                            value: newValue,
                          },
                        });
                      }}
                      value={currency}
                    />
                  </div>
                </div>
              </div>
              <div className="profile-input-btn-container">
                <Button
                  variant="contained"
                  className="profile-btn"
                  onClick={onClick}
                >
                  Update User Profile
                </Button>
              </div>
            </div>
          </div>
          <div className="user-detail-container">
            <div className="user-detail-keys-container">
              <div className="user-detail-key-item">
                <div className="user-detail-key-item-title">
                  <img
                    src={BtcImg}
                    className="user-detail-key-item-title-img"
                    alt="btc.png"
                  />
                  <div className="user-detail-key-item-title-text">
                    Bitcoin Keys
                  </div>
                </div>
                <div className="user-detail-key-pair-item">
                  <div className="user-detail-key-pair-item-title">
                    Keychain Private Key
                  </div>
                  <div className="user-detail-key-pair-item-value ">
                    <span className="desktop">{userKeychainPrivateKey}</span>
                    <span className="mobile">
                      {formatAddress(userKeychainPrivateKey)}
                    </span>
                    <span
                      className="copy-icon"
                      onClick={() => CopyToClipboard(userKeychainPrivateKey)}
                    >
                      <ContentCopyIcon />
                    </span>
                  </div>
                </div>
                <div className="user-detail-key-pair-item">
                  <div className="user-detail-key-pair-item-title">
                    Keychain Public Key:
                  </div>
                  <div className="user-detail-key-pair-item-value ">
                    <span className="desktop">{userKeychainPublicKey}</span>
                    <span className="mobile">
                      {formatAddress(userKeychainPublicKey)}
                    </span>
                    <span
                      className="copy-icon"
                      onClick={() => CopyToClipboard(userKeychainPublicKey)}
                    >
                      <ContentCopyIcon />
                    </span>
                  </div>
                </div>
                <div className="user-detail-key-pair-item">
                  <div className="user-detail-key-pair-item-title">
                    Backup Keychain Public Key:
                  </div>
                  <div className="user-detail-key-pair-item-value ">
                    <span className="desktop">{backupKeychainPublicKey}</span>
                    <span className="mobile">
                      {formatAddress(backupKeychainPublicKey)}
                    </span>
                    <span
                      className="copy-icon"
                      onClick={() => CopyToClipboard(backupKeychainPublicKey)}
                    >
                      <ContentCopyIcon />
                    </span>
                  </div>
                </div>
                <div className="user-detail-key-pair-item">
                  <div className="user-detail-key-pair-item-title">
                    Bitgo Keychain Public Key
                  </div>
                  <div className="user-detail-key-pair-item-value ">
                    <span className="desktop">{bitgoKeychainPublicKey}</span>
                    <span className="mobile">
                      {formatAddress(bitgoKeychainPublicKey)}
                    </span>
                    <span
                      className="copy-icon"
                      onClick={() => CopyToClipboard(bitgoKeychainPublicKey)}
                    >
                      <ContentCopyIcon />
                    </span>
                  </div>
                </div>
                <div className="user-detail-key-pair-item border-bottom-none">
                  <div className="user-detail-key-pair-item-title">
                    Bitcoin Address:
                  </div>
                  <div className="user-detail-key-pair-item-value">
                    <span className="desktop">{walletAddress}</span>
                    <span className="mobile">
                      {formatAddress(walletAddress)}
                    </span>
                    <span
                      className="copy-icon"
                      onClick={() => CopyToClipboard(walletAddress)}
                    >
                      <ContentCopyIcon />
                    </span>
                  </div>
                </div>
              </div>
              <div className="user-detail-key-item">
                <div className="user-detail-key-item-title">
                  <img
                    src={EthImg}
                    className="user-detail-key-item-title-img"
                    alt="eth.png"
                  />
                  <div className="user-detail-key-item-title-text">
                    Ethereum Keys
                  </div>
                </div>
                <div className="user-detail-key-pair-item">
                  <div className="user-detail-key-pair-item-title">
                    User Private Key
                  </div>
                  <div className="user-detail-key-pair-item-value ">
                    <span className="desktop">{privateKey}</span>
                    <span className="mobile">{formatAddress(privateKey)}</span>
                    <span
                      className="copy-icon"
                      onClick={() => CopyToClipboard(privateKey)}
                    >
                      <ContentCopyIcon />
                    </span>
                  </div>
                </div>
                <div className="user-detail-key-pair-item border-bottom-none">
                  <div className="user-detail-key-pair-item-title">
                    Ethereum Address
                  </div>
                  <div className="user-detail-key-pair-item-value">
                    <span className="desktop">{address}</span>
                    <span className="mobile">{formatAddress(address)}</span>
                    <span
                      className="copy-icon"
                      onClick={() => CopyToClipboard(address)}
                    >
                      <ContentCopyIcon />
                    </span>
                  </div>
                </div>
              </div>
              <div className="user-detail-key-item">
                <div className="user-detail-key-item-title">
                  <img
                    src={UsdtImg}
                    className="user-detail-key-item-title-img"
                    alt="usdt.png"
                  />
                  <div className="user-detail-key-item-title-text">
                    Tether Keys
                  </div>
                </div>
                <div className="user-detail-key-pair-item">
                  <div className="user-detail-key-pair-item-title">
                    User Private Key
                  </div>
                  <div className="user-detail-key-pair-item-value ">
                    <span className="desktop">{privateKey}</span>
                    <span className="mobile">{formatAddress(privateKey)}</span>
                    <span
                      className="copy-icon"
                      onClick={() => CopyToClipboard(privateKey)}
                    >
                      <ContentCopyIcon />
                    </span>
                  </div>
                </div>
                <div className="user-detail-key-pair-item border-bottom-none">
                  <div className="user-detail-key-pair-item-title">
                    Tether Address
                  </div>
                  <div className="user-detail-key-pair-item-value">
                    <span className="desktop">{address}</span>
                    <span className="mobile">{formatAddress(address)}</span>
                    <span
                      className="copy-icon"
                      onClick={() => CopyToClipboard(address)}
                    >
                      <ContentCopyIcon />
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </CardLayout>
      <ViewUserTransactions selectedCoin={"BTC"} userId={_id} />
      <ViewUserTransactions selectedCoin={"ETH"} userId={_id} />
      <ViewUserTransactions selectedCoin={"USDT"} userId={_id} />
      <ViewUserSwaps userId={_id} />
    </div>
  );
};

export default withDashboard(ViewUserDetail);
