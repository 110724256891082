import { Card } from "@mui/material";
import React, { Fragment } from "react";
import "./index.css";
import Loader from "../../components/Loader";

const CardLayout = (props) => {
  return (
    <Fragment>
      <div className="card-layout-container">
        <Card className="layout-card">
          <Fragment>{props.children}</Fragment>
          <Loader />
        </Card>
      </div>
    </Fragment>
  );
};

export default CardLayout;
