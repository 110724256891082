import React, { Fragment, useEffect, useState } from "react";
import "./index.css";
import withDashboard from "../../HOC/withDashboard";
import BtcImg from "../../assets/bitcoin.png";
import EthImg from "../../assets/ethereum.png";
import UsdtImg from "../../assets/usdt.png";
import PriceCardList from "../../components/PriceCardList";
import { useDispatch, useSelector } from "react-redux";
import {
  getBTCAddress,
  getBTCBalance,
  getBTCPrices,
  setBtc,
} from "../../redux/actions/btcAction";
import {
  getETHAddress,
  getETHBalance,
  getETHPrices,
  setETH,
} from "../../redux/actions/ethAction";
import {
  getUSDTAddress,
  getUSDTBalance,
  getUSDTPrices,
  setUSDT,
} from "../../redux/actions/usdtAction";
import { Button, Card, OutlinedInput } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { MenuItem, Select } from "@mui/material";
import { getSwapRanges, setUser } from "../../redux/actions/userAction";
import Loader from "../../components/Loader";
import { useNavigate } from "react-router-dom";
import ViewSwaps from "../../components/ViewSwaps";
import { isNumber } from "../../utils";
import Swal from "sweetalert2";

const Swaps = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const User = useSelector(({ User }) => User);
  const [balanceToShow, setBalanceToShow] = useState(0);

  const {
    currentUser,
    swapCoinFromSelect,
    swapCoinFrom,
    swapCoinToSelect,
    swapCoinTo,
    range,
  } = User;
  const { currency } = currentUser || {};

  const coins = [
    { value: "BTC", label: "BTC", image: BtcImg },
    { value: "ETH", label: "ETH", image: EthImg },
    { value: "USDT", label: "USDT", image: UsdtImg },
  ];
  const [ToCoins, setToCoins] = useState([
    { value: "BTC", label: "BTC", image: BtcImg },
    { value: "ETH", label: "ETH", image: EthImg },
    { value: "USDT", label: "USDT", image: UsdtImg },
  ]);
  const Btc = useSelector(({ Btc }) => Btc);
  const Eth = useSelector(({ Eth }) => Eth);
  const Usdt = useSelector(({ Usdt }) => Usdt);

  const coinMappings = {
    BTC: {
      coinName: "Bitcoin",
      circleColor: "yellow-circle",
      baseColor: "yellow",
      borderColor: "rgba(244, 183, 63, 1)",
      backgroundColor: "rgba(244, 183, 63, 0.2)",
      selectedReducer: Btc,
      image: BtcImg,
    },
    ETH: {
      coinName: "Ethereum",
      circleColor: "blue-circle",
      baseColor: "blue",
      borderColor: "rgba(0, 107, 250, 1)",
      backgroundColor: "rgba(0, 107, 250, 0.2)",
      selectedReducer: Eth,
      image: EthImg,
    },
    USDT: {
      coinName: "Tether",
      circleColor: "green-circle",
      baseColor: "green",
      borderColor: "rgba(75, 192, 192, 1)",
      backgroundColor: "rgba(75, 192, 192, 0.2)",
      selectedReducer: Usdt,
      image: UsdtImg,
    },
  };

  useEffect(() => {
    dispatch(getBTCPrices({ currency }));
    dispatch(getETHPrices({ currency }));
    dispatch(getUSDTPrices({ currency }));
    dispatch(getBTCBalance());
    dispatch(getETHBalance());
    dispatch(getUSDTBalance());
    dispatch(getBTCAddress());
    dispatch(getETHAddress());
    dispatch(getUSDTAddress());
    // eslint-disable-next-line
  }, []);

  const useStyles = makeStyles((theme) => ({
    select: {
      display: "flex",
      alignItems: "center",
    },
    coinImage: {
      width: 24,
      marginRight: 8,
    },
  }));

  const classes = useStyles();

  const onCoinFromChange = (e) => {
    console.clear();
    const value = e.target.value;
    dispatch(
      setUser({
        name: "swapCoinFromSelect",
        value,
      })
    );
    const coin = value;
    const { selectedReducer } = coinMappings[coin];
    let balance = selectedReducer.balance;
    balance = parseFloat(balance).toFixed(7);
    if (Number.isInteger(parseFloat(balance))) {
      balance = parseFloat(balance).toString();
    }
    setBalanceToShow(balance);

    const newToCoins = coins.filter((item) => item.value !== coin);
    setToCoins(newToCoins);
    const selectedToCoin = swapCoinToSelect;
    if (coin === selectedToCoin) {
      dispatch(
        setUser({
          name: "swapCoinToSelect",
          value: null,
        })
      );
      dispatch(
        setUser({
          name: "swapCoinTo",
          value: "",
        })
      );
    }
    const coinTo = swapCoinToSelect;
    if (coin && coinTo) {
      dispatch(
        getSwapRanges({
          data: {
            coinToSend: coin,
            coinToRecieve: coinTo,
          },
        })
      );
      dispatch(
        setUser({
          name: "range",
          value: null,
        })
      );
    }
  };

  const onCoinToChange = (e) => {
    const value = e.target.value;
    dispatch(
      setUser({
        name: "swapCoinToSelect",
        value,
      })
    );
    const coinTo = value;
    const coinFrom = swapCoinFromSelect;
    if (coinFrom && coinTo) {
      dispatch(
        getSwapRanges({
          data: {
            coinToSend: coinFrom,
            coinToRecieve: coinTo,
          },
        })
      );
    }
  };

  const onInputCoinFromChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    dispatch(
      setUser({
        name,
        value,
      })
    );
    const coinFrom = swapCoinFromSelect;
    const selectedCoinFromData = coinMappings[coinFrom];
    const selectedFromReducer = selectedCoinFromData.selectedReducer;
    const coinFromPriceNow = selectedFromReducer.priceNow;
    const coinTo = swapCoinToSelect;
    if (coinTo) {
      const selectedCoinToData = coinMappings[coinTo];
      const selectedToReducer = selectedCoinToData.selectedReducer;
      const coinToPriceNow = selectedToReducer.priceNow;
      const toAmount = (value * coinFromPriceNow) / coinToPriceNow;
      let coinValue = parseFloat(toAmount).toFixed(7);
      if (Number.isInteger(parseFloat(coinValue))) {
        coinValue = parseFloat(coinValue).toString();
      }
      dispatch(
        setUser({
          name: "swapCoinTo",
          value: coinValue,
        })
      );
    }
  };

  const onInputCoinToChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    dispatch(
      setUser({
        name,
        value,
      })
    );
    const coinTo = swapCoinToSelect;
    const selectedCoinToData = coinMappings[coinTo];
    const selectedToReducer = selectedCoinToData.selectedReducer;
    const coinToPriceNow = selectedToReducer.priceNow;

    if (swapCoinFromSelect) {
      const coinFrom = swapCoinFromSelect;
      const selectedCoinFromData = coinMappings[coinFrom];
      const selectedFromReducer = selectedCoinFromData.selectedReducer;
      const coinFromPriceNow = selectedFromReducer.priceNow;

      const toAmount = (value * coinToPriceNow) / coinFromPriceNow;
      let coinValue = parseFloat(toAmount).toFixed(7);
      if (Number.isInteger(parseFloat(coinValue))) {
        coinValue = parseFloat(coinValue).toString();
      }
      dispatch(
        setUser({
          name: "swapCoinFrom",
          value: coinValue,
        })
      );
    }
  };
  const isValidSwap = () => {
    const min = range?.min;
    const max = range?.max;
    const coinFrom = swapCoinFromSelect;
    const { selectedReducer, coinName } = coinMappings[coinFrom];
    const { balance } = selectedReducer;

    if (swapCoinFromSelect === "") {
      Swal.fire({
        icon: "error",
        title: "Error Occured",
        text: `Select Coin to Send`,
      });
      return false;
    } else if (swapCoinFrom === "") {
      Swal.fire({
        icon: "error",
        title: "Error Occured",
        text: `Coin to Send is required`,
      });
      return false;
    } else if (swapCoinToSelect === "") {
      Swal.fire({
        icon: "error",
        title: "Error Occured",
        text: `Select Coin to Receive`,
      });
      return false;
    } else if (balance < min || balance < 0) {
      Swal.fire({
        icon: "error",
        title: "Error Occured",
        text: `Insufficent ${coinName} Balance `,
      });
      return false;
    } else if (swapCoinFrom < min || swapCoinFrom > max) {
      Swal.fire({
        icon: "error",
        title: "Error Occured",
        text: `Coin to Send/Swap must be between ${min} ${coinFrom} and ${max} ${coinFrom} `,
      });
      return false;
    } else {
      return true;
    }
  };
  const onContinue = () => {
    if (isValidSwap()) {
      const coinFrom = swapCoinFromSelect;

      const { selectedReducer } = coinMappings[coinFrom];
      const { address } = selectedReducer;

      if (coinFrom === "BTC") {
        dispatch(
          setBtc({
            name: "sendAmount",
            value: swapCoinFrom,
          })
        );
        dispatch(
          setBtc({
            name: "recipientAddress",
            value: address,
          })
        );
      } else if (coinFrom === "ETH") {
        dispatch(
          setETH({
            name: "sendAmount",
            value: swapCoinFrom,
          })
        );
        dispatch(
          setETH({
            name: "recipientAddress",
            value: address,
          })
        );
      } else if (coinFrom === "USDT") {
        dispatch(
          setUSDT({
            name: "sendAmount",
            value: swapCoinFrom,
          })
        );
        dispatch(
          setUSDT({
            name: "recipientAddress",
            value: address,
          })
        );
      }
      navigate("/reviewSwap");
    }
  };

  return (
    <div className="wallets-container">
      <PriceCardList />
      <div className="swaps-layout-container">
        <Card className="swap-card">
          <div className="swap-title">Swap Crypto</div>
          <div className="swap-inputs-container">
            <div className="swap-input-item">
              <div className="swap-range-container">
                <div className="swap-range-item">
                  {" "}
                  Min: {range?.min} {swapCoinFromSelect}
                </div>
                <div className="swap-range-item">
                  {" "}
                  Max: {range?.max} {swapCoinFromSelect}
                </div>
              </div>
              <div className="swap-intput">
                <div className="swap-input-textfield">
                  <OutlinedInput
                    variant="outlined"
                    className="w-100"
                    name={"swapCoinFrom"}
                    onChange={(e) => {
                      let value = e.target.value;
                      if (/^0[^\d.]/.test(value)) {
                        value = "0";
                      }
                      if (isNumber(value)) {
                        onInputCoinFromChange(e);
                      }
                    }}
                    value={swapCoinFrom}
                  />
                </div>
                <div className="swap-input-dropdown">
                  <Select
                    onChange={onCoinFromChange}
                    className={classes.select}
                    renderValue={(selected) => (
                      <Fragment>
                        <div className={classes.select}>
                          <img
                            src={
                              coins.find((coin) => coin.value === selected)
                                ?.image
                            }
                            alt={selected}
                            className={classes.coinImage}
                          />
                          {selected}
                        </div>
                      </Fragment>
                    )}
                    value={swapCoinFromSelect}
                  >
                    {coins.map((coin) => (
                      <MenuItem key={coin.value} value={coin.value}>
                        <img
                          src={coin.image}
                          alt={coin.label}
                          className={classes.coinImage}
                        />
                        {coin.label}
                      </MenuItem>
                    ))}
                  </Select>
                </div>
              </div>
              <div className="swap-input-balance-container">
                <div className="swap-input-balance">
                  Balance: {balanceToShow} {swapCoinFromSelect}
                </div>
              </div>
            </div>
            <div className="swap-input-item">
              <div className="swap-intput">
                <div className="swap-input-textfield">
                  <OutlinedInput
                    variant="outlined"
                    className="w-100"
                    name={"swapCoinTo"}
                    value={swapCoinTo}
                    onChange={(e) => {
                      let value = e.target.value;
                      if (/^0[^\d.]/.test(value)) {
                        value = "0";
                      }
                      if (isNumber(value)) {
                        onInputCoinToChange(e);
                      }
                    }}
                  />
                </div>
                <div className="swap-input-dropdown">
                  <Select
                    onChange={onCoinToChange}
                    className={classes.select}
                    renderValue={(selected) => (
                      <Fragment>
                        <div className={classes.select}>
                          <img
                            src={
                              ToCoins.find((coin) => coin.value === selected)
                                ?.image
                            }
                            alt={selected}
                            className={classes.coinImage}
                          />
                          {selected}
                        </div>
                      </Fragment>
                    )}
                    value={swapCoinToSelect}
                  >
                    {ToCoins.map((coin) => (
                      <MenuItem key={coin.value} value={coin.value}>
                        <img
                          src={coin.image}
                          alt={coin.label}
                          className={classes.coinImage}
                        />
                        {coin.label}
                      </MenuItem>
                    ))}
                  </Select>
                </div>
              </div>
            </div>
            <div className="swap-btn-container">
              <Button
                variant="contained"
                className={`w-100 swap-btn`}
                onClick={onContinue}
              >
                Continue
              </Button>
            </div>
          </div>
          <Loader />
        </Card>
      </div>
      <ViewSwaps />
    </div>
  );
};

export default withDashboard(Swaps);
