import React, { Fragment, useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";

import Profile from "./pages/Profile";
import Login from "./pages/Login";
import Wallets from "./pages/Wallets";
import CoinWallet from "./pages/CoinWallet";
import ReviewTransaction from "./pages/ReviewTransaction";
import ConfirmTransaction from "./pages/ConfirmTransaction";
import ViewTransactionDetail from "./pages/ViewTransactionDetail";
import Swaps from "./pages/Swaps";
import ReviewSwap from "./pages/ReviewSwap";
import ConfirmSwap from "./pages/ConfirmSwap";
import ViewSwapDetail from "./pages/ViewSwapDetail";
import Users from "./pages/Users";
import ViewUserDetail from "./pages/ViewUserDetail";
import Orders from "./pages/Orders";
import ReviewOrder from "./pages/ReviewOrder";
import Configurations from "./pages/Configurations";
import Settings from "./pages/Settings";
import UpdatePassword from "./pages/UpdatePassword";
import UpdatePin from "./pages/UpdatePin";
import ProtectedRoute from "./utils/ProtectedRoute";
import Referrals from "./pages/Referrals";
import ViewReferral from "./pages/ViewReferral";
import Miners from "./pages/Miners/index";

const AppRoutes = () => {
  return (
    <Fragment>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route
          path="/profile"
          element={<ProtectedRoute Component={Profile} />}
        />
        <Route path="/miners" element={<ProtectedRoute Component={Miners} />} />
        <Route
          path="/wallets"
          element={<ProtectedRoute Component={Wallets} />}
        />
        <Route path="/coinWallet" element={<CoinWallet />} />
        <Route
          path="/reviewTransaction"
          element={<ProtectedRoute Component={ReviewTransaction} />}
        />
        <Route
          path="/confirmTransaction"
          element={<ProtectedRoute Component={ConfirmTransaction} />}
        />
        <Route
          path="/viewTransactionDetail"
          element={<ProtectedRoute Component={ViewTransactionDetail} />}
        />
        <Route path="/swaps" element={<ProtectedRoute Component={Swaps} />} />
        <Route
          path="/reviewSwap"
          element={<ProtectedRoute Component={ReviewSwap} />}
        />
        <Route
          path="/confirmSwap"
          element={<ProtectedRoute Component={ConfirmSwap} />}
        />
        <Route
          path="/viewSwapDetail"
          element={<ProtectedRoute Component={ViewSwapDetail} />}
        />
        <Route path="/users" element={<ProtectedRoute Component={Users} />} />
        <Route
          path="/viewUserDetail"
          element={<ProtectedRoute Component={ViewUserDetail} />}
        />
        <Route path="/orders" element={<ProtectedRoute Component={Orders} />} />
        <Route
          path="/reviewOrder"
          element={<ProtectedRoute Component={ReviewOrder} />}
        />
        <Route
          path="/config"
          element={<ProtectedRoute Component={Configurations} />}
        />
        <Route
          path="/settings"
          element={<ProtectedRoute Component={Settings} />}
        />
        <Route
          path="/updatePassword"
          element={<ProtectedRoute Component={UpdatePassword} />}
        />
        <Route
          path="/updatePin"
          element={<ProtectedRoute Component={UpdatePin} />}
        />
        <Route
          path="/referrals"
          element={<ProtectedRoute Component={Referrals} />}
        />
        <Route
          path="/viewReferral"
          element={<ProtectedRoute Component={ViewReferral} />}
        />
      </Routes>
    </Fragment>
  );
};

export default AppRoutes;
