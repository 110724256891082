import { Fragment } from "react";
import "../PriceCard/index.css";
import "./index.css";
import { Card } from "@mui/material";
const BalanceCard = ({ balance, title, symbol, image, currency, fixed }) => {
  let formattedBalance = parseFloat(balance).toFixed(fixed);
  if (Number.isInteger(parseFloat(formattedBalance))) {
    formattedBalance = parseFloat(formattedBalance).toString();
  }
  return (
    <Fragment>
      <div className="price-card-container">
        <Card className="price-card">
          <div className="price-card-item balance-card-item">
            <img src={image} className="price-card-image" alt="card.png" />
            <div className="price-card-item-text">
              <div className="price-card-title">{title}</div>
              <div className="price-card-value">
                {currency && currency}
                {formattedBalance} {symbol}
              </div>
            </div>
          </div>
        </Card>
      </div>
    </Fragment>
  );
};

export default BalanceCard;
