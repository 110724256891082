import { Fragment } from "react";
import withDashboard from "../../HOC/withDashboard";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  ButtonBase,
  Button,
  InputAdornment,
  TextField,
  Card,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import Loader from "../../components/Loader";
import Pagination from "../../components/Pagination";
import SearchIcon from "@mui/icons-material/Search";

import {
  getBtcTransactions,
  setBtc,
  setSearchBtcTransaction,
} from "../../redux/actions/btcAction";
import {
  getEthTransactions,
  setETH,
  setSearchEthTransaction,
} from "../../redux/actions/ethAction";
import {
  getUsdtTransactions,
  setSearchUsdtTransaction,
  setUSDT,
} from "../../redux/actions/usdtAction";
import CallMadeIcon from "@mui/icons-material/CallMade";
import CallReceivedIcon from "@mui/icons-material/CallReceived";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import PreviewIcon from "@mui/icons-material/Preview";
import {
  getUsers,
  setSearchUser,
  setUser,
  setUserDetailProfile,
} from "../../redux/actions/userAction";

const Users = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const User = useSelector(({ User }) => User);
  const {
    selectedCoin,
    users,
    filteredUsers,
    paginatedUsers,
    currentUserPage,
    totalUserPages,
    userPerPage,
    userSearch,
    selectedUser,
  } = User;

  useEffect(() => {
    // eslint-disable-next-line
  }, []);

  const setTotalPages = (totalpages) => {
    dispatch(
      setUser({
        name: "totalUserPages",
        value: totalpages,
      })
    );
  };
  const setCurrentPage = (currentPage) => {
    dispatch(
      setUser({
        name: "currentUserPage",
        value: currentPage,
      })
    );
  };
  const setPaginatedItems = (paginatedItems) => {
    dispatch(
      setUser({
        name: "paginatedUsers",
        value: paginatedItems,
      })
    );
  };
  const handleChangeRowsPerPage = (event) => {
    const value = event.target.value;
    dispatch(
      setUser({
        name: "userPerPage",
        value,
      })
    );
  };

  const onSearch = (e) => {
    const value = e.target.value;
    dispatch(setSearchUser(value));
  };

  const formatAddress = (address) => {
    return `${address?.slice(0, 4)}...${address?.slice(-4)}`;
  };

  const onViewUser = (user) => {
    dispatch(
      setUser({
        name: "selectedUser",
        value: user,
      })
    );
    dispatch(setUserDetailProfile(user));
    navigate("/viewUserDetail");
  };

  useEffect(() => {
    dispatch(getUsers());
    // eslint-disable-next-line
  }, []);

  return (
    <Fragment>
      <div className="table-layout-container">
        <Card className="table-card">
          <div className="table-title">
            <span>List of Users</span>
            <div>
              <TextField
                fullWidth
                variant="standard"
                className="search-input"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon style={{ color: "white" }} />
                    </InputAdornment>
                  ),
                }}
                onChange={onSearch}
                value={userSearch}
              />
            </div>
          </div>
          <div className="table-container">
            <table>
              <thead>
                <th>Name</th>
                <th>Email</th>
                <th>PhoneNumber</th>
                <th>Bitcoin Address</th>
                <th>Ethereum Address</th>
                <th>Action</th>
              </thead>
              <tbody>
                {filteredUsers.map((user) => {
                  const bitcoinAddress = user?.bitcoin ? formatAddress(
                    user?.bitcoin?.walletAddress
                  ) : 'N/A';
                  const etherAddress = user?.ethereum ? formatAddress(user?.ethereum?.address) : 'N/A';
                  return (
                    <Fragment>
                      <tr>
                        <td>{user.name}</td>
                        <td>{user.email}</td>
                        <td>{user.phoneNumber}</td>
                        <td>{bitcoinAddress}</td>
                        <td>{etherAddress}</td>
                        <td>
                          <div className="action-row">
                            <Button
                              className="action-btn edit"
                              variant="contained"
                              onClick={() => onViewUser(user)}
                            >
                              {" "}
                              <PreviewIcon />
                              <span> View</span>
                            </Button>
                          </div>
                        </td>
                      </tr>
                    </Fragment>
                  );
                })}
              </tbody>
            </table>
          </div>
          <Pagination
            currentPage={currentUserPage}
            setCurrentPage={setCurrentPage}
            items={filteredUsers}
            totalPages={totalUserPages}
            setTotalPages={setTotalPages}
            itemsPerPage={userPerPage}
            setPaginatedItems={setPaginatedItems}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
          />
          <Loader />
        </Card>
      </div>
    </Fragment>
  );
};

export default withDashboard(Users);
