import { ButtonBase, TextField } from "@mui/material";
import React, { Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setTestSettings } from "../../redux/actions/userAction";

const TestConfig = () => {
  const User = useSelector(({ User }) => User);
  const dispatch = useDispatch();
  const { testConfig } = User;
  const {
    MONGO_URI,
    SESSION_SECRET,
    INFURA_API,
    BITGO_ACCESS_TOKEN,
    BITGO_ENTERPRISE_KEY,
    BITGO_ENV,
    ETHERSCAN_API_KEY,
    ETHERSCAN_NETWORK,
    SMTP_HOST,
    SMTP_PORT,
    SMTP_USER,
    SMTP_PASS,
    OTP_DURATION,
    COINMARKETCAP_API_KEY,
    BTC_ADDRESS,
    ETH_ADDRESS,
    USDT_ADDRESS,
    ACCOUNT_HOLDER_NAME,
    ACCOUNT_NUMBER,
    BANK_NAME,
    REFERRAL_PERCENTAGE,
    REFERRAL_ACTIVE,
    SIMPLE_SWAP_API_KEY,
    USDT_FEE,
    ETH_FEE,
    BTC_FEE,
    MIN_BUY_USD,
    MIN_BUY_NGN,
    BTC_EXPLORER,
    ETH_EXPLORER,
    USDT_EXPLORER,
  } = testConfig || {};
  const onChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    dispatch(
      setTestSettings({
        name,
        value,
      })
    );
  };
  return (
    <Fragment>
      <div className="config-env-container">
        <div className="config-env-label">Referral:</div>
        <div className="config-env-accordian-container">
          <ButtonBase
            className={`config-env-item left ${REFERRAL_ACTIVE && "selected"}`}
            onClick={() => {
              onChange({
                target: {
                  name: "REFERRAL_ACTIVE",
                  value: true,
                },
              });
            }}
          >
            Activated
          </ButtonBase>
          <ButtonBase
            className={`config-env-item right ${
              !REFERRAL_ACTIVE && "selected"
            }`}
            onClick={() => {
              onChange({
                target: {
                  name: "REFERRAL_ACTIVE",
                  value: false,
                },
              });
            }}
          >
            Deactivated
          </ButtonBase>
        </div>
      </div>
      <div className="config-input-item-row">
        <div className="config-input-item">
          <TextField
            type="text"
            label="MongoDB URI String"
            variant="outlined"
            className="input"
            name={"MONGO_URI"}
            onChange={onChange}
            value={MONGO_URI}
          />
        </div>
        <div className="config-input-item">
          <TextField
            type="text"
            label="Session Secret"
            variant="outlined"
            className="input"
            name={"SESSION_SECRET"}
            onChange={onChange}
            value={SESSION_SECRET}
          />
        </div>
      </div>
      <div className="config-input-item-row">
        <div className="config-input-item">
          <TextField
            type="text"
            label="Infura API URL"
            variant="outlined"
            className="input"
            name={"INFURA_API"}
            onChange={onChange}
            value={INFURA_API}
          />
        </div>
        <div className="config-input-item">
          <TextField
            type="text"
            label="Bitgo Acess TOken"
            variant="outlined"
            className="input"
            name={"BITGO_ACCESS_TOKEN"}
            onChange={onChange}
            value={BITGO_ACCESS_TOKEN}
          />
        </div>
      </div>
      <div className="config-input-item-row">
        <div className="config-input-item">
          <TextField
            type="text"
            label="Bitgo Enterprise Key"
            variant="outlined"
            className="input"
            name={"BITGO_ENTERPRISE_KEY"}
            onChange={onChange}
            value={BITGO_ENTERPRISE_KEY}
          />
        </div>
        <div className="config-input-item">
          <TextField
            type="text"
            label="Bitgo Enviroment"
            variant="outlined"
            className="input"
            name={"BITGO_ENV"}
            onChange={onChange}
            value={BITGO_ENV}
          />
        </div>
      </div>
      <div className="config-input-item-row">
        <div className="config-input-item">
          <TextField
            type="text"
            label="Etherscan API Key"
            variant="outlined"
            className="input"
            name={"ETHERSCAN_API_KEY"}
            onChange={onChange}
            value={ETHERSCAN_API_KEY}
          />
        </div>
        <div className="config-input-item">
          <TextField
            type="text"
            label="Etherscan Network"
            variant="outlined"
            className="input"
            name={"ETHERSCAN_NETWORK"}
            onChange={onChange}
            value={ETHERSCAN_NETWORK}
          />
        </div>
      </div>
      <div className="config-input-item-row">
        <div className="config-input-item">
          <TextField
            type="text"
            label="SMTP Host"
            variant="outlined"
            className="input"
            name={"SMTP_HOST"}
            onChange={onChange}
            value={SMTP_HOST}
          />
        </div>
        <div className="config-input-item">
          <TextField
            type="text"
            label="SMTP Port"
            variant="outlined"
            className="input"
            name={"SMTP_PORT"}
            onChange={onChange}
            value={SMTP_PORT}
          />
        </div>
      </div>
      <div className="config-input-item-row">
        <div className="config-input-item">
          <TextField
            type="text"
            label="SMTP User"
            variant="outlined"
            className="input"
            name={"SMTP_USER"}
            onChange={onChange}
            value={SMTP_USER}
          />
        </div>
        <div className="config-input-item">
          <TextField
            type="text"
            label="SMTP Password"
            variant="outlined"
            className="input"
            name={"SMTP_PASS"}
            onChange={onChange}
            value={SMTP_PASS}
          />
        </div>
      </div>
      <div className="config-input-item-row">
        <div className="config-input-item">
          <TextField
            type="text"
            label="OTP Duration in Days"
            variant="outlined"
            className="input"
            name={"OTP_DURATION"}
            onChange={onChange}
            value={OTP_DURATION}
          />
        </div>
        <div className="config-input-item">
          <TextField
            type="text"
            label="Coinmarketcap API Key"
            variant="outlined"
            className="input"
            name={"COINMARKETCAP_API_KEY"}
            onChange={onChange}
            value={COINMARKETCAP_API_KEY}
          />
        </div>
      </div>
      <div className="config-input-item-row">
        <div className="config-input-item">
          <TextField
            type="text"
            label="Bitcoin Address to Receive Fees"
            variant="outlined"
            className="input"
            name={"BTC_ADDRESS"}
            onChange={onChange}
            value={BTC_ADDRESS}
          />
        </div>
        <div className="config-input-item">
          <TextField
            type="text"
            label="Ethereum Address to Receive Fees"
            variant="outlined"
            className="input"
            name={"ETH_ADDRESS"}
            onChange={onChange}
            value={ETH_ADDRESS}
          />
        </div>
      </div>
      <div className="config-input-item-row">
        <div className="config-input-item">
          <TextField
            type="text"
            label="Tether Address to Receive Fees"
            variant="outlined"
            className="input"
            name={"USDT_ADDRESS"}
            onChange={onChange}
            value={USDT_ADDRESS}
          />
        </div>
        <div className="config-input-item">
          <TextField
            type="text"
            label="Account Holder Name"
            variant="outlined"
            className="input"
            name={"ACCOUNT_HOLDER_NAME"}
            onChange={onChange}
            value={ACCOUNT_HOLDER_NAME}
          />
        </div>
      </div>
      <div className="config-input-item-row">
        <div className="config-input-item">
          <TextField
            type="text"
            label="Account Number"
            variant="outlined"
            className="input"
            name={"ACCOUNT_NUMBER"}
            onChange={onChange}
            value={ACCOUNT_NUMBER}
          />
        </div>
        <div className="config-input-item">
          <TextField
            type="text"
            label="Bank Name"
            variant="outlined"
            className="input"
            name={"BANK_NAME"}
            onChange={onChange}
            value={BANK_NAME}
          />
        </div>
      </div>
      <div className="config-input-item-row">
        <div className="config-input-item">
          <TextField
            type="text"
            label="Refferal Percentage"
            variant="outlined"
            className="input"
            name={"REFERRAL_PERCENTAGE"}
            onChange={onChange}
            value={REFERRAL_PERCENTAGE}
          />
        </div>
        <div className="config-input-item">
          <TextField
            type="text"
            label="SimpleSwap API Key"
            variant="outlined"
            className="input"
            name={"SIMPLE_SWAP_API_KEY"}
            onChange={onChange}
            value={SIMPLE_SWAP_API_KEY}
          />
        </div>
      </div>
      <div className="config-input-item-row">
        <div className="config-input-item">
          <TextField
            type="text"
            label="Tether Fee per transaction"
            variant="outlined"
            className="input"
            name={"USDT_FEE"}
            onChange={onChange}
            value={USDT_FEE}
          />
        </div>
        <div className="config-input-item">
          <TextField
            type="text"
            label="Ethereum Fee per transaction"
            variant="outlined"
            className="input"
            name={"ETH_FEE"}
            onChange={onChange}
            value={ETH_FEE}
          />
        </div>
      </div>
      <div className="config-input-item-row">
        <div className="config-input-item">
          <TextField
            type="text"
            label="Bitcoin Fee per transaction"
            variant="outlined"
            className="input"
            name={"BTC_FEE"}
            onChange={onChange}
            value={BTC_FEE}
          />
        </div>
        <div className="config-input-item">
          <TextField
            type="text"
            label="Minimum USD user can buy"
            variant="outlined"
            className="input"
            name={"MIN_BUY_USD"}
            onChange={onChange}
            value={MIN_BUY_USD}
          />
        </div>
      </div>
      <div className="config-input-item-row">
        <div className="config-input-item">
          <TextField
            type="text"
            label="Minimum NGN user can buy"
            variant="outlined"
            className="input"
            name={"MIN_BUY_NGN"}
            onChange={onChange}
            value={MIN_BUY_NGN}
          />
        </div>
        <div className="config-input-item">
          <TextField
            type="text"
            label="Bitcoin Explorer URL"
            variant="outlined"
            className="input"
            name={"BTC_EXPLORER"}
            onChange={onChange}
            value={BTC_EXPLORER}
          />
        </div>
      </div>
      <div className="config-input-item-row">
        <div className="config-input-item">
          <TextField
            type="text"
            label="Ethereum Explorer URL"
            variant="outlined"
            className="input"
            name={"ETH_EXPLORER"}
            onChange={onChange}
            value={ETH_EXPLORER}
          />
        </div>
        <div className="config-input-item">
          <TextField
            type="text"
            label="USDT Explorer URL"
            variant="outlined"
            className="input"
            name={"USDT_EXPLORER"}
            onChange={onChange}
            value={USDT_EXPLORER}
          />
        </div>
      </div>
    </Fragment>
  );
};

export default TestConfig;
