import { Fragment } from "react";
import withDashboard from "../../HOC/withDashboard";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  ButtonBase,
  Button,
  InputAdornment,
  TextField,
  Card,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import Loader from "../../components/Loader";
import Pagination from "../../components/Pagination";
import SearchIcon from "@mui/icons-material/Search";
import CallMadeIcon from "@mui/icons-material/CallMade";
import CallReceivedIcon from "@mui/icons-material/CallReceived";
import PreviewIcon from "@mui/icons-material/Preview";
import {
  getOrderDetail,
  getOrders,
  getReferrals,
  setSearchOrder,
  setSearchReferral,
  setUser,
} from "../../redux/actions/userAction";
import { capitalize } from "../../utils";
import "./index.css";
import noTransactionImg from "../../assets/transaction_eth.png";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";

const Referrals = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const User = useSelector(({ User }) => User);
  const {
    filteredReferrals,
    paginatedReferrals,
    currentReferralPage,
    totalReferralPages,
    referralPerPage,
    referralSearch,
    testConfig,
    prodConfig,
    enviroment,
  } = User;
  const config = enviroment === "test" ? testConfig : prodConfig;
  const { BTC_EXPLORER, ETH_EXPLORER, USDT_EXPLORER } = config;
  const coinMappings = {
    BTC: {
      explorer: BTC_EXPLORER,
    },
    ETH: {
      explorer: ETH_EXPLORER,
    },
    USDT: {
      explorer: USDT_EXPLORER,
    },
  };

  const setTotalPages = (totalpages) => {
    dispatch(
      setUser({
        name: "totalReferralPages",
        value: totalpages,
      })
    );
  };
  const setCurrentPage = (currentPage) => {
    dispatch(
      setUser({
        name: "currentReferralPage",
        value: currentPage,
      })
    );
  };
  const setPaginatedItems = (paginatedItems) => {
    dispatch(
      setUser({
        name: "paginatedReferrals",
        value: paginatedItems,
      })
    );
  };
  const handleChangeRowsPerPage = (event) => {
    const value = event.target.value;
    dispatch(
      setUser({
        name: "referralPerPage",
        value,
      })
    );
  };

  const onSearch = (e) => {
    const value = e.target.value;
    dispatch(setSearchReferral(value));
  };

  const onViewReferral = (referral) => {
    dispatch(
      setUser({
        name: "selectedReferral",
        value: referral,
      })
    );
    navigate("/viewReferral");
  };

  useEffect(() => {
    dispatch(getReferrals());
    // eslint-disable-next-line
  }, []);

  const formatAddress = (address) => {
    return `${address.slice(0, 4)}...${address.slice(-4)}`;
  };

  const ViewOnBlockchain = (transactionHx, coin) => {
    const selectedCoinData = coinMappings[coin];
    const { explorer } = selectedCoinData;

    const url = `${explorer}${transactionHx}`;
    window.open(url, "_blank");
  };

  return (
    <Fragment>
      <div className="table-layout-container">
        <Card className="table-card">
          <div className="table-title">
            <span>List of Referrals</span>
            <div>
              <TextField
                fullWidth
                variant="standard"
                className="search-input"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon style={{ color: "white" }} />
                    </InputAdornment>
                  ),
                }}
                onChange={onSearch}
                value={referralSearch}
              />
            </div>
          </div>
          <div className="table-container">
            <table>
              <thead>
                <th>Referrral Name</th>
                <th>Referre Name</th>
                <th>Amount Deposited</th>
                <th>Amount Paid</th>
                <th>Action</th>
              </thead>
              <tbody>
                {paginatedReferrals.length >= 1 &&
                  paginatedReferrals.map((item) => {
                    const formattedTransactionHx = formatAddress(
                      item.transactionHx
                    );
                    return (
                      <Fragment>
                        <tr>
                          <td>{item.refferal.name}</td>
                          <td>{item.referre.name}</td>
                          <td>{`${item.amountDeposited} ${item.coin}`}</td>
                          <td>{`${item.amountPaid} ${item.coin}`}</td>
                          <td className="with-icon">
                            <span className="td-icon-text icon-right">
                              {" "}
                              {formattedTransactionHx}
                            </span>
                            <span className={`td-icon-container new-tab`}>
                              <ButtonBase
                                onClick={() =>
                                  ViewOnBlockchain(
                                    item.transactionHx,
                                    item.coin
                                  )
                                }
                              >
                                <OpenInNewIcon className="table-icon" />
                              </ButtonBase>
                            </span>
                          </td>
                          <td>
                            <div className="action-row">
                              <Button
                                className="action-btn edit"
                                variant="contained"
                                onClick={() => onViewReferral(item)}
                              >
                                {" "}
                                <PreviewIcon />
                                <span> View</span>
                              </Button>
                            </div>
                          </td>
                        </tr>
                      </Fragment>
                    );
                  })}
              </tbody>
            </table>
            {paginatedReferrals.length < 1 && (
              <Fragment>
                <div className="no-transaction-container">
                  <img src={noTransactionImg} className="no-transaction-img" />
                  <div className="no-transaction-text">No Referrals Found</div>
                </div>
              </Fragment>
            )}
          </div>
          <Pagination
            currentPage={currentReferralPage}
            setCurrentPage={setCurrentPage}
            items={filteredReferrals}
            totalPages={totalReferralPages}
            setTotalPages={setTotalPages}
            itemsPerPage={referralPerPage}
            setPaginatedItems={setPaginatedItems}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
          />
          <Loader />
        </Card>
      </div>
    </Fragment>
  );
};

export default withDashboard(Referrals);
