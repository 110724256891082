import "./index.css";
import withDashboard from "../../HOC/withDashboard";
import { useDispatch } from "react-redux";
import Loader from "../../components/Loader";
import { setLoader } from "../../redux/actions/userAction";
import { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import Switch from "react-switch";
import axios from "axios"
//assets
import Stack from "../../assets/Stack.svg";
import Misc from "../../assets/Misc.svg";
import Dots from "../../assets/Dots.svg";
import Edit from "../../assets/Edit.svg";
import Delete from "../../assets/Delete.svg";
import Cross from "../../assets/Cross.svg";
import Add from "../../assets/Add.svg";
import Minus from "../../assets/Minus.svg";
import Clock from "../../assets/Clock.svg";
import Approx from "../../assets/Approx.svg";

const BASE_URL = "https://api.coinatik.com"

function Miner({ miner,editPrepare,getAllMiners }) {
const dispatch = useDispatch()
  const {
    minerName,
    rentalRatePerHour,
    minduration,
    miningprob,
    isAvailable,
    status,
    _id
  } = miner;
  const [showEdit, setShowEdit] = useState(false);
  const [checked, setChecked] = useState(isAvailable);
  //APIs
  const deleteMinerAPI = async () => {
    try{
      dispatch(setLoader(true))
      await axios.delete(`${BASE_URL}/api/miner/${_id}/deleteMiner`,{
        withCredentials:true,
      })
      getAllMiners()
      dispatch(setLoader(false))
    }catch(err){
      console.log(err)
      dispatch(setLoader(false))
    }
  }
  const changeActive = async () => {
    try{
      dispatch(setLoader(true))
      await axios.put(`${BASE_URL}/api/miner/${_id}/changeActive`,{isAvailable:checked},{
        withCredentials:true
      })
      dispatch(setLoader(false))
    }catch(err){
      console.log(err)
      dispatch(setLoader(false))

    }
  }


  useEffect(() => {
    changeActive()
  },[checked])

  return (
    <>
      <tr>
        <td>
          <Switch
            checked={checked}
            onChange={setChecked}
            onColor="#003687"
            width={45}
            height={24}
            handleDiameter={20}
          />
        </td>
        <td>{minerName}</td>
        <td>{rentalRatePerHour}BTC/Hr</td>
        <td>{minduration}hrs</td>
        <td>{miningprob}</td>
        <td
          className={`${
            status.includes("resting")
              ? "greenR"
              : status.includes("error")
              ? "redR"
              : "blueR"
          }  `}
        >
          {" "}
          {status.includes("resting")
            ? "🟢"
            : status.includes("error")
            ? "🔴"
            : "🔵"}{" "}
          {status}
        </td>
        <td>
          <button onClick={() => setShowEdit((prev) => !prev)} className="dots">
            <img src={Dots} alt="" />
          </button>
          {showEdit && (
            <div className="edit">
              <div onClick={() => editPrepare(miner)}>
                <img src={Edit} alt="" /> Edit
              </div>
              <div onClick={deleteMinerAPI}>
                <img src={Delete} alt="" /> Delete
              </div>
            </div>
          )}
        </td>
      </tr>
    </>
  );
}

function Miners() {
  const formRef = useRef();
  const dispatch = useDispatch();
  const [ isAdd,setIsAdd ] = useState(false);
  const [revenue, setRevenue] = useState(2.8566);
  const [revenueComparison, setRevenueComparison] = useState(0);
  const [usersPercentage, setUsersPercentage] = useState("100%");
  const [usersNum, setUsersNum] = useState(15);
  const [allMiners, setAllMiners] = useState([]);
  const [formName, setFormName] = useState("");
  const [formRate, setFormRate] = useState("");
  const [formProfit, setFormProfit] = useState("");
  const [formMinDuration, setFormMinDuration] = useState("");
  const [formProb, setFormProb] = useState("");
  const [formIsActive, setFormIsActive] = useState(false);
  const [ minerId,setMinerId ] = useState("")
  useEffect(() => {
    getAllMiners()
    getIncome()
    getUsers()
  },[])

  const editPrepare= ({minerName,miningprob,minduration,miningprofit,_id,isAvailable,rentalRatePerHour}) => {
    setFormName(minerName);
    setFormProb(miningprob);
    setFormMinDuration(minduration);
    setFormProfit(miningprofit)
    setFormIsActive(isAvailable)
    setFormRate(rentalRatePerHour)
    setMinerId(_id)
    setIsAdd(false)
    formRef.current.showModal()
  }

  //apis
  const getAllMiners = async () => {
    try{
      dispatch(setLoader(true))
      const res = await axios.get(`${BASE_URL}/api/miner/allAdminMiners`,{
        withCredentials:true
      })
      console.log(res)
      setAllMiners(res.data) 
      dispatch(setLoader(false))
    }catch(err){
      console.log(err)
      dispatch(setLoader(false))
    }
  }

  const addMinerAPI = async (e) => {
    e.preventDefault()
    try{
      dispatch(setLoader(true));
      const data = {
        minerName:formName,
        minduration:formMinDuration,
        miningprob:formProb,
        isAvailable:formIsActive,
        rentalRatePerHour:formRate,
        miningprofit:formProfit
      }
      await axios.post(`${BASE_URL}/api/miner/addMiner`,data,{
        withCredentials:true
      })
      getAllMiners()
      dispatch(setLoader(false))
      closeModal()
    }catch(err){
      console.log(err)
      dispatch(setLoader(false))   
    }
  }

  const editMinerAPI = async (e) => {
    e.preventDefault()
    try{
      dispatch(setLoader(true))
      const data = {
        minerName:formName,
        minduration:formMinDuration,
        miningprob:formProb,
        isAvailable:formIsActive,
        rentalRatePerHour:formRate,
        miningprofit:formProfit
      }
      await axios.put(`${BASE_URL}/api/miner/${minerId}/editMiner`,data,{
        withCredentials:true
      })
      getAllMiners()
      dispatch(setLoader(false))
      closeModal()
    }catch(err){
      console.log(err)
      dispatch(setLoader(false))
    }
  }

   const getIncome = async () => {
    try{
      dispatch(setLoader(true))
      const res = await axios.get(`${BASE_URL}/api/miner/weeklyIncome`,{
        withCredentials:true
      })
      setRevenue(res.data.totalIncome)
      dispatch(setLoader(false))
    }catch(err){
      console.log(err)
      dispatch(setLoader(false))
    }
   }

   const getUsers = async () => {
    try{
      dispatch(setLoader(true))
      const res = await axios.get(`${BASE_URL}/api/miner/minerUsers`,{
        withCredentials:true
      })
      console.log(res)
      setUsersNum(res.data.totalUsersWithMiners)
      setUsersPercentage(res.data.percentageOfUsersWithMiners)
      dispatch(setLoader(false))
    }catch(err){
      console.log(err)
      dispatch(setLoader(false))
    }
   }



  function dialogClickHandler(e) {
    if (e.target.tagName !== "DIALOG")
      //This prevents issues with forms
      return;

    const rect = e.target.getBoundingClientRect();

    const clickedInDialog =
      rect.top <= e.clientY &&
      e.clientY <= rect.top + rect.height &&
      rect.left <= e.clientX &&
      e.clientX <= rect.left + rect.width;

    if (clickedInDialog === false) e.target.close();
  }

  const closeModal = () => {
    setFormIsActive(false);
    setFormMinDuration("");
    setFormName("")
    setFormRate("")
    setFormProfit("")
    setFormProb("")
    formRef.current.close()
  }

  return (
    <>
      <section className="container">
        <div className="cards">
          <div className="card">
            <h1>Revenue Generated</h1>
            <p className="slate">Income generated from all miners this week</p>
            <p className="bigText">{revenue} BTC</p>
            <p className="slate">
              <span
                className={`${
                  revenueComparison >= 0 ? "greenR" : "redR"
                }`}
              >
                {revenueComparison}%
              </span>{" "}
              Compared to last week
            </p>
            <div className="details">
              <Link>VIEW DETAILS {">"}</Link>
              <img src={Stack} alt="" />
            </div>
          </div>
          <div className="card">
            <h1>Mining Users</h1>
            <p className="slate">No of users currently renting miner</p>
            <p className="bigText">{usersNum} BTC</p>
            <p className="slate">
              <span
                className={`${
                  usersPercentage >= 0 ? "greenR" : "redR"
                }`}
              >
                {usersPercentage}%
              </span>{" "}
              Users
            </p>
            <div className="details">
              <Link>VIEW ALL TARGETS {">"}</Link>
              <img src={Misc} alt="" />
            </div>
          </div>
        </div>
        <div className="miners">
          <div className="miner-header">
            <div>
              <h1>Miners</h1>
              <p className="slate">{allMiners.length} miners added</p>
            </div>
            <button onClick={() => {
              setIsAdd(true)
              formRef.current.showModal()}}>
              Add new Miner
            </button>
          </div>
          <div className="table">
            <table>
              <thead>
                <tr>
                  <th>Active</th>
                  <th>Miner</th>
                  <th>Mining Rate</th>
                  <th>Minimum Duration</th>
                  <th>Probability</th>
                  <th>Status</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {allMiners.length > 0 && allMiners.map(miner => (
                <Miner 
                key={miner._id} 
                miner={miner} 
                editPrepare={editPrepare} 
                getAllMiners={getAllMiners}
                />))}
              </tbody>
            </table>
          </div>
        </div>
        <dialog onClick={dialogClickHandler} ref={formRef}>
          <div>
            <p>{formName || "Add Miner"}</p>
            <img onClick={closeModal} src={Cross} alt="" />
          </div>
          <form onSubmit={isAdd ? addMinerAPI : editMinerAPI} className="form" action="">
            <label htmlFor="name">
              Miner Name
              <input
                onChange={(e) => setFormName(e.target.value)}
                type="text"
                id="name"
                value={formName}
                required
              />
            </label>

            <label htmlFor="rate">
              Mining Rate{"(BTC/Hr)"}
              <div className="rateD">
                <img onClick={() => setFormRate(prev => parseFloat(+(prev)-0.1).toFixed(2))} src={Minus} alt="" />
                <input
                  onChange={(e) => setFormRate(e.target.value)}
                  type="number"
                  id="rate"
                  value={formRate}
                  required
                />
                <img onClick={() => setFormRate(prev => parseFloat(+(prev)+0.1).toFixed(2))} src={Add} alt="" />
              </div>
            </label>

            <label id="prof" htmlFor="profit">
            Potential Hourly Profit {"(hrs)"}
            <img src={Approx} alt="" />
              <input
                onChange={(e) => setFormProfit(e.target.value)}
                type="number"
                id="profit"
                value={formProfit}
                required
              />
            </label>

            <label id="dur" htmlFor="duration">
              Minimun Duration {"hrs"}
              <img src={Clock} alt="" />
              <input
                onChange={(e) => setFormMinDuration(e.target.value)}
                type="number"
                id="duration"
                value={formMinDuration}
                required
              />
            </label>

            <label htmlFor="prob">
              Probability {"(hrs)"}
              <input
                onChange={(e) => setFormProb(e.target.value)}
                type="text"
                id="prob"
                value={formProb}
                required
              />
            </label>

            {isAdd && <label id="act" htmlFor="active">
              <input
                onChange={() => setFormIsActive(prev => !prev)}
                type="checkbox"
                id="active"
                value={formIsActive}
              />
              Make Active
            </label>}
            <div className="buttons">
              <button id="cancel" onClick={closeModal}>Cancel</button>
              <button id="submit" type="submit">Save</button>
            </div>
          </form>
        </dialog>
      </section>

      <Loader />
    </>
  );
}

export default withDashboard(Miners);
