import React from "react";
import "./index.css";
import withDashboard from "../../HOC/withDashboard";
import { Button } from "@mui/material";
import CardLayout from "../../components/CardLayout";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import FourDigits from "../../components/FourDigits";
import Avatar from "../../assets/avatars/avatar1.png";
import PriceCardList from "../../components/PriceCardList";
import { useDispatch, useSelector } from "react-redux";
import { checkPIN, setUser, updateOrder } from "../../redux/actions/userAction";
import { useNavigate } from "react-router-dom";
import { sendBTC } from "../../redux/actions/btcAction";
import { sendETH } from "../../redux/actions/ethAction";
import { sendUSDT } from "../../redux/actions/usdtAction";
import Swal from "sweetalert2";

const ConfirmTransaction = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const User = useSelector(({ User }) => User);
  const {
    confirmPinTransaction,
    selectedCoin,
    isBuyTransaction,
    selectedOrder,
    currentUser,
  } = User;
  const { name, email, image } = currentUser || {};
  const Btc = useSelector(({ Btc }) => Btc);
  const Eth = useSelector(({ Eth }) => Eth);
  const Usdt = useSelector(({ Usdt }) => Usdt);
  const coinMappings = {
    BTC: {
      selectedReducer: Btc,
    },
    ETH: {
      selectedReducer: Eth,
    },
    USDT: {
      selectedReducer: Usdt,
    },
  };
  const selectedCoinData = coinMappings[selectedCoin];
  const { selectedReducer } = selectedCoinData;
  const { address, sendAmount, recipientAddress } = selectedReducer;

  const onPinChange = (pin) => {
    dispatch(
      setUser({
        name: "confirmPinTransaction",
        value: pin,
      })
    );
  };
  const ConfirmTransaction = (pin) => {
    if (selectedCoin === "BTC") {
      dispatch(
        sendBTC({
          data: {
            pin,
            amount: sendAmount,
            recipientAddress,
          },
          navigate,
        })
      );
    } else if (selectedCoin === "ETH") {
      console.log({
        pin,
        amount: sendAmount,
        recipientAddress,
      });
      dispatch(
        sendETH({
          data: {
            pin,
            amount: sendAmount,
            recipientAddress,
          },
          navigate,
        })
      );
    } else if (selectedCoin === "USDT") {
      dispatch(
        sendUSDT({
          data: {
            pin,
            amount: sendAmount,
            recipientAddress,
          },
          navigate,
        })
      );
    }
  };
  const ConfirmBuy = (pin) => {
    const orderId = selectedOrder._id;
    dispatch(
      updateOrder({
        data: {
          status: "completed",
          pin,
          orderId,
        },
        navigate,
      })
    );
  };

  const onConfirm = async () => {
    const pin = confirmPinTransaction.join("");
    const isCorrectPin = await dispatch(checkPIN({ data: { pin } }));
    if (!isCorrectPin) {
      Swal.fire({
        icon: "error",
        title: "Error Occured",
        text: "Pin is not correct",
      });
    }
    if (isBuyTransaction) {
      ConfirmBuy(pin);
    } else {
      ConfirmTransaction(pin);
    }
  };
  return (
    <div className="wallets-container">
      <PriceCardList />
      <CardLayout>
        <div className="confirm-pin-container">
          <div className="coinwallet-back-btn">
            <Button variant="outlined" onClick={() => navigate(-1)}>
              <ArrowBackIcon /> Back
            </Button>
          </div>
          <div className="coinfirm-pin-details-container">
            <img
              src={image ? require(`../../assets/avatars/${image}`) : Avatar}
              alt="avatar.png"
            />
            <div className="confirm-pin-name">{name}</div>
            <div className="confirm-pin-email">{email}</div>
            <FourDigits
              digits={confirmPinTransaction}
              setDigits={onPinChange}
            />
            <div className="confirm-pin-note">
              <span> Note:</span> You are about to send {sendAmount}{" "}
              {selectedCoin} to {address}
            </div>
            <Button
              variant="contained"
              className="continue confirm-pin-btn"
              onClick={onConfirm}
            >
              Confirm
            </Button>
          </div>
        </div>
      </CardLayout>
    </div>
  );
};

export default withDashboard(ConfirmTransaction);
