import React, { useState } from "react";
import "./index.css";
import withDashboard from "../../HOC/withDashboard";
import BtcImg from "../../assets/bitcoin.png";
import EthImg from "../../assets/ethereum.png";
import UsdtImg from "../../assets/usdt.png";
import PriceCard from "../../components/PriceCard";
import CoinCard from "../../components/CoinCard";
import {
  Button,
  Card,
  IconButton,
  InputAdornment,
  TextField,
} from "@mui/material";
import Chart from "../../components/Chart";
import QRCode from "react-qr-code";
import CardLayout from "../../components/CardLayout";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import PriceCardList from "../../components/PriceCardList";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setBtc } from "../../redux/actions/btcAction";
import { setETH } from "../../redux/actions/ethAction";
import { setUSDT } from "../../redux/actions/usdtAction";
import Swal from "sweetalert2";
import ViewTransactions from "../../components/ViewTransactions";
import {
  setPinForm,
  setUpdatePassword,
  setUser,
  updatePassword,
  updatePin,
} from "../../redux/actions/userAction";
import SettingsIcon from "@mui/icons-material/Settings";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import PasswordIcon from "@mui/icons-material/Password";
import FourDigits from "../../components/FourDigits";
import FiberPinIcon from "@mui/icons-material/FiberPin";

const UpdatePin = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showPassword, setshowPassword] = useState(false);
  const [shownewPassword, setshowNewPassword] = useState(false);
  const [showNewConfirmPassword, setshowNewConfirmPassword] = useState(false);
  const User = useSelector(({ User }) => User);
  const { updatePinForm } = User;
  const { currentPin, newPin, confirmPin } = updatePinForm || {};

  const onChange = (name, pin) => {
    dispatch(
      setPinForm({
        name,
        value: pin,
      })
    );
  };

  const onClick = () => {
    const _currentPin = currentPin.join("");
    const _newPin = newPin.join("");
    const _confirmPin = confirmPin.join("");
    if (_newPin !== _confirmPin) {
      Swal.fire({
        icon: "error",
        title: "Error Occured",
        text: `New Pin and Confirm Pin is not the same`,
      });
    }
    dispatch(
      updatePin({
        data: {
          currentPin: _currentPin,
          newPin: _newPin,
        },
        navigate,
      })
    );
  };

  return (
    <div className="settings-container">
      <Card className="settings-card">
        <div className="coinwallet-back-btn">
          <Button variant="outlined" onClick={() => navigate(-1)}>
            <ArrowBackIcon /> Back
          </Button>
        </div>
        <div className="settings-title">
          <span>
            <FiberPinIcon />
          </span>
          Update Pin
        </div>
        <div className="update-pin-input-container">
          <div className="settings-pin-container">
            <div className="settings-pin-label">Current Pin:</div>
            <FourDigits
              digits={currentPin}
              setDigits={(pin) => onChange("currentPin", pin)}
            />
          </div>
          <div className="settings-pin-container">
            <div className="settings-pin-label">New Pin:</div>
            <FourDigits
              digits={newPin}
              setDigits={(pin) => onChange("newPin", pin)}
            />
          </div>
          <div className="settings-pin-container">
            <div className="settings-pin-label">Confirm New Pin:</div>
            <FourDigits
              digits={confirmPin}
              setDigits={(pin) => onChange("confirmPin", pin)}
            />
          </div>
          <div className="setting-btn-container">
            <Button
              variant="contained"
              className={`setting-btn`}
              onClick={onClick}
            >
              Update
            </Button>
          </div>
        </div>
      </Card>
    </div>
  );
};

export default withDashboard(UpdatePin);
