import React from "react";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import "./index.css";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";

const AccountDetails = () => {
  const User = useSelector(({ User }) => User);
  const { selectedOrder } = User;
  const { bankName, accountHolder, accountNumber } = selectedOrder || {};

  const CopyToClipboard = (data) => {
    navigator.clipboard.writeText(data);
    Swal.fire({
      position: "top-end",
      icon: "success",
      title: "Copied to Clipboard",
      showConfirmButton: false,
      timer: 1500,
    });
  };

  return (
    <div className="buy-order-review-container">
      <div className="buy-order-review-title">User Account Details</div>
      <div className="buy-order-review-item">
        <div className="buy-order-review-item-title">Account Holder</div>
        <div className="buy-order-review-value">
          {accountHolder}
          <span
            onClick={() => CopyToClipboard(accountHolder)}
            className="copy-icon-container"
          >
            <ContentCopyIcon />
          </span>
        </div>
      </div>
      <div className="buy-order-review-item">
        <div className="buy-order-review-item-title">Account Number</div>
        <div className="buy-order-review-value">
          {accountNumber}
          <span
            onClick={() => CopyToClipboard(accountNumber)}
            className="copy-icon-container"
          >
            <ContentCopyIcon />
          </span>
        </div>
      </div>
      <div className="buy-order-review-item border-bottom-none">
        <div className="buy-order-review-item-title">Bank Name</div>
        <div className="buy-order-review-value">
          {bankName}
          <span
            onClick={() => CopyToClipboard(bankName)}
            className="copy-icon-container"
          >
            <ContentCopyIcon />
          </span>
        </div>
      </div>
    </div>
  );
};

export default AccountDetails;
