import React, { Fragment } from "react";
import "./index.css";
import { Card } from "@mui/material";
import LocalAtmIcon from "@mui/icons-material/LocalAtm";
import HistoryToggleOffIcon from "@mui/icons-material/HistoryToggleOff";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import TrendingDown from "@mui/icons-material/TrendingDown";
import TrendingFlat from "@mui/icons-material/TrendingFlat";
import { useSelector } from "react-redux";
import { formatNumberWithCommas } from "../../utils";
const PriceCard = ({ cardImg, price, title, currencyDiff, isCurrency }) => {
  const parsedCurrencyDiff = parseFloat(currencyDiff);
  const isPositive = !isNaN(parsedCurrencyDiff) && parsedCurrencyDiff >= 0;
  const User = useSelector(({ User }) => User);
  const { currentUser } = User;
  const { currencySymbol } = currentUser || {};
  const formattedPrice = formatNumberWithCommas(price);
  return (
    <Fragment>
      <div className="price-card-container">
        <Card className="price-card">
          <div className="price-card-item">
            <img src={cardImg} className="price-card-image" alt="cardImg.png" />
            <div className="price-card-item-text">
              <div className="price-card-title">{title}</div>
              <div className="price-card-value">
                {currencySymbol}
                {formattedPrice}
              </div>
            </div>
          </div>
          <div className="price-card-desc">
            {isCurrency ? (
              <Fragment>
                <span>
                  <LocalAtmIcon />
                </span>
                Total Amount of Assets
              </Fragment>
            ) : (
              <Fragment>
                <span>
                  <HistoryToggleOffIcon />
                </span>{" "}
                24hr Difference: {currencySymbol}
                {currencyDiff}{" "}
                <span className={`trend-icon ${isPositive ? "up" : "down"}`}>
                  {currencyDiff === 0 ? (
                    <TrendingFlat />
                  ) : currencyDiff > 0 ? (
                    <TrendingUpIcon />
                  ) : (
                    <TrendingDown />
                  )}
                </span>
              </Fragment>
            )}
          </div>
        </Card>
      </div>
    </Fragment>
  );
};

export default PriceCard;
