import React, { useState, useEffect } from "react";
import { Navigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { BASE_URL } from "../redux/actions/types.js";
import { useNavigate } from 'react-router-dom';
import {
  setUser,
  setUserProfile,
  getSettings,
} from "../redux/actions/userAction";
import axios from "axios";

const ProtectedRoute = ({ Component }) => {
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const getUserInfo = async () => {
    try {
      const { data } = await axios.get(`${BASE_URL}/api/user/user`, {
        withCredentials: true,
      });
      const currentUser = data.user;
  
      dispatch(setUser({ name: "currentUser", value: currentUser }));
      dispatch(setUserProfile(currentUser));
      setIsLoading(false);
    } catch (e) {
      console.error(e);
      navigate('/');
    }
  };

  useEffect(() => {
    getUserInfo();
  }, []);

  const currentUser = useSelector(({ User }) => User.currentUser);

  return !isLoading && (currentUser ? <Component /> : <Navigate to={"/"} />);
};

export default ProtectedRoute;
