import React from "react";
import "../ConfirmTransaction/index.css";
import withDashboard from "../../HOC/withDashboard";
import { Button } from "@mui/material";
import CardLayout from "../../components/CardLayout";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import FourDigits from "../../components/FourDigits";
import Avatar from "../../assets/avatars/avatar1.png";
import PriceCardList from "../../components/PriceCardList";
import { useDispatch, useSelector } from "react-redux";
import { setUser, swapCrypto } from "../../redux/actions/userAction";
import { useNavigate } from "react-router-dom";

const ConfirmTransaction = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const User = useSelector(({ User }) => User);
  const {
    confirmPinSwap,
    swapCoinFromSelect,
    swapCoinToSelect,
    swapCoinFrom,
    swapEstimate,
    currentUser,
  } = User;
  console.log(currentUser);
  const { name, email,image } = currentUser || {};
  const onPinChange = (pin) => {
    dispatch(
      setUser({
        name: "confirmPinSwap",
        value: pin,
      })
    );
  };
  const onConfirm = () => {
    const pin = confirmPinSwap.join("");
    dispatch(
      swapCrypto({
        data: {
          coinToSend: swapCoinFromSelect,
          coinToRecieve: swapCoinToSelect,
          amount: swapCoinFrom,
          pin,
        },
        navigate,
      })
    );
  };
  return (
    <div className="wallets-container">
      <PriceCardList />
      <CardLayout>
        <div className="confirm-pin-container">
          <div className="coinwallet-back-btn">
            <Button variant="outlined" onClick={() => navigate(-1)}>
              <ArrowBackIcon /> Back
            </Button>
          </div>
          <div className="coinfirm-pin-details-container">
            <img
              src={image ? require(`../../assets/avatars/${image}`) : Avatar}
              alt="avatar.png"
            />
            <div className="confirm-pin-name">{name}</div>
            <div className="confirm-pin-email">{email}</div>
            <FourDigits digits={confirmPinSwap} setDigits={onPinChange} />
            <div className="confirm-pin-note">
              <span> Note:</span> You are about to swap {swapCoinFrom}{" "}
              {swapCoinFromSelect} for {swapEstimate} {swapCoinToSelect}
            </div>
            <Button
              variant="contained"
              className="continue confirm-pin-btn"
              onClick={onConfirm}
            >
              Confirm
            </Button>
          </div>
        </div>
      </CardLayout>
    </div>
  );
};

export default withDashboard(ConfirmTransaction);
