import {
  BASE_URL,
  SET_ETH,
  SET_ETH_CHART,
  SET_SEARCH_ETH_TRANSACTION,
  SET_SEARCH_ETH_USER_TRANSACTION,
  SET_STATUS,
} from "./types";
import Axios from "axios";
import Swal from "sweetalert2";
import { setLoader } from "./userAction";

// Set ETH reducer
export const setETH = (data) => async (dispatch) => {
  try {
    dispatch({ type: SET_ETH, payload: data });
  } catch (error) {
    console.log(error);
  }
};

// Login a User
export const getETHPrices = (data) => async (dispatch) => {
  try {
    const resp = await Axios.post(`${BASE_URL}/api/eth/getPrices`, data, {
      withCredentials: true,
    });
    const dataset = resp.data.dataset;
    dispatch({ type: SET_ETH_CHART, payload: dataset });
  } catch (error) {
    const data = error?.response.data;
    const errorMsg = data?.errorMsg;
    if (errorMsg) {
      Swal.fire({
        icon: "error",
        title: "Error Occured",
        text: errorMsg,
      });
    }
    console.log(error);
  }
};

export const getEthCurrentPrice = (data) => async (dispatch) => {
  try {
    const resp = await Axios.post(`${BASE_URL}/api/eth/currentPrice`, data, {
      withCredentials: true,
    });
    const price = resp.data.price;
    dispatch({
      type: SET_ETH,
      payload: {
        name: "priceNow",
        value: price,
      },
    });
  } catch (error) {
    const data = error?.response.data;
    const errorMsg = data?.errorMsg;
    if (errorMsg) {
      Swal.fire({
        icon: "error",
        title: "Error Occured",
        text: errorMsg,
      });
    }
    console.log(error);
  }
};

// get user address
export const getETHAddress = () => async (dispatch) => {
  try {
    const resp = await Axios.get(`${BASE_URL}/api/eth/address`, {
      withCredentials: true,
    });
    const address = resp.data.address;
    dispatch({
      type: SET_ETH,
      payload: {
        name: "address",
        value: address,
      },
    });
  } catch (error) {
    const data = error?.response.data;
    const errorMsg = data?.errorMsg;
    if (errorMsg) {
      Swal.fire({
        icon: "error",
        title: "Error Occured",
        text: errorMsg,
      });
    }
    console.log(error);
  }
};

// get user balance
export const getETHBalance = () => async (dispatch) => {
  try {
    const resp = await Axios.get(`${BASE_URL}/api/eth/balance`, {
      withCredentials: true,
    });
    const balance = resp.data.balance;
    dispatch({
      type: SET_ETH,
      payload: {
        name: "balance",
        value: balance,
      },
    });
  } catch (error) {
    const data = error?.response.data;
    const errorMsg = data?.errorMsg;
    if (errorMsg) {
      Swal.fire({
        icon: "error",
        title: "Error Occured",
        text: errorMsg,
      });
    }
    console.log(error);
  }
};

// get user balance
export const getETHUserBalance =
  ({ data }) =>
  async (dispatch) => {
    try {
      const resp = await Axios.post(`${BASE_URL}/api/eth/userBalance`, data, {
        withCredentials: true,
      });
      const balance = resp.data.balance;
      dispatch({
        type: SET_ETH,
        payload: {
          name: "userBalance",
          value: balance,
        },
      });
    } catch (error) {
      console.log(error);
    }
  };

export const estimateETHGas =
  ({ data }) =>
  async (dispatch) => {
    dispatch(setLoader(true));
    try {
      const isFee = data.isFee;
      const resp = await Axios.post(`${BASE_URL}/api/eth/estimateGas`, data, {
        withCredentials: true,
      });
      const estimatedGas = resp.data.estimatedGas;
      if (isFee) {
        dispatch({
          type: SET_ETH,
          payload: {
            name: "gasPriceFee",
            value: estimatedGas,
          },
        });
      } else {
        dispatch({
          type: SET_ETH,
          payload: {
            name: "gasPrice",
            value: estimatedGas,
          },
        });
      }
    } catch (error) {
      const data = error?.response.data;
      const errorMsg = data?.errorMsg;
      if (errorMsg) {
        Swal.fire({
          icon: "error",
          title: "Error Occured",
          text: errorMsg,
        });
      }
      console.log(error);
    }
    dispatch(setLoader(false));
  };

// Send USDT
export const sendETH =
  ({ data, navigate }) =>
  async (dispatch) => {
    dispatch(setLoader(true));
    try {
      const resp = await Axios.post(`${BASE_URL}/api/eth/send`, data, {
        withCredentials: true,
      });
      const transactionHash = resp.data.transactionHash;
      dispatch({
        type: SET_STATUS,
        payload: {
          statusTitle: "Success",
          statusDesc: "Amount was successfully Sent",
          statusContinueRoute: "/coinWallet",
        },
      });
      console.log(transactionHash);
      navigate("/coinWallet");
      Swal.fire({
        position: "top-end",
        icon: "success",
        title: "Transaction Sent Successfully",
        showConfirmButton: false,
        timer: 1500,
      });
      dispatch(setETH({ name: "sendAmount", value: "" }));
      dispatch(setETH({ name: "recipientAddress", value: "" }));
    } catch (error) {
      const data = error?.response.data;
      const errorMsg = data?.errorMsg;
      if (errorMsg) {
        Swal.fire({
          icon: "error",
          title: "Error Occured",
          text: errorMsg,
        });
      }
      navigate("/coinWallet");
      console.log(error);
    }
    dispatch(setLoader(false));
  };

export const getEthTransactions = () => async (dispatch) => {
  dispatch(setLoader(true));
  try {
    const resp = await Axios.get(`${BASE_URL}/api/eth/transactions`, {
      withCredentials: true,
    });
    const transactions = resp.data.transactions;
    dispatch(
      setETH({
        name: "transactions",
        value: transactions,
      })
    );
    dispatch(
      setETH({
        name: "filteredTransactions",
        value: transactions,
      })
    );
  } catch (error) {
    const data = error?.response.data;
    const errorMsg = data?.errorMsg;
    if (errorMsg) {
      Swal.fire({
        icon: "error",
        title: "Error Occured",
        text: errorMsg,
      });
    }
    console.log(error);
  }
  dispatch(setLoader(false));
};

export const getEthUserTransactions =
  ({ data }) =>
  async (dispatch) => {
    dispatch(setLoader(true));
    try {
      const resp = await Axios.post(
        `${BASE_URL}/api/eth/userTransactions`,
        data,
        {
          withCredentials: true,
        }
      );
      const transactions = resp.data.transactions;
      dispatch(
        setETH({
          name: "usertransactions",
          value: transactions,
        })
      );
      dispatch(
        setETH({
          name: "userfilteredTransactions",
          value: transactions,
        })
      );
    } catch (error) {
      console.log(error);
    }
    dispatch(setLoader(false));
  };

export const setSearchEthTransaction = (data) => async (dispatch) => {
  try {
    dispatch({ type: SET_SEARCH_ETH_TRANSACTION, payload: data });
  } catch (error) {
    console.log(error);
  }
};

export const setSearchUserEthTransaction = (data) => async (dispatch) => {
  try {
    dispatch({ type: SET_SEARCH_ETH_USER_TRANSACTION, payload: data });
  } catch (error) {
    console.log(error);
  }
};
